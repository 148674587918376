import React, { useEffect, useState } from "react";

import {IOrder} from '../../interfaces/order'
import { Storage } from '@ionic/storage'

function useLocalOrderListDatabase() {
    const [isMounted, setIsMounted] = useState<boolean>(true);

    const storage = new Storage();
    storage.create();
    
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isLocalLoaded, setIsLocalLoaded] = useState<boolean>(false);
    const [savedOrderList, setSavedOrderList] = useState<IOrder[]>();
    const [isSaved, setIsSaved] = useState<boolean>(false);
    const [savedOrderListIsRemoved, setSavedOrderListIsRemoved] = useState<boolean>(false);
    const [orderToSave, setOrderToSave] = useState<IOrder>();
    
    React.useEffect(() => {
        setIsMounted(true)
        if(savedOrderList == undefined && isLocalLoaded == false && isMounted == true && savedOrderListIsRemoved == false)
            loadSavedOrderList();

        return () => {
          setIsMounted(false);
          setIsSaved(false)
        };
    }, []);

    React.useEffect(() => {
        if(isLocalLoaded == false)
            loadSavedOrderList();

    },[isLocalLoaded])

    /* load existing contact persons */
    const loadSavedOrderList = () => {
        if(isMounted == true)
        {   
            if(isLocalLoaded == false){
                setIsLoading(true)
                storage.get('order_list').then((data) => {
                    if(isMounted == true)
                    {
                        if(data == null)
                            setSavedOrderList([])
                        else
                            setSavedOrderList(data)

                        setIsLocalLoaded(true)
                        setIsLoading(false);
                    }
                    
                }).catch((e) => {
                    if(isMounted == true)
                    {
                        console.error(e)
                        setIsLocalLoaded(false);
                        setIsLoading(false);
                    }
                });
            }
        }
    }

    React.useEffect(() => {
        if(orderToSave != undefined)
            saveOrderToList(orderToSave)
    }, [orderToSave]);

    /* save single order */
    const saveOrderToList = (order:IOrder) => {
        let orderId:number                 = 1,
            updateExistingOrder:boolean    = false;
        
        let list:IOrder[]  = [];
        let primkeyList:any[] = [];
        
        if(savedOrderList !== undefined){
            savedOrderList.forEach((existingOrder, index) => {
                if(primkeyList.includes(existingOrder.primkey) == false)
                {
                    primkeyList.push(existingOrder.primkey);
                    orderId = (existingOrder._id != undefined ? existingOrder._id + 1 : 1); 
                    if (existingOrder._id  == order._id || existingOrder.primkey == order.primkey)
                    {
                        updateExistingOrder = true;
                        existingOrder = order;
                    }
                }
                    
                list.push(existingOrder);
            })
        }

        if (updateExistingOrder === false && primkeyList.includes(order.primkey) === false){
            order._id = orderId;
            list.push(order);
        }
        setSavedOrderList(list)
    }


    /* update contact person */
    React.useEffect(() => {
        if(savedOrderList!= undefined)
        {
            updateSavedOrderList();
        }
           
    }, [savedOrderList])

    const updateSavedOrderList = (setAsSaved:boolean = false) => {
        if(savedOrderList != undefined){
            if(savedOrderList.length > 0)
            {
                let list:IOrder[] = filterSamePrimkeys(savedOrderList);

                //setIsLoading(true);   
                storage.remove("order_list").then(()=> {
                    storage
                        .set('order_list', list)
                        .then(() => {
                            if(isMounted == true && orderToSave != undefined)
                            {
                                setIsSaved(true);
                                setTimeout(() => {setOrderToSave(undefined); setIsSaved(false)}, 1000)
                                setIsLoading(false);
                            }   
                        });
                })
                .catch((e) => {
                    if(isMounted == true)
                    {
                        console.error(e)
                        setIsLoading(false);
                    }
                });
            }
        }   
    }

    const filterSamePrimkeys = (orders:IOrder[]) : IOrder[] => {
        let list:IOrder[] = [];
        let primkeyList: any[] = [];

        orders.forEach((item: IOrder, index) => {
            if(primkeyList.includes(item.primkey) === false)
            {
                primkeyList.push(item.primkey);
                list.push(item);
            }
        });

        return list;
    }

    const removeSavedOrderList = () => {
        storage.remove("order_list")
               .then(()=> {
                    if(isMounted == true){
                        setSavedOrderListIsRemoved(true);
                    }
               })
               .catch((e) => {console.error(e);})
        
    }

    return { isLoading, removeSavedOrderList, savedOrderListIsRemoved, setSavedOrderListIsRemoved, isLocalLoaded, setIsLocalLoaded, loadSavedOrderList, isSaved, setIsSaved, savedOrderList, setSavedOrderList, setOrderToSave }
}

export default useLocalOrderListDatabase;