import { ICompany } from "./account";

export interface ICustomer {
    _id?: string | number,
    id: string | number,
    tbkuid?: string | number,
   
    producer_nr?: number | string | null | undefined,
    customer_nr?: string | null | undefined,
    //email?: string | null | undefined,
    customername:string,
    additional?: string,
    zip?: string,
    city?: string,
    isVisible: boolean,
    showItem?: boolean
}

export function instanceOfCustomer (object: any) : object is ICustomer
{
    return 'customername' in object
}