import React, { useEffect, useState, useContext } from "react";

import { IonApp, IonRouterOutlet, IonSplitPane, NavContext } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { Redirect, Route } from 'react-router-dom';
import { useParams, useLocation, useHistory } from 'react-router';
/* interfaces */
import {IUser} from './interfaces/account'

/* custom components */
import ErrorBoundary from "./components/ErrorBoundary";
import ListenerAppRouterAnalytic from "./components/base/ListenerAppRouterAnalytics";
import Menu from './components/base/Menu';
import Welcome from "./pages/Welcome";
import Ordering from './pages/ordering/Ordering';
import Delivery from "./pages/delivery/Delivery";
import Orders from "./pages/orders/Orders";
import {Login} from './pages/account/Login';
import {Logout} from './pages/account/Logout';

import Success from './pages/ordering/Success'
/* services */
import useLocalAppDatabase from './services/useLocalAppDatabase';
import useLocalUserDatabase from './services/account/useLocalUserDatabase';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

import {IAppData, ISettings} from './interfaces/settings';

import {settings} from './settings/settings';
import useGet from './services/hooks/useGet';

/* Theme variables */
import './theme/variables.css';
/* Load tbsoft customer css */
import './theme/customer.css';
/* Base style */
import './components/base/Base.css'
import { render } from '@testing-library/react';
import { hostname, userInfo } from "os";

interface IAppProps {}
interface IAppState {
  isLoggedIn: boolean,
  appData: IAppData,
}

const App: React.FC<IAppProps> = (props : IAppProps) => {
  // make redirect on origin domain 
  //    is needed when another domain is used before on e.g. firebase
  if (settings.originDomain != undefined && settings.originDomain != "")
  {
    if (settings.originDomain != hostname() && hostname() != 'localhost' )
      window.location.replace("https://" + settings.originDomain); 
  }

  const [isMounted, setIsMounted] = useState<boolean>(true)
  const {navigate} = useContext(NavContext);
  //console.log("Current environment : ",process.env.NODE_ENV)

  const {isLocalUserLoaded, isSaved, loadExistingUser, localUser, saveUserData} = useLocalUserDatabase(undefined);
  /*******************************
   *  Application Data - Token
   *******************************/
  // for blocking redirect to save get parameters out of url
  const [urlSearchString, setUrlSearchString] = useState<string>("");
  const [appToken, setAppToken] = useState<string>("");
  // Handle app_token as GET-Paramter
  const {localApplication, loadApplicationData, saveApplicationData, isLocalLoaded} = useLocalAppDatabase();

  React.useEffect(() => {
    if(appToken !== undefined && appToken !== "" && isLocalLoaded === true)
      saveApplicationData({appToken: appToken !== undefined ? appToken  : ""});
  }, [appToken, localApplication, isLocalLoaded])

  useEffect(() => {
    let token:string = getAppTokenFromUrl();
    setAppToken(token);
  },[window.location.href, localUser])

  /*******************************  
   *  Routing
   *******************************/
  // Routing Helper
  const [currentPath, setCurrentPath] = useState<string>("");
  const [pathHasChanged,  setPathHasChanged] = useState<boolean>(false);
  const [pageType, setPageType] = useState<typeof Ordering | typeof Orders | typeof Login | typeof Logout>() 

  // App & User Data
  const [appData, setAppData] = useState<IAppData>(settings.appData)
  const [appUser, setAppUser] = useState<IUser>()
  const [doHandleAppUserUpdate, setDoHandleAppUserUpdate] = useState<Boolean>(false)
  const [isLoggedIn, setIsLoggedIn] = useState<Boolean |undefined>(appUser != undefined ? appUser.isLoggedIn : false)

  // proof authorization
  const { data: loginStateData_remote, loading, reDoGet: proofLoginState, setData: setValidationData, setDoGet, unAuthorized } = useGet("o/auth/is-logged-in", false);

  useEffect(()=>{
    setIsMounted(true);

    //setTimeout(() =>{proofLoginState();}, 1000)
    return () => {
      setIsMounted(false);
    }
  }, []);

  React.useEffect(() => {
    if(loginStateData_remote != undefined)
    {
      if(loginStateData_remote.allowed == false && appUser != undefined && window.location.pathname != '/login'){
        navigate('logout')
      }
    }
    
    if(isLoggedIn !== undefined && isLoggedIn === false && appUser !== undefined && window.location.pathname != '/login' ){
      if(appUser.isLoggedIn == false)
        navigate("login");
    }
  }, [loginStateData_remote, isLoggedIn, appUser]);
  
  /* check and set local user  */
  if(isLocalUserLoaded == false)
    loadExistingUser();

  React.useEffect(()=> {
    if(localUser != undefined)
    { 
      setAppUser(localUser);
      if(localUser.isLoggedIn == true)
        setDoHandleAppUserUpdate(true);
    }
    else 
      setIsLoggedIn(false)
  },[localUser])

  /* handle login action and state */
  const handleLoginState = (user : IUser) => {
    let appToken = getAppTokenFromUrl();
    
    user = {...user, appToken: appToken};
    setAppUser(user);
    setDoHandleAppUserUpdate(true);
  } 

  const getAppTokenFromUrl = ():string => {
    let token:string = "";
    if (window.location.search.length > 0){
      setUrlSearchString(window.location.search);

      if(window.location.search.indexOf("app_token") > -1)
      {
        let search:string = "" + new String(window.location.search);
        var data = search.slice(1).split("&")[0].split("=");
        let nextIsValue:boolean = false;
        data.forEach((value:string, key) => {
          if(nextIsValue === true)
          {
            token = value;
            nextIsValue = false;
          }

          if(value === "app_token"){
            nextIsValue = true;
          }
        });
      }
    }
    else
      setUrlSearchString("");

    return token;
  };

  useEffect(() => {
    if(appUser != undefined && doHandleAppUserUpdate == true)
    {
      setTimeout(() => {
        setDoHandleAppUserUpdate(false);
        setIsLoggedIn(appUser.isLoggedIn);
      }, 1000)
    }
  }, [appUser, doHandleAppUserUpdate])
  
  if( isLoggedIn == undefined  )
    return <p>is undefined</p>;
  else if(isLoggedIn == false)
    return (
      <IonApp className={settings.appData.appShortCut}>  
        <IonReactRouter>
           <ListenerAppRouterAnalytic changeRoute={setCurrentPath} />
            <Route>
            < IonRouterOutlet id="main">
                <Route path="/" exact={true}  >
                  <Redirect to={"/login" + urlSearchString}  />
                </Route>
                <Route path="/logout" exact={true} >
                  <Redirect to={"/login" + urlSearchString} />
                </Route>
                <Route path="/login" exact={true}>
                  <Login loginType={appData.loginType}  handleLoginState={handleLoginState} appToken={appToken}/>
                </Route> 
              </IonRouterOutlet>
            </Route>
        </IonReactRouter>
      </IonApp>
    );
  else if(isLoggedIn == true)
    return (
      <IonApp className={settings.appData.appShortCut}>
        <IonReactRouter>
          <ListenerAppRouterAnalytic changeRoute={setCurrentPath}/>
          <Route>
                <IonSplitPane contentId="main">
                  <Menu/> 
                  <IonRouterOutlet id="main">
                    <Route path="/login" exact={true} >
                      <Redirect to={"/bestellen" + urlSearchString} />
                    </Route>
                    <Route path="/" exact={true} >
                      <Redirect to={"/bestellen" + urlSearchString} />
                    </Route>
                  {settings.appData.appPackagename == 'com.tbsoft.minerals.kaltenbach' 
                  ?
                    <Route path="/login" exact={true} >
                      <Redirect to={"/willkommen" + urlSearchString} />
                    </Route>
                  : 
                    <Route path="/login" exact={true} >
                      <Redirect to={"/bestellen" + urlSearchString} />
                    </Route>
                  }
                  {settings.appData.appPackagename == 'com.tbsoft.minerals.kaltenbach' 
                  ?
                     <Route path="/willkommen" exact={true}>
                      <Welcome  currentPath={currentPath} pathHasChanged={pathHasChanged} pagePath="/willkommen" hasToOrderListButton={false}/>
                    </Route> 
                  : 
                    <Route path="/willkommen" exact={true} >
                      <Redirect to={"/bestellen" + urlSearchString}/>
                    </Route>}
                    <Route path="/bestellen" >
                      <Ordering  currentPath={currentPath} pathHasChanged={pathHasChanged} pagePath="/bestellen"/>
                    </Route> 
                    <Route path="/bestellungen" exact={true}>
                      <Orders currentPath={currentPath} pathHasChanged={pathHasChanged} pagePath="/bestellungen"/>
                    </Route> 
                    <Route path="/lieferungen" exact={true}>
                      <Delivery currentPath={currentPath} pathHasChanged={pathHasChanged} pagePath="/lieferungen"/>
                    </Route> 
                    <Route path="/Logout" >
                      <Logout handleLoginState={handleLoginState} user={appUser} />
                    </Route>
                    {/*
                    <Route path="/page/:name" exact={true}>
                      <Page />
                    </Route>
                    */}
                </IonRouterOutlet>
              </IonSplitPane>
          </Route>
        </IonReactRouter>
      </IonApp>
    );
  else 
    return <p>kkk</p>
};

export default App;