import {useContext} from 'react';

import { NavContext } from '@ionic/react';


type TNavigationName = "" |"login" | "Login" | "logout" | "Logout" | "ordering" | "Ordering" | "orderList" | "Order" |  "delivery" | "DeliveryStatus";

function useInternalNavigate (name:TNavigationName = "") 
{
    const {navigate} = useContext(NavContext);
    //navigate(name)

    const navigateInternal = (withReload: boolean = false, extraName: string = "")  => {
        let routeName: string = (extraName !== "" ? extraName : name);

        switch(routeName)
        {
            case "login":
                navigate('login', undefined, "pop");
                break;
            case "logout":
            case "Logout":
                navigate('logout', undefined, "pop");
                break;
            case "ordering":
            case "Ordering":
                navigate('bestellen', undefined, "pop");
                break;
            case "orderList":
            case "Order":
                navigate('bestellungen', undefined, "pop");
                break;
            case "delivery":
            case "DeliveryStatus":
                navigate('lieferungen', undefined, "pop");
                break;
            
        }
    };

    const navigateToPath = (path:string = "") => {
        navigate(path, "none", "pop");
    };

    return { navigateInternal, navigateToPath };
}


export default useInternalNavigate;