import React, { useEffect, useState } from "react";

import {IApplicationData} from '../interfaces/application'
import { Storage } from '@ionic/storage'

function useLocalAppDatabase() {
    const [isMounted, setIsMounted] = useState<boolean>(true)

    const storage = new Storage();
    storage.create();
    
    const [isLocalLoaded, setIsLocalLoaded] = useState<boolean>(false)
    const [localApplication, setLocalApplication] = useState<IApplicationData>()
    const [isSaved, setIsSaved] = useState<boolean>(false)

    React.useEffect(() => {
        if(localApplication === undefined && isLocalLoaded === false)
                loadApplicationData();

        return () => {
          setIsMounted(false);
        };
    }, []);

    /* load existing application data */
    const loadApplicationData = () => {
        if(isMounted === true)
        {
            if(isLocalLoaded == false){
                storage.get('application_data').then((data) => {
                    if(isMounted === true)
                    {
                        if(data !== null)
                            setLocalApplication(data);
                        
                        setIsLocalLoaded(true);
                    }
                }).catch((e) => {
                    if(isMounted === true)
                    {
                        console.error(e)
                        setIsLocalLoaded(false);
                    }
                });
            }
        }
    }

    /* save contact person */
    const saveApplicationData = (applicationData:IApplicationData) => {
        storage.remove("application_data").then(()=> {
            storage
                .set('application_data', applicationData)
                .then(() => {
                    if(isMounted == true)
                        setIsSaved(true);
                });
        });
    }

    return { localApplication, loadApplicationData, saveApplicationData, useLocalAppDatabase, isLocalLoaded }// loading, error};
}

export default useLocalAppDatabase;
