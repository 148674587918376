import React, { useState } from 'react';

import { IonGrid, IonRow, IonCol, IonIcon, useIonAlert } from '@ionic/react';
import { checkmarkCircleOutline, checkmarkCircleSharp, closeCircleOutline, closeCircleSharp } from 'ionicons/icons';
import parse from 'html-react-parser';

import { render } from '@testing-library/react';
import { useParams } from 'react-router';

// interfaces
import {IOrder} from '../../interfaces/order'

// hooks & services
import {stringToHTMLconvert} from '../../services/hooks/stringToHTMLconvert';
import { deliveryDateString } from '../../services/hooks/dateString';
import { materialAmountConverter, amountConverter } from '../../services/hooks/materialAmountConverter';

import useLocalUserDatabase from '../../services/account/useLocalUserDatabase';

import usePost from '../../services/hooks/usePost';

interface IHandleOrderChange{
    (order: IOrder, type?: string) : void
}
interface IOrderDetail {
    order: IOrder,
    handleOrderChange:IHandleOrderChange
}

const OrderDetail: React.FC<IOrderDetail> = (props) => { 
    const {localUser} = useLocalUserDatabase(undefined);
    const { data: setAsReadResponse, repost: sendAsRead } = usePost("pre-order/answer/set-as-read/" + props.order.primkey, {}, false, localUser?.token != undefined ? localUser?.token : "" );
    
    const [doDeleteAction, setDoDeleteAction] = useState<boolean>(false);
    const [doUpdateRemoveAction, setDoUpdateRemoveAction] = useState<boolean>(false);
    
    const [removeOrderCheck] = useIonAlert();
    
    const { data: asCompleteDeletedData, repost: sendAsCompleteDeleted} = usePost("pre-order/dispo", {order: props.order, user: localUser, status: "X"}, false, localUser?.token != undefined ? localUser?.token : "" );

    // handle delete button
    const handleDeleteStatusButtonClick = (e:any) => {
        setDoDeleteAction(true);
    }

    React.useEffect(() => {
        if(doDeleteAction == true || doUpdateRemoveAction == true)
            sendAsRead();
    }, [doDeleteAction, doUpdateRemoveAction])

    const handleRemoveOrderFromInterface = () => {
        sendAsCompleteDeleted();
    };

    React.useEffect(() => {
        if(asCompleteDeletedData != undefined)
            if(asCompleteDeletedData.Success == true)
                props.handleOrderChange({...props.order}, "delete");
    }, [asCompleteDeletedData])

    const handleUpdateStatusButtonClick = () => {
        setDoUpdateRemoveAction(true);
    }

    const doRemoveCompletly = () => {
        removeOrderCheck({
            cssClass: 'restart',
            header: 'Bestellung entfernen',
            message: 'Soll die Bestellung auch aus der Liste entfernt werden? Wenn nicht, kann die Bestellung weiterhin betrachtet werden.',
            buttons: [
                { text: 'Ja', cssClass: "do", handler: handleRemoveOrderFromInterface },
                'Nein',
            ],
        });
    };
    
    React.useEffect(() => {
        if(setAsReadResponse != undefined)
        {
            if(setAsReadResponse.Success == true )
            {
                props.handleOrderChange({...props.order, isUpdated: false});
                
                if(doDeleteAction == true)
                {
                    setDoDeleteAction(false);
                    doRemoveCompletly(); 
                }
                else 
                    setDoUpdateRemoveAction(false);   
            }
            else
                removeOrderCheck({
                    cssClass: 'error',
                    header: 'Fehler',
                    message: 'Es ist ein technischer Fehler aufgetreten. Versuchen Sie es später nochmals.',
                    buttons: [
                        'OK',
                    ],
                });
        }
    }, [setAsReadResponse])

    return (
        <IonGrid className={"order-detail" + (props.order.isUpdated == true ? " updated" : "") + (props.order.isDeleted == true ? " deleted" : "")}>
            <IonRow style={{minHeight: "3.5em"}}>
                <IonCol size="12">
                {props.order.isUpdated == true && props.order.isDeleted == false ? <IonIcon className="updated button" size="large" ios={checkmarkCircleOutline} md={checkmarkCircleSharp}  onClick={handleUpdateStatusButtonClick} /> : ""}
                {props.order.isDeleted == true && props.order.isUpdated == true ? <IonIcon className="deleted button" size="large" ios={closeCircleOutline} md={closeCircleSharp} onClick={handleDeleteStatusButtonClick} /> : ""}
                {props.order.isDeleted == true && props.order.isUpdated == false ? <IonCol className="delete fully" size='5' offset='7'><a onClick={doRemoveCompletly}>Endgültig löschen</a></IonCol> : ""}
                </IonCol>
            </IonRow>
        {props.order.deliveryDate != undefined ? 
        (
            <IonRow className="delivery-time">
                <IonCol size="12" >
                    {/*<p>{String(props.order.isDeleted)} | {String(props.order.isUpdated)} --- {props.order.primkey}</p>*/}
                    <p>Lieferung am {parse(deliveryDateString(props.order.deliveryDate).replaceAll(" ", "&nbsp;"))}</p>
                </IonCol>
            </IonRow>
        ) : ""}
            <IonRow className="status-box">
                <IonCol  className="status" size="5" offset="7">
                    {/*<p>{String(props.order.isDeleted)} | {String(props.order.isUpdated)} --- {props.order.primkey}</p>*/}
                    <p><span className={"bold medium-size important"}>{props.order.status != undefined ? props.order.status : "Vordisposition"}</span></p>
                </IonCol>
            </IonRow>
            <IonRow className="details">
                <IonCol size='6'>
                    <h2 className='text-center'>Baustelle</h2>
                    <p>{props.order.construction?.address}<br/>
                    {props.order.construction?.zip} {props.order.construction?.city}</p>
                </IonCol>
                <IonCol size='6'>
                    <h2 className='text-center'>Ansprechpartner</h2>
                    <p>{props.order.contactPerson?.salutation} {props.order.contactPerson?.fullName}<br/>
                    Tel.: {props.order.contactPerson?.phone}<br/>
                    {props.order.contactPerson?.email != undefined 
                        ? props.order.contactPerson?.email != "" 
                            ? "E-Mail: " + props.order.contactPerson?.email 
                            : "" 
                        : ""}
                    </p>
                </IonCol>
            </IonRow>
            <IonRow className="details non-border">
                <IonCol size='6'>
                    <h2 className='text-center'>Material</h2>
                    <p>{props.order.material?.name}<br/>
                    {amountConverter(props.order.amount)} {materialAmountConverter(props.order.material, props.order.amountType)}</p>
                </IonCol>
                <IonCol size='6'>
                    <h2 className='text-center'>Transporter</h2>
                    <p >{props.order.transporter != undefined ? props.order.transporter.name : ""}</p>
                </IonCol>
            </IonRow>
        {props.order.comment != undefined && props.order.comment != "" ? 
            (<IonRow className="comment">
                <IonCol size='12'>
                    {parse(stringToHTMLconvert(props.order.comment))}
                </IonCol>
            </IonRow>)
            : null}
        </IonGrid>
    );
}

export default OrderDetail;
