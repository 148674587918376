import React, { useEffect, useState } from "react";

import {ICustomer} from '../../interfaces/customer'
import { Storage } from '@ionic/storage'

function useLocaleCustomerDatabase() {
    const [isMounted, setIsMounted] = useState<boolean>(true);

    const storage = new Storage();
    storage.create();
    
    const [isLocalLoaded, setIsLocalLoaded] = useState<boolean>(false);
    const [savedCustomers, setSavedCustomers] = useState<ICustomer[]>([]);

    const [isSaved, setIsSaved] = useState<boolean>(false);
    const [savedOrderIsRemoved, setSavedOrderIsRemoved] = useState<boolean>(false);

    const [updateData, setUpdateData] = useState<any>();

    React.useEffect(() => {
        setIsMounted(true);
        if(savedCustomers.length == 0 && isLocalLoaded == false && isMounted == true)
        {
            loadSavedCustomers();
            loadUpdateData();
        }
        
        return () => {
          setIsMounted(false);
        };
    }, []);

    /* load existing contact persons */
    const loadSavedCustomers = () => {
        if(isMounted == true)
        {
            if(isLocalLoaded == false){
                storage.get('customers').then((data) => {
                    if(isMounted == true)
                    {
                        if(data == null)
                            setSavedCustomers([])
                        else{
                            setSavedCustomers(data)
                            setIsLocalLoaded(true)
                        } 
                    }
                }).catch((e) => {
                    if(isMounted == true)
                    {
                        console.error(e)
                        setIsLocalLoaded(false);
                    }
                });
            }
        }
    }

    /* save customers  */
    const saveCustomers = (customers: ICustomer[]) => {
        if(customers.length > 0)
        {
            //setIsLoading(true);
            storage.remove("customers").then(()=> {
                storage
                    .set('customers', customers)
                    .then(() => {
                        if(isMounted == true)
                        {
                            setIsSaved(true);
                        }   
                    });
            })
            .catch((e) => {
                if(isMounted == true)
                {
                    console.error(e)
                    //setIsLoading(false);
                }
            });
        }
    }

    const loadUpdateData = () => {
        if(isMounted == true)
        {
            if(isLocalLoaded == false){
                storage.get('customer_update_status').then((data) => {
                    if(isMounted == true)
                    {
                        if(data == null)
                            setUpdateData([])
                        else
                            setUpdateData(data)
                    }
                }).catch((e) => {
                    if(isMounted == true)
                    {
                        console.error(e)
                        setUpdateData([])
                    }
                });
            }
        }
    }

    const saveUpdateData = (data: any) => {
        storage.remove("customer_update_status").then(()=> {
            storage
                .set('customer_update_status', data)
                .then(() => {
                    if(isMounted == true)
                    {
                        setUpdateData(data);
                    }   
                });
        })
        .catch((e) => {
            if(isMounted == true)
            {
                console.error(e)
            }
        });
    }
   
    return {   loadSavedCustomers, savedCustomers,saveCustomers, setSavedCustomers, saveUpdateData, updateData }
}

export default useLocaleCustomerDatabase ;