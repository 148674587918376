import React, {useState} from 'react';
import { IonItem, IonLabel, IonInput, IonSelect, IonSelectOption, IonGrid, IonRow, IonCol } from '@ionic/react';

import useLocalAppDatabase from '../../services/useLocalAppDatabase';
import {IHandleOrderUpdate, IMaterial, IMaterialAmountType, IOrder} from '../../interfaces/order';
interface IAmountChange {
    (event: any): void
}

interface IAmountAndType {
    material?: IMaterial,
    onChange?: IAmountChange,
    order: IOrder | undefined,
    updateOrder: IHandleOrderUpdate,
}

let recalcTimeout: any | undefined | null;

const AmountAndType: React.FC<IAmountAndType> = (props) => { 
    // proof on mounted state -> is component available or removed from 
    const [isMounted, setIsMounted] = useState<boolean>(true);
    const {localApplication, loadApplicationData, saveApplicationData} = useLocalAppDatabase();

    React.useEffect(() => {
        setIsMounted(true);
        return () => {
          setIsMounted(false);
        };
    }, []);

    const listOfTypes:any = {
        "Menge": {
            text: "Menge (in " + props.material?.unit +")",
            mode: "decimal",
            type: "decimal",
            allowedKeyCodes: [/*'Period'*/, 'NumpadDecimal','Comma','Backspace','Enter','NumpadEnter',],
        },
        "Touren": {
            text: "Anzahl an Touren",
            mode: "numeric",
            type: "number",
            allowedKeyCodes: ['Backspace','Enter','NumpadEnter',],
        },
        "Laufend": {
            text: "Anzahl Fahrzeuge",
            mode: "numeric",
            type: "number",
            allowedKeyCodes: ['Backspace','Enter','NumpadEnter', 'Delete'],
        }
    }
    
    const [typeOfAmount, setTypeOfAmount] = useState<IMaterialAmountType>();
    const [amount, setAmount] = useState<string>((props.order !== undefined ? (props.order.amount !== undefined ? String(props.order.amount) : "0") : "0"));
    const [doUpdate, setDoUpdate] = useState<boolean>(false);
    const [orderAmount, setOrderAmount] = useState<number>(0.00);
    const [recalcOrderAmount, setRecalcOrderAmount]= useState<boolean>(false);
    const [amountLabelText, setAmountLabelText] = useState<string>();
    const [makeUpdateOrder, setMakeUpdateOrder] = useState<boolean>(false);
    const [inputMode, setInputMode] = useState<"numeric" | "decimal">("numeric")

    /* proof on allowed chars in amount field */
    const handleAmountKeyDown = (event: any) => {
        if(typeOfAmount !== undefined && localApplication?.appToken === undefined && localApplication?.appToken === "")
        {
            if ((listOfTypes[typeOfAmount.type].allowedKeyCodes.includes(event.code) === false
                && event.code.substr(0, event.code.length - 1) !== 'Digit' 
                && event.code.substr(0, event.code.length - 1) !== 'Numpad')
                ||  (isNaN(parseInt(event.key)) &&  event.code.substr(0, event.code.length - 1) === 'Digit') )
            {    
                event.preventDefault();
                event.stopPropagation();
            }
        }

        if (event.code === 'Enter' || event.code === 'NumpadEnter')
            event.target.blur();
    }
    /* handle amount changements */
    const handleAmountInputChange = (event: any) => {
        if(isMounted === true)
            setAmount (event.detail.value);
    }

    const handleAmountInputBlur = (event: any) => {
        handleAmountInput();
    }

    const handleAmountInput = () => {
        if (amount === "" && isMounted === true)
        {
            setAmount("0");
            setOrderAmount(0.00);
        }
        else
        {
            let amountInEngl =  new Intl.NumberFormat('en-EN').format(parseFloat(amount.replace(',','.')));
            if(amountInEngl.indexOf(',') > -1)
                amountInEngl = amountInEngl.replace(',', '')
            
            let amountInGerman = new Intl.NumberFormat('de-DE').format(parseFloat(amountInEngl));
            if(amountInGerman.indexOf('.') > -1)
                amountInGerman = amountInGerman.replace('.', '')
        
            if(isMounted === true)
            {
                setAmount(amountInGerman);
                setOrderAmount(parseFloat(amountInEngl));
                setMakeUpdateOrder(true)
            }
        }
    }

    React.useEffect(() => {
        if (recalcOrderAmount === true && isMounted === true)
        {
            handleAmountInput();
            setRecalcOrderAmount(false)
        }
    }, [recalcOrderAmount])

    React.useEffect(() => {
        clearTimeout(recalcTimeout);
        recalcTimeout = setTimeout(() => { 
            handleAmountInput();
        }, 800);
    },[amount]);

    /* handle change of type of amount */
    React.useEffect(() => {
        if(props.order !== undefined) 
        {
            if (typeOfAmount === undefined)
            {   
                let isUpdated:boolean = false;
                if(props.order !== undefined)
                {
                    if(props.order.amountType ===  "Laufend" || props.order.amountType ===  "Menge" || props.order.amountType ===  "Touren" && isMounted === true)
                    {
                        setTypeOfAmount({type: props.order.amountType})
                        setDoUpdate(true);
                        isUpdated = true;
                    }
                }

                if (isUpdated === false && isMounted === true)
                {
                    setDoUpdate(true);
                    setTypeOfAmount({type: "Touren"})
                }
            }
            if (amount === "0" && "" + props.order.amount !== amount && props.order.amount !== undefined) 
            {
                setAmount("" + props.order.amount);
                setRecalcOrderAmount(true);
            }
        }
        
    }, [ props.order]);

    React.useEffect(() => {
        if (typeOfAmount !== undefined && doUpdate === true) 
        {
            if(typeof typeOfAmount.type === "string" )
                if(listOfTypes[typeOfAmount.type] !== undefined && isMounted === true){
                    setAmountLabelText(listOfTypes[typeOfAmount.type].text);
                    setInputMode(listOfTypes[typeOfAmount.type].mode);

                    setMakeUpdateOrder(true);
                    setDoUpdate(false);
                }
        }
    }, [ typeOfAmount]);

    /***
     *  + proof value of amounnt if type is change 
     *  + update order data
     **/ 
    React.useEffect(() => {
        if(makeUpdateOrder === true && props.order !== undefined && isMounted === true)
        {
            let currentOrder: any = {};
            if(typeOfAmount !== undefined)
                currentOrder.amountType = typeOfAmount.type;
            
            handleAmountTypeChange();
            
            currentOrder.amount = orderAmount;
            props.updateOrder(currentOrder);
            setMakeUpdateOrder(false);
            setDoUpdate(true);
        }
    }, [makeUpdateOrder, orderAmount, typeOfAmount]);

    /* proof value of amount on amount type change */
    const handleAmountTypeChange = () => {
        if(typeOfAmount !== undefined)
            if(listOfTypes[typeOfAmount?.type].type === 'number' && amount.includes(',') && isMounted === true)
            {
                setDoUpdate(true);
                let positionOfComma = amount.indexOf(',');
                setAmount(amount.substring(0, positionOfComma));
                setRecalcOrderAmount(true);
            }
    }

    return (
       <IonGrid className="amount-type-container">
            <IonRow>
                <IonCol size="6" >
                    <IonLabel>Art der Menge</IonLabel>
                    <IonItem >
                        <IonSelect 
                            cancelText="Abbrechen"
                            placeholder="Art der Menge"
                            value={typeOfAmount?.type}  
                            onIonChange={e => setTypeOfAmount({type: e.detail.value})} 
                            interfaceOptions={{header: "Art der Menge", }}> 
                            <IonSelectOption value="Menge" >Menge</IonSelectOption>
                            <IonSelectOption value="Touren">Touren</IonSelectOption>
                            <IonSelectOption value="Laufend">Laufend</IonSelectOption>
                        </IonSelect>
                    </IonItem >
                </IonCol>
                <IonCol  size="6" >
                    <IonLabel>{amountLabelText}</IonLabel>
                    <IonItem >
                        <IonInput value={amount} inputmode={inputMode} onKeyDown={handleAmountKeyDown} onIonBlur={handleAmountInputBlur} onIonChange={handleAmountInputChange} />
                    </IonItem>
                </IonCol>
            </IonRow>
       </IonGrid>
    );
  }

export default AmountAndType;