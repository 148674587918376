import React, { useEffect, useState } from "react";
import base64 from 'base-64';

import {settings} from "../../settings/settings";
import {IApiSecurity} from "../../interfaces/security";


function useGet(url: any, doGetAction: boolean = true, apiSecurity: IApiSecurity|undefined = undefined) {
  const [isMounted, setIsMounted] = useState<boolean>(true)

  const [isEmpty, setIsEmpty] = useState<boolean>(false);
  const [doGet, setDoGet] = useState<boolean>(doGetAction)
  const [data, setData] = useState<any[] | any>();
  const [loading, setLoading] = useState<boolean>(false);
  const [isLoaded, setIsLoaded] = useState<boolean>(false)
  const [error, setError] = useState<string|boolean|null>(null);

  const [unAuthorized, setUnAuthorized] = useState<boolean>();
  const [apiToken, setApiToken] = useState<string>();
  const [isReady, setIsReady] = useState<boolean>(apiSecurity !== undefined ? false : true);
  const [getData, setGetData] = useState<any>({
    method: "GET",
   // credentials: 'include',
    //mode: 'cors',
    cache: 'default',
  });

  useEffect(()=>{
    setIsMounted(true)
    handleSecurityInfo();

    return () => {
      setIsMounted(false);
    }
  }, []);
  
  const handleSecurityInfo = () => {
    if(apiSecurity !== undefined) {
      if(getData.headers?.Authorization === undefined){
        if(getData.headers !== undefined)
          delete getData.headers;

        let authInfo:string = apiSecurity.authorizationType + " ";
        let setUpAuthHeader: boolean = true;
        if(apiSecurity.authorizationType == "Basic"){
          authInfo += window.btoa(apiSecurity.user + ":" + apiSecurity.password);
        }
        else if (apiSecurity.token !== "" && apiSecurity.token !== undefined) {
          authInfo += apiSecurity.token;
        }
        else 
          setUpAuthHeader = false;

        if(setUpAuthHeader === true){
          let headers = new Headers();
          headers.set('Authorization', authInfo)
          setGetData({...getData, 
            headers: headers
          });
  
          setApiToken(apiSecurity.token);
        }
        else
          setIsReady(true);
       
      } 
    }
    else
      setIsReady(true);
  };
  
  useEffect(() => {
    if(getData.headers !== undefined){
      setTimeout(() => setIsReady(true), 100)
    }
  }, [getData]);
  
  useEffect(() => {
    if( isMounted == true && doGet == true && isReady == true){
      let completeUrl:string = (url.indexOf("http:") > -1 || url.indexOf("https:") > -1 ? url : settings.apiConfig.baseURL + url);
      setLoading(true); // set loading to true
      fetch(completeUrl, getData)
      // We get the API response and receive data in JSON format
      .then((response) => {
        if(isMounted == true && response.status == 401)
          setUnAuthorized(true);
        else if(isMounted == true && response.status != 401)
          setUnAuthorized(true);
        
        return response.json();
      })
      .then((data) => {
        if(isMounted == true)
        {
          setIsLoaded(true);
          setData(data);
        } 
      })
      .catch ((error) => {
        console.error(error);
        setError(error);
      })
      .finally(() => {
        if(isMounted == true)
          setLoading(false);
      });
    }
  }, [url, doGet]);

  // Function to call when button is clicked
  const reDoGet = (token: string = "") => {
   
    if( isMounted == true && isReady == true){
      let completeUrl:string = (url.indexOf("http:") > -1 || url.indexOf("https:") > -1 ? url : settings.apiConfig.baseURL + url);
      if(token != "") {
          setGetData({...getData, 
          headers: new Headers({
                    'Authorization': 'Bearer ' + token, 
              })
          });
          setApiToken(token);
      }
      else {
        setLoading(true); // set loading to true
        fetch(completeUrl, getData)
        // We get the API response and receive data in JSON format
        .then((response) => {  
          if(isMounted == true && response.status == 401)
            setUnAuthorized(true);
          else if(isMounted == true && response.status != 401)
            setUnAuthorized(true);
          
          return response.json();
        })
        .then((data) => {
          if(isMounted == true){
            if(data.length == 0)
              setIsEmpty(true);
            else
              setIsEmpty(false);

            setData(data);
            setIsLoaded(true);
          }
        })
        .catch ((error) => {
          console.error(error);
          setError(error);
        })
        .finally(() => {
          if(isMounted == true)
            setLoading(false);
        });
      }
    }
  };

  return { data, loading, isLoaded, setLoading, error, setError, reDoGet, setData, setDoGet, unAuthorized, isEmpty, setIsEmpty, isReady };
}

export default useGet;
