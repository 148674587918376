const timeString = (date:Date | any, includeSeconds: boolean = true, hoursChangement : number = 0) : string => {
    let result = "";

    if(date != null)
    {
        if(typeof date.getFullYear == 'function')
        {
            result += (String(date.getHours() + hoursChangement).length < 2 ? String(date.getHours() + hoursChangement).padStart(2, "0") : date.getHours() + hoursChangement) + ":";
            result += (String(date.getMinutes()).length < 2 ? String(date.getMinutes()).padStart(2, "0") : date.getMinutes()) + (includeSeconds == true ? ":00" : "");
        }
        else if( date.hour != undefined && date.minute != undefined && includeSeconds == true)
            result = date.hour.text + ":" + date.minute.text + ":" + "00";
        else if( date.hour != undefined && date.minute != undefined && date.second != undefined && includeSeconds == true)
            result = date.hour.text + ":" + date.minute.text + ":" + date.second.text;
        else if (date.hour != undefined && date.minute != undefined && includeSeconds == false)
            result = date.hour.text + ":" + date.minute.text;
    }
   
    return result;
}

const dateString = (date :Date | any) : string => {
    let result = "";
    //console.log("d", date);
    if(date != null)
    {
        if(typeof date.getFullYear == 'function')
        {
            result += date.getFullYear() + "-";
            result += (String(date.getMonth() + 1).length < 2 ? String(date.getMonth() + 1).padStart(2, "0") : date.getMonth() + 1 ) + "-";
            result += String(date.getDate()).length < 2 ? String(date.getDate()).padStart(2, "0") : date.getDate();
        }
        else if (date.day != undefined && date.month != undefined && date.year != undefined)
            result = date.year.text + "-" + date.month.text + "-" + date.day.text;
    }

    return result;
}

const dateForUX = (date: Date | string, withYear: boolean = true): string => {
    let result:string = "";
    
    if(typeof date == 'string')
        date = new Date(date);

    if(typeof date == 'object')
    {
        if(date != null)
        {    
            if(typeof date.getFullYear == 'function')
            {   
                
                result  = (String(date.getDate()).length < 2 ? String(date.getDate()).padStart(2, "0") : String(date.getDate()));
                result += '.' +  (String(date.getMonth() + 1).length < 2 ? String(date.getMonth() + 1).padStart(2, "0") : date.getMonth() + 1 );
                
                if (withYear == true)
                    result += '.' + date.getFullYear();
            }
        }
    }

    return result;
}

const deliveryDateString = (date:Date|any, timeIsUnimportortant:boolean|undefined = undefined) : string => {
    let result = "";

    if(date != null)
    {
        if(typeof date.getFullYear == 'function')
        {
            result  = (String(date.getDate()).length < 2 ? String(date.getDate()).padStart(2, "0") : date.getDate());
            result += '.' +  (String(date.getMonth() + 1).length < 2 ? String(date.getMonth() + 1).padStart(2, "0") : date.getMonth() + 1 );
            result += '.' + date.getFullYear();

            if(date.getHours() == 1 && date.getMinutes() == 0 && date.getSeconds() == 0 || timeIsUnimportortant === true)
                result +=  " (Zeitpunkt egal)";
            else
                result += ' um ' + timeString(date, false, -2) + ' Uhr';
        }
        else if (typeof date == 'string')
        {
            let dateFromString = new Date(date);
            dateFromString.setHours(dateFromString.getHours() + 1);
            result = deliveryDateString(dateFromString);
        }  
    }
    return result;
}

export {dateString, deliveryDateString, dateForUX, timeString}