import React from 'react';

import { IonIcon } from '@ionic/react';
import { refresh, refreshSharp, refreshOutline } from 'ionicons/icons';
import { useParams } from 'react-router';
import { render } from '@testing-library/react';

import {IReload} from '../../interfaces/search'

const Reload: React.FC<IReload> = (props) => {    
    return (
        <IonIcon className={"reload-icon " + props.size + (props.position != undefined ? " " + props.position : "") + (props.className != undefined ? " " + props.className : "")} 
                ios={refreshOutline} md={refreshSharp} 
                onClick={(e:any) => {
                    if(props.handleReload != undefined)
                        props.handleReload(e);
                    else
                        console.log("reload", e); 
                }}/>
    );
};

Reload.defaultProps = {
    size: "medium",
    position: "left"
}

export default Reload;
