/* interfaces */
import { IUser } from "./account";
import { ITransporter } from "./transporter";
import { IContactPerson } from "./person";
import { IHandleSearch } from "./search";
import { ICustomer } from "./customer";
import { Icon } from "ionicons/dist/types/components/icon/icon";


export interface IConstruction {
    _id?: number,
    constructionId: number
    producer_nr: string | number,
    customer_nr: string | number,
    construction_nr: string | number,
    location_id?: number,
    //location?: ILocation,
    address: string,
    additionalAddress?: string,
    memo?: string,
    street?: string,
    zip?: string,
    city?: string,
    zipAndCity?: string,
    isVisible?: boolean,
}

export function instanceOfConstruction (object: any) : object is IConstruction
{
    return 'constructionId' in object
}

export interface IMaterial {
    _id?: number,
    construction_id?: number, // id of construction
    factory_nr: number | string,
    material_nr: number | string,
    retrieval_nr?: number | string, // abruf nummer
    name: string,
    group?: string,
    description?:  string,
    additional?: string,
    info?: string,
    price?: number | string,
    unit: string,
    total?: number,
    isVisible?: boolean,
}

export interface IMaterialTour {
    type: "Touren"
}
export interface IMaterialAmount {
    type: "Menge"
}
export interface IMaterialRunning {
    type: "Laufend"
}

export interface IMaterialAmountType{
    type: "Touren" | "Menge" | "Laufend", 
}

export function instanceOfMaterial (object: any) : object is IMaterial
{
    return 'material_nr' in object
}

/* order and order stage */
export interface IOrderingStage {
    name: string,
    title: string,
    description: any | string,
    hasSearch?: boolean,
    handleSearch?: IHandleSearch,
    searchItems?: (IConstruction | ICustomer | IMaterial )[],
    minSearchChars?: number, 
    hasBackButton?: boolean,
    fetchURL?: string,
    stageNumber: number,
    active?: boolean,
    hasOwnTemplate?: boolean, // for page without ordering container
    template?: any,
}

export interface IHandleOrderUpdate {
    (order: IOrder, movement?: string): void
}

export interface IUpdateSearchItems {
    (items: IMaterial[] | IConstruction[] | any[], stageNumber: any): void
}

export interface IHandleOrder {
    title?:string,
    order: IOrder|undefined,
    doReload?: boolean,
    stageNumber?: number,
    updateOrder: IHandleOrderUpdate,
    updateSearchItems?: IUpdateSearchItems,
    //handleU
    reloadData?: boolean,
    user?: IUser | undefined,
}

export function instanceOfOrder (object: any) : object is IOrder
{
    return 'primkey' in object
}

export interface IOrder {
    _id?: number,
    orderNumber?: string,
    primkey?: number | string,
    status?: string,
    status_code?: string,
    creationData?: Date,
    currentStage?: number,
    //background_color: string,
    //statusImage: string,
    //statusImageButton: string,
    user?: IUser,
    /* customer */
    customer?: ICustomer,
    
    transporter?: ITransporter,
    contactPerson?: IContactPerson,
    construction?: IConstruction,
   
    /* material, unit and amount */
    material?: IMaterial,
    amountType?: string,
    amount?: number,
    amountWithUnit?: string,
    /* comment */
    comment?: string,
    /* price | total of the order */
    totalPrice?: number,
    /* date and times */
    orderDate?: Date, // currently used for delivery date => TODO: change to deliveryDate; use orderDate for saving the date of the order [Date: 2021-12-02]
    orderTime?: string, // currently not in use => time is in orderDate
    deliveryDate?: Date, // should be used for the date of the delivery => TODO: use instead of orderDate 
    deliveryTimeIsUnimportant?: boolean,
    /* update && delete status && is_finished status */
    isFinished?: boolean,
    finishTime?: Date,

    isUpdated: boolean,
    updateTime?: Date,
    isDeleted: boolean,
    doLocalRemovement?: boolean,
    appToken?: string,
}


export interface IOrderDetails {
    order_id: number | string,
    amountOfVehicles: number,
    total_amount: string |  number,
    amountType: string,
    contactPerson: IContactPerson,
    deliveryDate: Date,
    orderDate: Date,
    order_start: Date,
    order_end: Date,
}