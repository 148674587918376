import React from 'react';

import { IonButton, IonAlert, useIonAlert } from '@ionic/react';
import { useParams } from 'react-router';
import { render } from '@testing-library/react';

interface IDoRestart {
    (event:any) : void
}
interface IRestart {
    doRestart: IDoRestart
}

const RestartContainer: React.FC<IRestart> = (props) => {    
    const [restartCheck] = useIonAlert();
    return (
        <div className="restart-container">
            <IonButton 
                color="light" slot="start"  shape="round" 
                onClick={(e) => {
                    restartCheck({
                        cssClass: 'restart',
                        header: 'Neustart der Bestellung',
                        message: 'Wollen Sie die Bestellung von vorne beginnen?',
                        buttons: [
                            { text: 'Ja', cssClass: "do", handler: props.doRestart },
                            'Nein',
                            
                        ],
                    })
                }} 
            >
                Neustart
            </IonButton>
        </div>
    );
};

export default RestartContainer;
