import { IonButtons, IonButton, IonImg, IonPage, IonGrid, IonRow, IonCol } from '@ionic/react';
import { useParams } from 'react-router';

// interfaces 
import { IApplication } from '../interfaces/application';
import {IHandleOrder} from '../interfaces/order'
import { IUser } from '../interfaces/account';
// components
import Base from '../components/base/Base';
import WelcomeContainer from '../components/WelcomeContainer';

interface IHandleButtonClickBefore {
  (e:any): void,
}

export interface IWelcomeContainer extends IApplication{
  localUser?: IUser
  hasToOrderListButton: boolean,
  handleButtonClickBeforeAction?: IHandleButtonClickBefore,
  handleOrder?: IHandleOrder,
}

const Welcome: React.FC<IWelcomeContainer> = (props) => {

  const { name } = useParams<{ name: string; }>();

  return (
    <IonPage>
      <Base extraClassName='welcome' name={name} title="">
          <WelcomeContainer {...props}/> 
      </Base>
    </IonPage>
  );
};

export default Welcome;
