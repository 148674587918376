import React, {Component, MouseEventHandler, useState} from 'react';

interface IError {
    error: string | undefined
}

const Error: React.FC<IError> = (prop) => { 
    let error = null;

    if(String(prop.error) != "" && prop.error != undefined)
        error = <p className="error center">{prop.error}</p>
    return error;
}
  
export default Error