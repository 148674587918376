import React, {useState} from 'react';
import { IConstruction, IHandleOrder } from '../../interfaces/order';

import ItemList from '../../components/orders/ItemList';
import usePost from '../../services/hooks/usePost';
import useGet from '../../services/hooks/useGet';
const Constructions: React.FC<IHandleOrder> = (props) => { 
    const { data, loading, reDoGet, setData: setValidationData, setDoGet, unAuthorized, isReady: isReadyForApiCall } = useGet("construction/tbkuid-"  + (props.order?.customer?.id != undefined ? props.order.customer.id : props.user?.tbkuid), false, props.user?.token != undefined ? {authorizationType: "Bearer", token: props.user.token} : undefined);

    React.useEffect(() => {
        if(props.doReload == true){
            setConstructions(undefined);
        }
            
    },[props.doReload])
    
    const [constructions, setConstructions] = useState<IConstruction[]>();
    const builtConstructionsFromData = () => {
        let constructionsList:IConstruction[] = data.map((constructionData: any, index:number) => {
            constructionData.isVisible = true;
            return constructionData;
        })

        setConstructions(constructionsList);
    }

    React.useEffect(() => {
        if(data != undefined)
        {
            if (data.length != undefined)
            {
                if (data.length > 0)
                    builtConstructionsFromData();
            }   
        }
    }, [data])

    React.useEffect(() => {
        if(constructions == undefined && props.user != undefined && props.user?.roles?.includes("ROLE_NORMAL"))
            reDoGet(props.user?.token != undefined ? props.user.token : "");
        else if(constructions == undefined && props.order != undefined && props.user?.roles?.includes("ROLE_SALE"))
            reDoGet(props.user?.token != undefined ? props.user.token : "");
        
        if (typeof props.updateSearchItems == 'function' && constructions != undefined)
            props.updateSearchItems(constructions, props.stageNumber);
    }, [constructions, props.order?.customer, isReadyForApiCall])
    
    const handleClick = (event: any) =>{
        let elem = event.target,
            selectedIndex,
            currentOrder: any = {};

        if(elem.nodeName != 'ION-ITEM')
            elem = event.target.closest('ion-item')

        selectedIndex = elem.getAttribute('data-list-index')
        
        if(currentOrder == undefined)
            currentOrder = {isUpdated: false, isDeleted:false}
        
        if(constructions != undefined)
        {
            currentOrder.construction = constructions[selectedIndex];
            props.updateOrder(currentOrder, "forwards");
        }  
    }
    
    React.useEffect(() => {
        if(props.user != undefined && constructions != undefined )
        {
            //setTimeout(() => { setDoGet(true);},1000)
           
        }    
       
    }, [props.user])
    return (<ItemList emptyMessage="Keine aktuelle Baustelle vorhanden." items={constructions != undefined ? constructions : []} onClick={handleClick} loading={loading}></ItemList>)
}

export default Constructions;