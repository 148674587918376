import {ISettings} from './../interfaces/settings';

const settings : ISettings =  {
    terms_link: "https://gebr-kaltenbach.de/fileadmin/INHALTE/unternehmen/agb-downloads/agb/AGB_Kaltenbach.pdf",
    dataprotection_link: "https://gebr-kaltenbach.de/fileadmin/INHALTE/datenschutz/hinweis-kunden.pdf",
    apiConfig: {
        baseURL: "https://api.gebr-kaltenbach.de/api/v2/",
        authConfig: {
            username: "api_geb_KaLBaCH20",
            password: "olIhfHb2!arRtG",
        }
    },
    appData: {
        appName: "Kaltenbach Rockstar App",
        appShortCut: "kaltenbach",
        appPackagename: "com.tbsoft.minerals.kaltenbach",
        appLogo: "",
        loginType: "multiple",
    },
    headerLogo: "",
    producer_nr: 1,
    originDomain: "kaltenbach-rockstar-web.de",
};

export {settings}