import React, { useEffect, useState } from "react";

import {IOrder} from '../../interfaces/order'
import { Storage } from '@ionic/storage'

function useLocalOrderDatabase() {
    const [isMounted, setIsMounted] = useState<boolean>(true);

    const storage = new Storage();
    storage.create();
    
    const [isLocalLoaded, setIsLocalLoaded] = useState<boolean>(false);
    const [savedOrder, setSavedOrder] = useState<IOrder>();
    const [isSaved, setIsSaved] = useState<boolean>(false);
    const [savedOrderIsRemoved, setSavedOrderIsRemoved] = useState<boolean>(false);

    React.useEffect(() => {
        setIsMounted(true);
        if(savedOrder == undefined && isLocalLoaded == false && isMounted == true)
            loadSavedOrder();

        return () => {
          setIsMounted(false);
        };
    }, []);

    /* load existing contact persons */
    const loadSavedOrder = () => {
        if(isMounted == true)
        {
            if(isLocalLoaded == false){
                storage.get('actual_order').then((data) => {
                    if(isMounted == true)
                    {
                        if(data == null)
                            setSavedOrder({isUpdated: false, isDeleted:false, currentStage: 0})
                        else{
                            setSavedOrder(data)
                            setIsLocalLoaded(true)
                        } 
                    }
                }).catch((e) => {
                    if(isMounted == true)
                    {
                        console.error(e)
                        setIsLocalLoaded(false);
                    }
                });
            }
        }
    }

    /* update contact person */
    React.useEffect(() => {
        if(savedOrder != undefined)
            updateSavedOrder();
    }, [savedOrder])

    const updateSavedOrder = () => {
        storage.remove("actual_order").then(()=> {
            storage
                .set('actual_order', savedOrder)
                .then(() => {
                    if(isMounted == true)
                        setIsSaved(true);
                });
        })
    }

    const removeSavedOrder = () => {
        storage.remove("actual_order")
               .then(()=> {
                    if(isMounted == true){
                        setSavedOrderIsRemoved(true)
                        setSavedOrder({isDeleted: false, isUpdated: false, currentStage:0});
                    }
               })
               .catch((e) => {console.error(e);})
    }

    return { removeSavedOrder, savedOrderIsRemoved, setSavedOrderIsRemoved, isLocalLoaded, loadSavedOrder,  savedOrder, setSavedOrder }
}

export default useLocalOrderDatabase;