import {
  IonContent,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonMenu,
  IonMenuButton,
  IonMenuToggle,
  IonNote,
} from '@ionic/react';

import React, { useEffect, useState, useContext } from "react";
import { useLocation } from 'react-router-dom';
import { archiveOutline, archiveSharp, bagSharp, bagOutline, bookmarkOutline, clipboard, clipboardOutline, clipboardSharp, heartOutline, heartSharp, logOutOutline, logOutSharp, mailOutline, mailSharp, menuOutline, menuSharp, newspaperOutline, newspaperSharp, paperPlaneOutline, paperPlaneSharp, trashOutline, trashSharp, warningOutline, warningSharp } from 'ionicons/icons';
import './Menu.css';

import { settings } from '../../settings/settings';

import {IAppPage} from '../../interfaces/application';

import useGet from '../../services/hooks/useGet';
import useLocalUserDatabase from '../../services/account/useLocalUserDatabase';
import useLocalModuleData from '../../services/useLocalModuleData';
import useCheckAvailableModule from '../../services/hooks/useCheckAvailableModule';

interface MenuProps{
  isOpen?: boolean | true
}

const defaultProps: MenuProps = {
  isOpen: true,
}

const appPages: IAppPage[] = [
  {
    title: 'Bestellen',
    url: '/bestellen',
    iosIcon: bagOutline,
    mdIcon: bagSharp,
    role: "ROLE_ALL",
    itemcallname: "Ordering",
  },
  {
    title: 'Bestellungen',
    url: '/bestellungen',
    iosIcon: newspaperOutline,
    mdIcon: newspaperSharp,
    role: "ROLE_NORMAL",
    itemcallname: "Order",
  },
  {
    title: 'Lieferungen',
    url: '/lieferungen',
    iosIcon: clipboardOutline,
    mdIcon: clipboardSharp,
    role: "ROLE_NORMAL",
    itemcallname: "DeliveryStatus",
  },
  {
    title: 'Logout',
    url: '/logout',
    iosIcon: logOutOutline,
    mdIcon: logOutSharp,
    role: "ROLE_ALL",
    itemcallname: "Logout",
  },
];

let menuCheckInterval:number|any = 0;

const Menu: React.FC<MenuProps> = (props) => {
  const location = useLocation();
  const [isMounted, setIsMounted] = useState<boolean>(true);

  const {localUser} = useLocalUserDatabase(undefined)

  const { data: moduleData, setData: setModulData, reDoGet: getModuleData, isReady: moduleCallIsReady, error: moduleError } = useGet("https://control.tbsoft.de/api/v0/modules/package-" + settings.appData.appPackagename + "?env=prod", false, {authorizationType: "Basic", user: "tbsoftApi", password: "2020tbsoftAPi"});
  const {saveModuleData, localModules} =  useLocalModuleData();
 
  const [firstPageLoad, setFirstPageLoad] = useState<boolean>(true);
  const {checkAvailablityOfModule, currentModule, localModules: localCheckModules, loadModuleData: loadCheckModuleData, moveToAvailableModule} = useCheckAvailableModule();
  
  const [currentModules,  setCurrentModules] = useState<IAppPage[]>(appPages);

  React.useEffect(() => {
    return () => {
      setIsMounted(false);
      clearInterval(menuCheckInterval);
      menuCheckInterval = 0;
    };
  }, []);

  React.useEffect(() => {
    if(localCheckModules !== undefined && currentModule != undefined) {
        setFirstPageLoad(false);
        buildMenuByLocalModules();

        if(currentModule !== undefined){
          let available = checkAvailablityOfModule(currentModule?.itemcallname);
          if(available === false){
            moveToAvailableModule();
          }
        }
    }
  }, [localCheckModules])

  React.useEffect(() => {
    let isAvailable = checkAvailablityOfModule(location.pathname);
    if(isAvailable === false && firstPageLoad === true)
      buildMenuByLocalModules();
  }, [location.pathname]);

  React.useEffect(() => {
    if(menuCheckInterval == 0 && moduleCallIsReady == true)
      menuCheckInterval = setInterval(() => {getModuleData(); }, 1800000);

    if(moduleData === undefined && moduleCallIsReady == true)
      getModuleData();
    else if ((moduleError === false || moduleError === null) && moduleData !== undefined ) {
      saveModuleData(moduleData);
      setTimeout(() => {loadCheckModuleData(); }, 1000);
    }
  }, [moduleData, moduleCallIsReady, moduleError]);

  const buildMenuByLocalModules = ()  => {
    if(localModules !== undefined) {
      let modules:IAppPage[] = [];
      // Add available modules 
      localModules.forEach((localItem, index) => {
        appPages.forEach((appItem, appIndex) => {
          if(appItem.itemcallname == localItem.itemcallname)
            modules.push(appItem);
        });
      });

      // Add Logout module / link
      appPages.forEach((appItem, appIndex) => {
        if(appItem.itemcallname == "Logout")
          modules.push(appItem);
      });

      setCurrentModules(modules);
    }
  };

  return (
    <IonMenu contentId="main" type="reveal">
      <IonContent className={props.isOpen ? "show" : "hide"}>
        <IonList id="main-nav-list" >
        {
          (
            currentModules.map((modul, index) => {
              return (
              modul.role == "ROLE_ALL"  || localUser?.roles?.includes(modul.role) ? (
                <IonMenuToggle key={index} autoHide={false}>
                  <IonItem className={location.pathname === modul.url ? 'selected' : ''} routerLink={modul.url} routerDirection="none" lines="none" detail={false}>
                    <IonIcon slot="start" ios={modul.iosIcon} md={modul.mdIcon} />
                    <IonLabel>{modul.title}</IonLabel>
                  </IonItem>
                </IonMenuToggle>
                ) 
                : null
              );
            })
          )
        }
        </IonList>
      </IonContent>
    </IonMenu>
  );
};

Menu.defaultProps = defaultProps;

export default Menu;
