import React, { useEffect, useState } from "react";

import {IContactPerson} from '../../interfaces/person'
import { Storage } from '@ionic/storage'

function useLocalContactPersonDatabase() {
    const [isMounted, setIsMounted] = useState<boolean>(true)

    const storage = new Storage();
    storage.create();
    
    const [isLocalLoaded, setIsLocalLoaded] = useState<boolean>(false)
    const [localListOfContactPerson, setLocalListOfContactPerson] = useState<IContactPerson[]>([])
    const [isSaved, setIsSaved] = useState<boolean>(false)

    React.useEffect(() => {
        if(localListOfContactPerson.length == 0 && isLocalLoaded == false)
            loadExistingContactPersons();

        return () => {
          setIsMounted(false);
        };
    }, []);

    /* load existing contact persons */
    const loadExistingContactPersons = () => {
        if(isMounted == true)
        {
            setIsLocalLoaded(true)
            if(isLocalLoaded == false){
                storage.get('contact_person').then((data) => {
                    if(isMounted == true)
                    {
                        if(data == null)
                            setLocalListOfContactPerson([])
                        else
                            setLocalListOfContactPerson(data)
                    }
                        
                }).catch((e) => {
                    if(isMounted == true)
                    {
                        console.error(e)
                        setIsLocalLoaded(false);
                    }
                });
            }
        }
    }

    /* save contact person */
    const saveContactPerson = (contactPerson:IContactPerson) => {
        
        let personId:number                 = 1,
            updateExistingPerson:boolean    = false,
            list:IContactPerson[]           = localListOfContactPerson.map((person, index) => {
                personId = (person._id != undefined ? person._id + 1 : 1); 

                if (person._id  == contactPerson._id)
                {
                    updateExistingPerson = true;
                    person = contactPerson;
                }
                    
                return person;
            })

       
        
        if (updateExistingPerson == false){
            contactPerson._id = personId;
            list.push(contactPerson);
        }

        setLocalListOfContactPerson(list)
    }

    /* update contact person */
    React.useEffect(() => {
        if(localListOfContactPerson.length > 0)
        {
            updateListOfContactPersons();
        }
    }, [localListOfContactPerson])

    const updateListOfContactPersons = () => {
        storage.remove("contact_person").then(()=> {
            storage
                .set('contact_person', localListOfContactPerson)
                .then(() => {
                    if(isMounted == true)
                        setIsSaved(true);
                });
        })
    }

    return { loadExistingContactPersons, localListOfContactPerson, saveContactPerson, useLocalContactPersonDatabase }// loading, error};
}

export default useLocalContactPersonDatabase;
