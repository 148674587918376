import { IConstruction, IMaterial } from "./order";
import { IOrderDetails } from "./order";


export function instanceOfDelivery (object: any) : object is IDelivery
{
    return 'delivery_note_nr' in object
}


export interface IDeliveryAmountInfo {
    completeAmountOfMaterialWithUnit: string | number,
    completeProgressInPercentage: number  
    completeProgressValue: number,
    completeAmountOfMaterial: string | number,
    completeTourAmount?: number | string,
}

export interface IDeliveryDetails{
    showExtra:boolean,
    scalingTime: Date,
    deliveryTime: Date,
    delivery_note_nr: string | number,
    amount: number | string,
    unit: string,
}

export interface IDelivery {
    producer_nr: string | number,
    customer_nr: string | number,
    contract_nr: string | number,
    date: Date,
    material: IMaterial,
    complete_scaled_amount: number,
    amount: number,
    is_canceled: boolean | number,
    order_details: IOrderDetails,
    delivery_note_nr: string,
    scalingTime: Date,
    deliveryTime: Date,
    amountInfo: IDeliveryAmountInfo,
    details: IDeliveryDetails[],
}

export type IDeliveryMode = 'daily' | 'total';

export interface IDeliveryParams {
    mode: IDeliveryMode,
    start_date: Date,
    end_date: Date,
    construction: IConstruction,
}