import React, { useEffect, useState } from "react";

import {IDelivery, IDeliveryParams} from '../../interfaces/delivery'
import { Storage } from '@ionic/storage'

function useLocalDeliveryListDatabase() {
    const [isMounted, setIsMounted] = useState<boolean>(true);

    const storage = new Storage();
    storage.create();
    
    const [isLocalDeliveryListLoaded, setIsLocalDeliveryListLoaded] = useState<boolean>(false);
    const [savedDeliveryList, setSavedDeliveryList] = useState<IDelivery[]>();
    const [deliveryListIsSaved, setDeliveryListIsSaved] = useState<boolean>(false);

    const [isLocalParamsEmpty, setIsLocalParamsEmpty] = useState<boolean>();
    const [isLocalParamsLoaded, setIsLocalParamsLoaded] = useState<boolean>(false);
    const [savedDeliveryParams, setSavedDeliveryParams] = useState<IDeliveryParams>();
    const [deliveryParamsAreSaved, setDeliveryParamsAreSaved] = useState<boolean>(false);

    React.useEffect(() => {
        setIsMounted(true);
        if(isLocalDeliveryListLoaded == false && isMounted == true)
            loadDeliveryList();

        if(isLocalParamsLoaded == false && isMounted == true)
            loadDeliveryParams();
        
        return () => {
          setIsMounted(false);
        };
    }, []);

    /* load existing delivery data */
    const loadDeliveryList = () => {
        if(isMounted == true)
        {
            if(isLocalDeliveryListLoaded == false){
                storage.get('delivery_data').then((data) => {
                    if(isMounted == true)
                    {
                        if(data == null)
                            setSavedDeliveryList([]);
                        else
                            setSavedDeliveryList(data)
                        
                        setIsLocalDeliveryListLoaded(true)
                    }
                }).catch((e) => {
                    if(isMounted == true)
                    {
                        console.error(e)
                        setIsLocalDeliveryListLoaded(false);
                    }
                });
            }
        }
    }

    const updateDeliveryList = (data:IDelivery[] | undefined) => {
        storage.remove("delivery_data").then(()=> {
            if(data != undefined )
            {
                storage
                    .set('delivery_data', data)
                    .then(() => {
                        if(isMounted == true)
                        {
                            setDeliveryListIsSaved(true);
                            setSavedDeliveryList(data);
                        }
                            
                    });
            }
            else 
            setSavedDeliveryList(undefined)
        })
    };

    /********
     * Delivery Parameters
     */
    /* load existing delivery parameters */
    React.useEffect (() => {
        if( isLocalParamsEmpty != undefined)
            setIsLocalParamsLoaded(true);

    }, [savedDeliveryParams,isLocalParamsEmpty, isLocalParamsLoaded] )


    const loadDeliveryParams = () => {
        if(isMounted == true)
        {
            if(isLocalParamsLoaded == false){
                storage.get('delivery_params').then((data) => {
                    if(isMounted == true)
                    {
                        if(data != null)
                        {
                            setSavedDeliveryParams(data);
                            setIsLocalParamsEmpty(false);
                        }   
                        else 
                        {
                            setIsLocalParamsEmpty(true);
                        }
                           
    
                    }
                }).catch((e) => {
                    if(isMounted == true)
                    {
                        console.error(e)
                        setIsLocalParamsLoaded(false);
                    }
                });
            }
        }
    }

    const updateDeliveryParams = (data:IDeliveryParams | undefined) => {
        storage.remove("delivery_params").then(()=> {
            if(data != undefined )
            {
                storage
                    .set('delivery_params', data)
                    .then(() => {
                        if(isMounted == true)
                        {
                            setDeliveryParamsAreSaved(true);
                            setSavedDeliveryParams(data);
                        }
                            
                    });
            }
            else 
                setSavedDeliveryParams(undefined)
        })
    };

    return { isLocalDeliveryListLoaded, isLocalParamsEmpty, savedDeliveryList, updateDeliveryList, deliveryListIsSaved,
             isLocalParamsLoaded, savedDeliveryParams, updateDeliveryParams, deliveryParamsAreSaved }
}

export default useLocalDeliveryListDatabase;