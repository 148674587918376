import React, {useState} from 'react';

import { IHandleOrder, IMaterial} from '../../interfaces/order';
import ItemList from '../../components/orders/ItemList';
import useGet from '../../services/hooks/useGet';
import {settings} from '../../settings/settings';

const Materials: React.FC<IHandleOrder> = (props) => { 

    const { data, loading, reDoGet, setDoGet, unAuthorized, isReady: isReadyForConstruction } = useGet("material/producer-" +  (props.order?.customer?.producer_nr != undefined ? props.order.customer.producer_nr : props.user?.producer_nr) + "/customer-" + (props.order?.customer?.customer_nr != undefined ? props.order.customer.customer_nr : props.user?.customer_nr) + "/construction-" + props.order?.construction?.construction_nr, false, props.user?.token != undefined ? {authorizationType: "Bearer", token: props.user.token} : undefined);
    const { data: extraMaterial, loading: extraLoading, reDoGet: fetchExtraMaterial, setData: setExtraMaterial, setDoGet: setDoFetchingExtraMaterial, isReady: isReadyForAllConstruction } = useGet("material/producer-" +  (props.order?.customer?.producer_nr != undefined ? props.order.customer.producer_nr : props.user?.producer_nr) + "/customer-" + (props.order?.customer?.customer_nr != undefined ? props.order.customer.customer_nr : props.user?.customer_nr) + "/construction-99999999", false, props.user?.token != undefined ? {authorizationType: "Bearer", token: props.user.token} : undefined);
    
    React.useEffect(() => {
        if(props.user != undefined  && props.order?.construction?.construction_nr != undefined && materials == undefined)
        {
            reDoGet(props.user.token);
            
            if(settings.appData.appPackagename == "com.tbsoft.minerals.kaltenbach")
                fetchExtraMaterial(props.user?.token != undefined ? props.user.token : "");
        }
    }, [props.user, props.order?.construction, isReadyForAllConstruction])

    React.useEffect(() => {
        if(props.doReload == true)
            setMaterials(undefined);    
    },[props.doReload])

    const [materials, setMaterials] = useState<IMaterial[]>()

    const builtMaterialsFromData = (someData: any = null) => {
        let materialList:(IMaterial)[] = [];
        if(data != undefined)
            materialList= data.map((materialData: any, index:number) => {
                materialData.isVisible = true;
                return materialData;
            })

        if(someData != undefined)
        {
            someData.forEach((item:any, index: number) => {
                item.isVisible = true;
                materialList.push(item);
            });
        }

        setMaterials(materialList);
    }

    React.useEffect(() => {
        if(data != undefined)
        {
            if (data.length != undefined)
            {
                if (data.length > 0)
                    builtMaterialsFromData();
            }   
        }
    }, [data])

    React.useEffect(() => {
        if(extraMaterial != undefined)
        {
            if (extraMaterial.length != undefined)
            {
                if (extraMaterial.length > 0)
                    builtMaterialsFromData(extraMaterial);
            }   
        }
    }, [extraMaterial])

    React.useEffect(() => {
        if(materials == undefined && props.user != undefined && props.order?.construction !== undefined && props.user?.roles?.includes("ROLE_NORMAL"))
            reDoGet(props.user?.token != undefined ? props.user.token : "");
        else if(materials == undefined && props.order != undefined && props.user?.roles?.includes("ROLE_SALE"))
            reDoGet(props.user?.token != undefined ? props.user.token : "");

        if (typeof props.updateSearchItems == 'function' && materials != undefined){
            props.updateSearchItems(materials, props.stageNumber);
        }
    }, [materials, props.order?.construction, isReadyForConstruction])

    const handleClick = (event: any) =>{
        let elem = event.target,
            selectedIndex,
            currentOrder: any = {};
        
        if(elem.nodeName != 'ION-ITEM')
            elem = event.target.closest('ion-item')

        selectedIndex = elem.getAttribute('data-list-index')
        
        if(currentOrder == undefined)
            currentOrder = {isUpdated: false, isDeleted:false}
        
        if(materials != undefined)
        {
            currentOrder.material = materials[selectedIndex];
            props.updateOrder(currentOrder, "forwards");
        }
    }
      
    return <ItemList emptyMessage='Es wurden kein passendes Material zu dieser Baustelle gefunden.' items={materials != undefined ? materials : []} loading={loading} onClick={handleClick} />
}

export default Materials;