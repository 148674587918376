import React from 'react';

import { IonGrid, IonRow, IonCol, IonIcon } from '@ionic/react';
import { refresh, refreshSharp, refreshOutline } from 'ionicons/icons';

import { render } from '@testing-library/react';
import { useParams } from 'react-router';

import {IOrderSerarch} from '../../interfaces/search'
import Search from '../base/Search'
import Reload from '../base/Reload';

const SearchBar: React.FC<IOrderSerarch> = (props) => { 
    const [resetSearch, setResetSearch] = React.useState<boolean| null>(null);

    const handleReload = (e: any) => {
        setResetSearch(true);
        
        if(props.handleReload != undefined)
            props.handleReload(e);
        else
            console.log("reload", e);
    }

    React.useEffect(() => {
        if(resetSearch == true)
            setTimeout(() => {setResetSearch(null)}, 500);
    }, [resetSearch])

    return (
        <IonGrid className="search-container">
            <IonRow>
                <IonCol size="11">
                    <Search searchDisabled={props.searchDisabled} handleSearch={props.handleSearch} resetSearch={resetSearch != null ? resetSearch : props.resetSearch} noSearchResult={props.noSearchResult} minSearchChars={props.minSearchChars}/>
                </IonCol>
                <IonCol size="1">
                    <Reload handleReload={handleReload} />
                </IonCol>
            </IonRow>
        </IonGrid>
    );
}

export default SearchBar;
