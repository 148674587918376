import React, { useEffect, useState } from "react";
import axios, { AxiosRequestConfig } from "axios";

import {settings} from "../../settings/settings";

axios.defaults.baseURL = settings.apiConfig.baseURL;
//axios.defaults.auth = {username: settings.apiConfig.authConfig.username, password: settings.apiConfig.authConfig.password}

function useFetch(url: any, params: any = {}) {
  const [isMounted, setIsMounted] = useState<boolean>(true)

  const [data, setData] = useState<any>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(()=>{
    return () => {
      setIsMounted(false);
    }
  }, [])

  useEffect(() => {
    if(params.ignoreAPICall != true && isMounted == true){
      setLoading(true); // set loading to true

      axios
        .get(url, {params: params,})
        .then((response) => {
          if(isMounted == true)
          {
            console.log(response);
            setData(response.data);
          }
        })
        .catch((err) => {
          if(isMounted == true)
            setError(err);
        })
        .finally(() => {
          if(isMounted == true)
            setLoading(false);
        });
    }
  }, [url]);

  // Function to call when button is clicked
  const refetch = () => {
    if(params.ignoreAPICall != true && isMounted == true){
      setLoading(true); // set loading to true
      axios
        .get(url, {params: params,})
        .then((response) => {
          if(isMounted == true)
            setData(response.data);
        })
        .catch((err) => {
          if(isMounted == true)
            setError(err);
        })
        .finally(() => {
          if(isMounted == true)
            setLoading(false);
        });
    }
  };

  return { data, loading, error, refetch };
}

export default useFetch;
