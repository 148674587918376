import React, { useEffect, useState } from 'react';

import {IonButton, IonPage } from '@ionic/react';
import { render } from '@testing-library/react';
import { useParams } from 'react-router';

// interfaces
import { IApplication } from '../../interfaces/application';
import { IOrder } from '../../interfaces/order';

// components
import Base from '../../components/base/Base';
import Reload from '../../components/base/Reload';
import ItemList from '../../components/orders/ItemList';

// hooks & services
import useLocalUserDatabase from '../../services/account/useLocalUserDatabase';
import useLocalOrderListDatabase from '../../services/orders/useLocalOrderListDatabase';

import  useInternalNavigate  from '../../services/hooks/internalNavigate';
import useGet from '../../services/hooks/useGet';

let orderChecker: any = undefined;
/***
 * List of current orders
 */
const Orders: React.FC<IApplication> = (props) => { 
  let name = "Bestellungen";

  const [isMounted, setIsMounted] = useState<boolean>(true);
  const {navigateInternal: navigateToOrdering} = useInternalNavigate("ordering");

  React.useEffect(() => {
    setIsMounted(true);
    return () => {
      setIsMounted(false);
      clearInterval(orderChecker);
    };
  }, []);

  React.useEffect(() => {
    if(props.currentPath == props.pagePath)
      orderChecker = setInterval(() => {setFirstDataLoaded(undefined)}, 30000)
    else 
    {
      clearInterval(orderChecker);
    }  
  }, [props.currentPath]);

  const {localUser} = useLocalUserDatabase(undefined);
  const { data: firstDataLoad, setData: setFirstDataLoaded, loading, reDoGet: getFirstDataLoad, setDoGet, unAuthorized, isReady: isReadyForApiCall } = useGet("pre-order/user/" + localUser?.user_id, false, localUser?.token != undefined ? {authorizationType: "Bearer", token: localUser.token} : undefined );

  const {isLoading, isLocalLoaded, setIsLocalLoaded, setSavedOrderList, savedOrderList, loadSavedOrderList, } = useLocalOrderListDatabase();
  const [preparedOrderList, setPreparedOrderList] = useState<IOrder[]>([]);

  React.useEffect(() => {
    if(localUser != undefined)
    {
      if ( localUser.token != undefined && localUser.roles?.includes("ROLE_NORMAL")) //&& localUser.roles?.includes("NORMAL_USER"))
        getFirstDataLoad(localUser.token);
      else
        navigate();
    }
  }, [localUser, isReadyForApiCall]);

  const navigate = () => {navigateToOrdering();}


  React.useEffect(() => {
    if(props.pathHasChanged == true && props.currentPath == props.pagePath)
      setFirstDataLoaded(undefined);
  }, [props.pathHasChanged])


  const handleOrderStatus = (order: IOrder, setUpdated:boolean = false) : IOrder => {
    if(order.status_code == 'S')
      order.isDeleted = true;
    else if(setUpdated == true)
      order.isUpdated = true;

    order.status = setOrderStatusText(order);

    return order;
  };

  const setOrderStatusText = (order: IOrder) : string => {
    let status = "";

    switch(order.status_code)
    {
      case "R":
          status = "Rückfrage(n)";
          break;
      case "S":
          status = "Storniert";
          break;
      case "U": 
          status = "Disponiert";
          break;
      default:
          status = "Vordisposition";
    }

    return status;
  };

  React.useEffect(() => {
    if(firstDataLoad == undefined && localUser != undefined)
      getFirstDataLoad();

    if(firstDataLoad != undefined && isLocalLoaded == true)
    {
      let doCheckOnExistingOrders: boolean = false;
      
      if(savedOrderList != undefined)
      {
          //console.log(savedOrderList, savedOrderList.length)
          if(savedOrderList.length > 0)
            doCheckOnExistingOrders = true;
      }

      if (doCheckOnExistingOrders == false)
      {
        let orders = firstDataLoad.map((order: IOrder, index:number | string) => {

          return handleOrderStatus(order, order.isUpdated);
        });
        setSavedOrderList(orders);
      }
      else if (firstDataLoad != undefined && firstDataLoad.map != undefined )
      {
        let restOfSavedOrders:IOrder[] = (savedOrderList != undefined ? [...savedOrderList] : []);

        let orders = firstDataLoad.map((order: IOrder, index:number | string) => {
            let doUpdate:boolean = false;
            let currentOrder: IOrder|undefined = undefined;
            // proof on existing local order
            
            restOfSavedOrders.map((rest: IOrder, restIndex:number) => {
              if(rest.primkey == order.primkey && rest.updateTime != undefined && order.updateTime != undefined)
              {
                if(rest.updateTime < order.updateTime)
                {
                  doUpdate                = true;
                  currentOrder  = {...order};
                  currentOrder  = handleOrderStatus(currentOrder);
                  restOfSavedOrders.splice(restIndex, 1);
                }
                else 
                {
                  doUpdate                = true;
                  currentOrder  = {...rest};
                  restOfSavedOrders.splice(restIndex, 1);
                }
              }
            });

            if(doUpdate == false)
              return order;
            else if (currentOrder != undefined)
              return currentOrder;
            
        });
        // add rest of saved orders
        if(restOfSavedOrders.length > 0)
        {
          restOfSavedOrders.forEach((item:IOrder, index:number) => {
            orders.push(item);
          });
        }

        setSavedOrderList(orders);
      }
    }

  }, [firstDataLoad, isReadyForApiCall])

  React.useEffect(() => {
    if(savedOrderList != undefined)
    {
        let date = new Date(),
            orderList: IOrder[] = [];

        date.setHours(0);
        date.setMinutes(0);
        date.setSeconds(-1);

        savedOrderList.forEach((order, index) => {
          // filter by delivery date => non orders from past
          if(order.deliveryDate != undefined)
          {
            let deliveryDate: Date = new Date( order.deliveryDate);
            if(deliveryDate >= date && order.status_code != 'X')
                orderList.push(order)
          }
        });

        if(orderList != undefined)
          setPreparedOrderList(orderList);
    }
  }, [isLocalLoaded, savedOrderList])
  /*
  useEffect(() => {
    console.log(preparedOrderList);
  }, [preparedOrderList])
  */
  const handleReload = (e:any) => {

    setSavedOrderList(undefined)
    setIsLocalLoaded(false);
    loadSavedOrderList();
    // load data via api
    getFirstDataLoad();
  }

  const updateOrderList = () => {

  };
 
  const updateSingleOrder = (singleOrder: IOrder, type: string = "") => {  
    if(savedOrderList != undefined)
    {
      const orders: IOrder[]= []
      savedOrderList.forEach((order:IOrder, index) => {
        if(order != undefined && singleOrder != undefined)
        {
          if(singleOrder.primkey == order.primkey && type == "")
            order =  singleOrder;
             
          if (type != "delete" || singleOrder.primkey != order.primkey)
            orders.push(order)
          else if (type == "delete" && singleOrder.primkey == order.primkey)
          {
             singleOrder.status_code = "X";
             orders.push(singleOrder);
          }
            
        }
      });

      setSavedOrderList(orders);
    }
  };

  return (
    <IonPage>
      <Base name={name} title={name} description={"<p>Hier sehen Sie Ihre aktuellen Bestellungen</p>"} 
        extraClassName="orders" topElements={<Reload className="" position='right' handleReload={handleReload} /> } >
        <ItemList extraClass='non-hover' items={preparedOrderList} updateItems={updateOrderList} updateSingleItem={updateSingleOrder} emptyMessage='Es konnten keine aktuellen Bestellungen gefunden werden.' loading={isLoading} />
      </Base>
    </IonPage>
  );
}

export default Orders;