import React, {useState} from 'react';

import {IonGrid, IonRow, IonCol, IonIcon } from '@ionic/react';
import { render } from '@testing-library/react';
import { checkmarkCircleOutline, checkmarkCircleSharp } from 'ionicons/icons';

import { IHandleOrder } from '../../interfaces/order';
import { ITransporter } from '../../interfaces/transporter';
import {array_chunk} from '../../services/hooks/array_chunk'

import useGet from '../../services/hooks/useGet';

const TransporterGrid: React.FC<IHandleOrder> = (props) => { 
    const { data, reDoGet, unAuthorized, isReady: isReadyForApiCall} = useGet("vehicle/type/lkw", false, props.user?.token != undefined ? {authorizationType: "Bearer", token: props.user.token} : undefined);

    React.useEffect(() => {
        if(data != undefined)
        {
            if (data.length != undefined)
            {
                if (data.length > 0)
                    builtTransporterListFromData();
            }   
        }
        if(data == undefined && props.user?.token != undefined)
            reDoGet();
    }, [data, props.user, isReadyForApiCall])

    const [transporterList, setTransporterList] = useState<ITransporter[]>();
    const builtTransporterListFromData = () => {
        let transporterList:ITransporter[] = data.map((transporterData: any, index:number) => {
            let transporter: ITransporter = {
                _id: transporterData.id != undefined ? transporterData.id : transporterData.Id,
                number: transporterData.ganummer,
                name: (transporterData.ganame1 == "Alle" ? "Beliebiges KFZ" : transporterData.ganame1) ,
                additionalName: transporterData.ganame2,
                group: transporterData.gagruppe,
                active: false,
            };

            return transporter;
        })

        setTransporterList(transporterList);
    }


    const [transportersPerLine, setTransportersPerLine] = useState<number>(3);
    const [selectedTransporter, setSelectdTransporter] = useState<ITransporter | undefined>();
    const [theGridItems, setTheGridItems]   = useState<any>();

    const handleTransporterClick = (e: any) => {
        let elem = e.target;

        if(elem.nodeName != 'ION-COL')
            elem = e.target.closest('ion-col')

        let elementId = elem.getAttribute("id"),
            transporterNumber = elementId.replace("transporter-", "")
        
        setActiveTransporterInGrid(transporterNumber)
    }

    const setActiveTransporterInGrid = (transporterNumber: string) => {
        
        if (transporterList != undefined)
        {
            let newList:ITransporter[] = transporterList.map((t) => {
                if(t.number == transporterNumber){
                    t.active = true;
                    setSelectdTransporter(t);
                }
                else
                    t.active = false

                return t;
            })

            setTransporterList(newList);
        }
    }

    /* handle transporter list / grid */
    const buildTransporterGrid = () => {
        if (transporterList != undefined){
            let colSize = 12 / transportersPerLine;
            const rows = array_chunk(transporterList, transportersPerLine);
            setTheGridItems(rows.map((row, rowIndex) => {
                return (
                    <IonRow key={rowIndex} className="theGrid">
                        {
                        row.map((col, colIndex) => {
                            return (
                                <IonCol id={"transporter-" + col.number} className={"single-transporter" + (col.active == true ? " active" : "") } size={String(colSize)} key={rowIndex + "-" + colIndex } onClick={handleTransporterClick}>
                                    <div>
                                    { col.name } 
                                    </div>
                                    {(col.active == true ?<div> <IonIcon size="large"  ios={checkmarkCircleOutline} md={checkmarkCircleSharp} /></div> : "")}
                                </IonCol>
                            )
                        })
                    }
                    </IonRow>)
            }));
        }
    }

    React.useEffect(() => {
        //if(transporterList == undefined)
        //    setTransporterList(transporterListExample)
        
        if (transporterList != undefined){
            if(selectedTransporter == undefined){
                setTimeout(() => {
                    let transporter: ITransporter|undefined = {number: "0", name: "", active: false};
    
                    if(props.order != undefined)
                    {
                        if(props.order.transporter != undefined)
                            transporter = props.order.transporter;
                    }
    
    
                    setSelectdTransporter(transporter);
                    setActiveTransporterInGrid(transporter.number)
                }, 500)
            }

        }
            buildTransporterGrid();
    }, [transporterList])

    React.useEffect(() => {
        if(selectedTransporter != undefined){
             // update order
             if(props.order != undefined )
             {
                let currentOrder: any = {};
                currentOrder.transporter = selectedTransporter;
                props.updateOrder(currentOrder);
             }
        }
    }, [selectedTransporter]);

    return (
       <IonGrid className="transporter-grid">
           <h2>Transportertyp</h2>
           {theGridItems}
       </IonGrid>
    );
  }

export default TransporterGrid;
