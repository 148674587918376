import React, { useEffect, useState, useContext } from "react";
import {useLocation} from 'react-router-dom';

import Ordering from '../../pages/ordering/Ordering';
import Orders from "../../pages/orders/Orders";
import {Login} from '../../pages/account/Login';
import {Logout} from '../../pages/account/Logout';

interface IChangeRoute {
    (route:string): void
}

interface IChangePageType {
    (type: typeof Ordering | typeof Orders | typeof Login | typeof Logout ): void
}

interface IRouteAnalytics {
    hasChanged?: boolean,
    route?: string,
    changeRoute: IChangeRoute,
}

const ListenerAppRouterAnalytic: React.FC<IRouteAnalytics> = (props) => { 
    const location = useLocation();
  
    // Set the screen name for each router path.
    useEffect(() => {
        const path = location.pathname + location.search;
        props.changeRoute(path);
        /*
        switch(path){
            case "/bestellen":
                props.changePageType(Ordering);
                break;
        }
        */
    }, [location]);
  
    return null;
  };

  export default ListenerAppRouterAnalytic;