import React, {Component, MouseEventHandler, useState} from 'react';
import {IErrors} from "../interfaces/account"
import Error from "./Error"
export interface IErrorList {
  errors: IErrors,  
}

const Errors: React.FC<IErrorList> = (props) => { 
    let otherErrors = "";
    
    if (props.errors.otherErrors != undefined)
      otherErrors = props.errors.otherErrors.map((e:any, item:any) => {
        return <Error key={item} error={e} />
    })

    return (
      <div className="error-container">
          <Error error={props.errors.required}/>
          <Error error={props.errors.terms}/>
          <Error error={props.errors.email}/>
          <Error error={props.errors.access}/>
          {/* handle list of errors */}
          {otherErrors}
      </div>  
    )
  }
  
  export default Errors 