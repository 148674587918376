import React, {useContext, useState} from 'react';
import {IonCard, IonCardHeader, IonCardContent, IonGrid, IonRow, IonCol, IonButton} from '@ionic/react';

// interfaces
import { IHandleOrder } from '../../interfaces/order';

// hooks & services 
import  useInternalNavigate  from '../../services/hooks/internalNavigate';

var oldOrderTimerInterval: any;

const Success: React.FC<IHandleOrder> = (props) => { 
    const {navigateInternal} = useInternalNavigate('orderList');

    const [remainingTime, setRemainingTime] = useState<number>();
    const [remainingTimeText, setRemainingTimeText] = useState<string>();

    // handling old saved order => remove when time is over
    const proofOnOldOrder = () => {
        
        if (props.order != undefined)
        {
            // an order can only be half an hour old after finishing
            let plusHalfAnHour:Date = new Date(String(props.order.finishTime));
            plusHalfAnHour.setMinutes(plusHalfAnHour.getMinutes() + 30 );
            let timeUntilFinish = plusHalfAnHour.getTime() /1000 - new Date().getTime() /1000;
            setRemainingTime(timeUntilFinish); 
            setRemainingTimeText("Zeit bis Neustart: " + parseInt(String(remainingTime)) + " Sekunden")
            if(props.order.isFinished == true && timeUntilFinish <= 0) 
            {
                props.updateOrder({...props.order, doLocalRemovement: true})
            }
        }
    }
    React.useEffect(() => {
        clearInterval(oldOrderTimerInterval);
        oldOrderTimerInterval = setInterval(proofOnOldOrder, 1000);

        return () => {
            clearInterval(oldOrderTimerInterval);
        }
    }, [])

    // handle clicks of buttons
    const handleButtonClick = (type: "ordering" | "orderList" ) =>{
        if( props.order != undefined)
            props.updateOrder({...props.order, doLocalRemovement: true})

        if(type == 'orderList')
            navigateInternal();
    }

    return (
        <div>
            <IonGrid className="order-success-container">
                {props.user?.roles?.includes("ROLE_SALE") == false ? 
                (<IonRow className="">
                    <IonCol size="6" offset="0"><IonButton className="ordering-success" onClick={() => handleButtonClick('orderList')}>Bestellungen</IonButton></IonCol>
                    <IonCol size="6"  offset="0"><IonButton className="ordering-success" onClick={() => handleButtonClick('ordering')}>Neue Bestellung</IonButton></IonCol>
                </IonRow>) 
                : 
                (<IonRow className="">
                    <IonCol size="6"  offset="3"><IonButton className="ordering-success" onClick={() => handleButtonClick('ordering')}>Neue Bestellung</IonButton></IonCol>
                </IonRow>)}
                {/*
                <IonRow props.order?.finishTime != undefined ? String(props.order?.finishTime) : ""
                    <IonCol size="12">
                        
                    </IonCol>
                </IonRow>
                */}
                {props.user?.roles?.includes("ROLE_SALE") == false ? 
                (<IonRow className="" style={{marginTop: "1.5em"}}>
                    <IonCol size="11" offset="1" className="text-center">Sie <strong>erhalten</strong> in Kürze eine <strong>Nachricht</strong> über Ihren Auftrag.</IonCol>
                </IonRow>) :
                (<IonRow className="" style={{marginTop: "1.5em"}}>
                    <IonCol size="11" offset="1" className="text-center">Der Auftrag wurde erfolgreich verschickt. </IonCol>
                </IonRow>) }            
            </IonGrid>
        {remainingTime != undefined 
            ?
            (remainingTime > 0 && remainingTime < 60 
                ?  (<IonCard>
                        <IonCardHeader>Neustart</IonCardHeader>
                        <IonCardContent>{"Starte neue Bestellung in " + parseInt(String(remainingTime)) + " Sekunden"}</IonCardContent>
                    </IonCard>) 
                : "")
            : ""}
        </div>
        
    )
}

export default Success;