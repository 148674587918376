import React, { useEffect, useState } from "react";
import {settings} from "../../settings/settings";

function usePost(url: any, params: any = {}, doPostAction: boolean = true, token: string = "") {
  const [isMounted, setIsMounted] = useState<boolean>(true)

  const [doPost, setDoPost] = useState<boolean>(doPostAction)
  const [data, setData] = useState<any[] | any>();
  const [loading, setLoading] = useState<boolean>(false);
  const [isLoaded, setIsLoaded] = useState<boolean>(false)
  const [error, setError] = useState<string|boolean|null>(null);

  const [unAuthorized, setUnAuthorized] = useState<boolean>();
  
  const [apiToken, setApiToken] = useState<string>();
  const [postHeaders, setPostHeaders] = useState<any | Headers>({});

  var formData = new FormData();

  for(var key in params)
    formData.append(key, params[key]);

  useEffect(()=>{
    setIsMounted(true)
    return () => {
      setIsMounted(false);
    }
  }, [])
  
  useEffect(() => {
    if(apiToken != "" && apiToken != undefined)
    {
      repost();
    }
     
  }, [apiToken]);

  const buildHeaders = () : Headers => {
    let headers  = null;
    if(token == "")
    {
      headers = new Headers({
        'Content-Type': 'application/json',
        //'data': JSON.stringify(params),
      });
    }
    else
    {
      headers = new Headers({
        'Content-Type': 'application/json',
        //'data': JSON.stringify(params),
        'Authorization': 'Bearer ' + token, 
      });
    }

    return headers;
  }

  useEffect(() => {
    let doEncode:boolean = true
    if(params.ignoreAPICall != true && isMounted == true && doPost == true){
      setLoading(true); // set loading to true
      fetch(settings.apiConfig.baseURL + url, {
        method: "POST",
        credentials: 'include',
        headers: buildHeaders(),
        mode: 'cors',
        cache: 'default',
        body: JSON.stringify(params, (key, value) =>
          typeof value === "string" && doEncode == true ? encodeURI(value) : value
        ),
      })
      // We get the API response and receive data in JSON format
      .then((response) => {
        if(isMounted == true && response.status == 401)
          setUnAuthorized(true);
        else if(isMounted == true && response.status != 401)
          setUnAuthorized(true);

        return response.json();
      })
      .then((data) => {
        if(isMounted == true)
        {
          setIsLoaded(true);
          setData(data);
        } 
      })
      .catch ((error) => {
        console.error(error);
        setError(error);
      })
      .finally(() => {
        if(isMounted == true)
          setLoading(false);
      });
    }
  }, [url, doPost]);

  // Function to call when button is clicked
  const repost = (doEncode: boolean = true) => {
    if(params.ignoreAPICall != true && isMounted == true){
      setLoading(true); // set loading to true

      fetch(settings.apiConfig.baseURL + url, {
        method: "POST",
        credentials: 'include',
        headers: buildHeaders(),
        mode: 'cors',
        cache: 'default',
        body: JSON.stringify(params, (key, value) =>
          typeof value === "string" && doEncode == true ? encodeURI(value) : value
        ),
      })
      // We get the API response and receive data in JSON format
      .then((response) => {
        if(isMounted == true && response.status == 401)
          setUnAuthorized(true);
        else if(isMounted == true && response.status != 401)
          setUnAuthorized(true);
        
        return response.json();
      })
      .then((data) => {
        if(isMounted == true){
          setData(data);
          setIsLoaded(true);
        }
      })
      .catch ((error) => {
        console.error(error);
        setError(error);
      })
      .finally(() => {
        if(isMounted == true)
          setLoading(false);
      });
    }
  };

  return { data, loading, isLoaded, setLoading, error, setError, repost, setData, setDoPost, unAuthorized };
}

export default usePost;
