import React, { useState } from 'react';
import { IonDatetime,IonGrid, IonRow, IonCol, IonCheckbox, IonLabel, IonItem} from '@ionic/react';
import { IConstruction, IHandleOrder, IMaterial} from '../../interfaces/order';

import { dateString, timeString } from '../../services/hooks/dateString';
import { today } from 'ionicons/icons';

const DateAndTimePicker: React.FC<IHandleOrder> = (props) => { 
    // proof on mounted state -> is component available or removed from DOM
    const [isMounted, setIsMounted] = useState<boolean>(true)
    React.useEffect(() => {
        return () => {
          setIsMounted(false);
        };
    }, []);

    const [dateToday, setDateToday] = useState<Date>(new Date())
    const [maxDate, setMaxDate] = useState<Date>(dateToday);
    const [minDate, setMinDate] = useState<Date>(new Date(dateString(dateToday) + " 07:00:00"))
    const [isOrderDeliveryDateUpdated, setIsOrderDeliveryDateUpdated] = useState<boolean>(false);
    const [selectedDate, setSelectedDate] = useState<Date>();
    const [deliveryTimeIsUnimportant, setDeliveryTimeIsUnimportant] = useState<boolean>(props.order?.deliveryTimeIsUnimportant != undefined ? props.order.deliveryTimeIsUnimportant : true)

    /* prepare dates - */
    React.useEffect(() => {
        if(props.order != undefined)
        {
            if ((maxDate == undefined || maxDate == dateToday) && isOrderDeliveryDateUpdated == false){
                setIsOrderDeliveryDateUpdated(true);
                setTimeout(() => {
                    /* setup already existing data*/
                    let isUpdated:boolean = false;
                    if(props.order != undefined)
                    {
                        if(props.order.deliveryDate != null && props.order.deliveryDate != undefined)
                        {
                            if(typeof props.order.deliveryDate == 'string'){
                                let deliveryDate = new Date(props.order.deliveryDate);
                                // recalculate for time picker 
                                deliveryDate.setHours(deliveryDate.getHours() - 1)
                                props.order.deliveryDate = deliveryDate;
                            }
                            if(isMounted == true)
                                setDeliveryTimeIsUnimportant(props.order.deliveryTimeIsUnimportant != undefined ? props.order.deliveryTimeIsUnimportant : true);

                            let theDate:Date = props.order.deliveryDate;
                            theDate.setHours(theDate.getHours() -1 )

                            if(isMounted == true)
                            {
                                if(deliveryTimeIsUnimportant == true)
                                {
                                    theDate.setHours(0, 0, 0, 0);
                                }
                                   

                                setSelectedDate(theDate);
                            }
                            
                            isUpdated = true;
                        }
                    }
                    
                    if(isUpdated == false)
                    {
                        const dateForSelect = new Date(dateToday);
                        dateForSelect.setDate(dateToday.getDate() + 1)
                        
                        if(deliveryTimeIsUnimportant == true)
                            dateForSelect.setHours(0, 0, 0, 0);
                        else
                            dateForSelect.setHours(minDate.getHours(), minDate.getMinutes(), 0, 0)
                        if(isMounted == true)
                        {
                            setDeliveryTimeIsUnimportant(true)
                            setSelectedDate(dateForSelect)
                        }
                    }
                    
                    const dateMax = new Date(dateToday);
                    dateMax.setMonth(dateMax.getMonth() + 2); 
                    
                    if(isMounted == true)
                        setMaxDate(dateMax)
                }, 1000)
            }
        }
    }, [maxDate, props.order])

    /* select of time and date */
    const handleDateSelect = (e :any) => {
        let selectedDateString:string = dateString(e) + " " + timeString(selectedDate);
        handleTimeAndDateSelect(selectedDateString);  
    }

    const handleTimeSelect = (e :any) => {
        let selectedDateString:string = dateString(selectedDate) + " " + timeString(e);
        handleTimeAndDateSelect(selectedDateString);   
    }

    const handleTimeAndDateSelect = (dateAndTime:string) => {
        let newSelectedDate:Date = new Date(dateAndTime);
        
        // if selected date is today => proof time is not in past
        let forceTodayDateTime: boolean = false,
            additionalMinutes:number = 30,
            now:Date = new Date(),
            plusAdditionaMinuteDate:Date = new Date();
        
        plusAdditionaMinuteDate.setMinutes(plusAdditionaMinuteDate.getMinutes() + additionalMinutes)
        
        if(newSelectedDate.getDate() == now.getDate() && newSelectedDate.getMonth() == now.getMonth() && newSelectedDate.getFullYear() == now.getFullYear() && deliveryTimeIsUnimportant == false)
        {
            if (newSelectedDate.getHours() < plusAdditionaMinuteDate.getHours() 
                || (newSelectedDate.getHours() == plusAdditionaMinuteDate.getHours() && newSelectedDate.getMinutes() < plusAdditionaMinuteDate.getMinutes()))
            {
                now.setMinutes(now.getMinutes() + additionalMinutes);
                if(isMounted == true)
                    setSelectedDate(now);
            }
            else
                setSelectedDate(new Date(dateAndTime));
        }
        else if(isMounted == true)
            setSelectedDate(new Date(dateAndTime));
    }

    React.useEffect(() => { 
        if (selectedDate != undefined && props.order != undefined && isMounted == true)
        {
            let currentOrder: any = {};
            let theDate: Date = new Date(selectedDate);
            
            // Add one hour to selectedDate => is always one hour smaller
            theDate.setHours(theDate.getHours() + 2);
            
            currentOrder.orderDate                  = theDate;
            currentOrder.deliveryDate               = theDate;
            currentOrder.deliveryTimeIsUnimportant  = deliveryTimeIsUnimportant;
            props.updateOrder(currentOrder)
        }
    }, [selectedDate])

    /* handle unimportant delivery time status */
    React.useEffect(() => {
        // if unimportant => the delivery time is set to 00:00
        if(deliveryTimeIsUnimportant == true && selectedDate != undefined && isMounted == true)
        {
            let unimportantTimeDate = new Date(selectedDate)
            unimportantTimeDate.setHours(0, 0, 0)
            handleTimeSelect(unimportantTimeDate);
        }
        else if (deliveryTimeIsUnimportant == false && selectedDate != undefined){
            let importantTimeDate = new Date(selectedDate)
            importantTimeDate.setHours(minDate.getHours(), minDate.getMinutes(), 0)
            handleTimeSelect(importantTimeDate)
        }

    }, [deliveryTimeIsUnimportant]);


    return (
        <IonGrid className="date-and-time-container">
            <IonRow>
                <IonCol size="4.75">
                    <h2>Lieferung am</h2>
                    <IonDatetime 
                        title="Lieferdatum"
                        pickerOptions={{
                            buttons: [{ text: 'OK', handler: handleDateSelect}]
                        }}
                        placeholder="Lieferdatum" displayFormat="DD.MM.YYYY" min={dateString(dateToday)} max={dateString(maxDate)}
                        value={selectedDate != undefined ? String(selectedDate) :""}>
                    </IonDatetime>
                </IonCol>
                <IonCol size="7.15">
                    <h2>Uhrzeit</h2>
                {deliveryTimeIsUnimportant == false ?
                    <IonDatetime pickerOptions={{
                        buttons: [{ text: 'OK', handler: handleTimeSelect}]
                    }}
                        placeholder="Zeitpunkt" displayFormat="HH:mm" min={timeString(minDate)} max="17:00"
                        value={selectedDate != undefined ? String(selectedDate) :""} onIonChange={(e) => {return false;}}>
                    </IonDatetime>
                : ""}
                    <IonItem className="none-border check-time">
                        <IonLabel>Beliebige Uhrzeit</IonLabel>
                        <IonCheckbox checked={deliveryTimeIsUnimportant} slot="start" onIonChange={(e) => setDeliveryTimeIsUnimportant(e.detail.checked)} />
                    </IonItem>
                </IonCol>

            </IonRow>
        </IonGrid>
    )
}

export default DateAndTimePicker;