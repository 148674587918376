import React, { useEffect, useState } from "react";
import useFetch from "../hooks/useFetch";

async function generateRandomStringPassword(length : number) :Promise<string> {
    var result : string  = '';
    var characters : string = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz@^*()";
    var charactersLength = characters.length;
    for ( var i = 0; i < length; i++ ) 
    result += characters.charAt(Math.floor(Math.random() *  charactersLength));
    
    return result;
}

function useGenerateSecurePassword() {
    const [securePassword, setSecurePassword] = useState<string>("");
    const [ignoreAPICall, setIgnoreAPICall] = useState<boolean>(true)
    const { data, loading, refetch } = useFetch("sec-password/check?data-only=1", {ignoreAPICall: ignoreAPICall, spwd:securePassword, data: [ "Ein einfacher Test", "Ohne viel zu machen", "Kann keiner was", "Wir bauen Hauser", "und wir bauen Strassen"] });
    
    const [validationData, setValidationData] = useState<any>({})
    const [isLoading, setLoad] = useState<boolean>(true)

    useEffect((): any => {
        if( isLoading == false && securePassword != "") 
        {
            setLoad(true)
            refetch()
            setValidationData(data);
        }    
    }, [])

    const generatePassword = async () : Promise<object> => {
        let p = await generateRandomStringPassword(32);
        
        return new Promise(resolve => {
            setSecurePassword(p)
            setLoad(false);

            return "";
        });
    }   

    return { securePassword, generatePassword, validationData }// loading, error};
}

export default useGenerateSecurePassword;
