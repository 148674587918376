import { type } from "os";
import { IMaterial } from "../../interfaces/order";

const amountConverter = (value: number | string |undefined) : string => {
    let result = "";

    if(typeof value == 'number')
        result = new Intl.NumberFormat('de-DE').format(value);
    else if (typeof value == 'string')
        result = new Intl.NumberFormat('de-DE').format(parseFloat(value));
    
    return result;
}


const materialAmountConverter = (material: IMaterial | undefined, type: string | undefined ) : string => {
    let result = "";
    if (material != undefined && type != undefined)
    {
        if(type == "Menge" )
            result = (material != undefined ? material.unit : "");
        else if (type == "Laufend" )
            result = "Fahrzeuge";
        else
            result = (type != undefined ? type : "");
    }

    return result;
}

export {amountConverter, materialAmountConverter}