import React from 'react';

import { IonGrid, IonRow, IonCol, IonIcon, IonImg, IonProgressBar  } from '@ionic/react';
import { refresh, refreshSharp, refreshOutline } from 'ionicons/icons';

import { render } from '@testing-library/react';
import { useParams } from 'react-router';

// interfaces
import {IOrderSerarch} from '../../interfaces/search'
import { IDelivery, IDeliveryMode } from '../../interfaces/delivery';

// hooks & services
import {dateForUX, deliveryDateString, timeString} from '../../services/hooks/dateString';
import { time } from 'console';

interface IDeliveryDetail {
    delivery: IDelivery
    mode: IDeliveryMode | undefined,
}

const DeliveryDetail: React.FC<IDeliveryDetail> = (props) => { 
    return (
        <IonGrid className="delivery-detail">
            <IonRow>
                <IonCol className="content-container" size='6' >
                    <IonGrid>
                    {props.mode == 'daily' ?
                        <IonRow className="delivery-date">{dateForUX(props.delivery.date)}</IonRow>
                    : null}
                        <IonRow className="material">{props.delivery.material.name}</IonRow>
                        <IonRow className="order-date-between">
                            <IonGrid>
                                <IonRow>
                                    <IonCol size='3' offset='2'>{dateForUX(props.delivery.order_details.order_start, false)} </IonCol>
                                    <IonCol size='1'>-</IonCol>
                                    <IonCol size='3' offset='1'>{dateForUX(props.delivery.order_details.order_end, false)}</IonCol>
                                </IonRow>
                            </IonGrid>
                        </IonRow>
                        <IonRow className="order-amount">
                            <IonGrid>
                                <IonRow>
                                    <IonCol size='5'  sizeLg='5' sizeSm='5' sizeMd='4' sizeXs='5'>Menge:</IonCol>
                                    <IonCol size='5'  sizeLg='5' sizeSm='5' sizeMd='5' sizeXs='6' offset='1' offsetLg='1' offsetMd='2' offsetXs='1'>{props.delivery.amountInfo.completeAmountOfMaterialWithUnit}</IonCol>
                                </IonRow>
                            </IonGrid>
                        </IonRow>
                        <IonRow className="order-time">
                            <IonGrid>
                                <IonRow>
                                    <IonCol size='5' sizeXs='5.8' >Bestellt am:</IonCol>
                                    <IonCol size='5' sizeXs="5.6" offset='1' offsetXs='.25' >{props.delivery.order_details.orderDate != undefined ? dateForUX(props.delivery.order_details.orderDate ) : ""}</IonCol>
                                </IonRow>
                            </IonGrid>
                        </IonRow>
                        <IonRow className="ordered-by">
                            <IonGrid>
                                <IonRow>
                                    <IonCol size='5' sizeXs='5.8'>Bestellt von:</IonCol>
                                    <IonCol size='5' sizeXs="5.6" offset='1' offsetXs='.25'>{props.delivery.order_details.contactPerson.fullName}</IonCol>
                                </IonRow>
                            </IonGrid>
                        </IonRow>
                        <IonRow className="progress">
                            <IonProgressBar value={props.delivery.amountInfo.completeProgressValue}/>
                        </IonRow>
                        <IonRow className="progress-value">
                           <IonCol size='3' sizeXs='5' offset='9' offsetXs='8'>{props.delivery.amountInfo.completeProgressInPercentage}</IonCol>
                        </IonRow>
                    </IonGrid>
                </IonCol>
                <IonCol  className="content-container" size='6'>
                    <IonGrid>
                    {props.delivery.details.map((detail, index) => {
                        return (
                            <IonRow className="single-delivery-item" key={index}>
                                <IonGrid>
                                {props.mode == 'total' ?
                                        <IonRow className=""><b>am {dateForUX(new Date(detail.scalingTime),false)}</b></IonRow>
                                : null}

                                    <IonRow>
                                        <IonCol size='3' offset='3' ><IonImg className="libra-img" src="images/libra_icon_512px.png" /> </IonCol>
                                        <IonCol size='3' sizeXs='5' offset='3' offsetXs='1'><b>{timeString(new Date(detail.scalingTime), false)} Uhr</b></IonCol>
                                    </IonRow>
                                    <IonRow>
                                        <IonCol size='5' sizeXs='6' offset='1' offsetXs='0'>Lieferschein:</IonCol>
                                        <IonCol size='3' sizeXs='5' offset='3' offsetXs='1'>{detail.delivery_note_nr}</IonCol>
                                    </IonRow>
                                    <IonRow>
                                        <IonCol size='5' offset='1'>Menge:</IonCol>
                                        <IonCol size='3' sizeXs='5' offset='3' offsetXs='1'><b>{detail.amount} {detail.unit}</b></IonCol>
                                    </IonRow>
                                </IonGrid>
                            </IonRow>
                        );
                    })}
                    </IonGrid>
                </IonCol>
            </IonRow>
            <IonRow className="complete-scaled-amount" >
                <IonCol className="content-container small" size='6'offset='6' >
                    <IonGrid>
                        <IonRow>
                            <IonGrid>
                                <IonRow>
                                    <IonCol size='5' offset='1'>Gesamt:</IonCol>
                                    <IonCol className="sum" size='3' sizeXs='4' offset='3' offsetXs='2'><b>{props.delivery.complete_scaled_amount} {props.delivery.material.unit}</b></IonCol>
                                </IonRow>
                            </IonGrid>
                        </IonRow>
                    </IonGrid>
                </IonCol>
            </IonRow>
        </IonGrid>
    );
}

export default DeliveryDetail;
