import React from 'react';

import { IonButtons, IonContent, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar } from '@ionic/react';
import { useParams } from 'react-router';
import { render } from '@testing-library/react';
import parse from 'html-react-parser';

interface IntroductionProps {
    title: string | undefined,
    description?: string | any
}
interface IntroductionState {}

const IntroductionContainer: React.FC<IntroductionProps> = (props) => { 
    const container = props.title == '' && (props.description == '' || props.description != undefined)
        ? null 
        :  (<div className="introduction-container">
                <h1 className="ion-text-center">{props.title}</h1>
                <div className="introduction ion-text-center">{(props.description != undefined && typeof props.description == 'string' ? parse(props.description) : props.description)}</div>
            </div>);

    return container;
};

export default IntroductionContainer;
