import React from 'react';

import { IonSpinner, IonContent, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar } from '@ionic/react';
import { useParams } from 'react-router';
import { render } from '@testing-library/react';

import {ISearch} from '../../interfaces/search'

interface ILoading {
    size: "small" | "medium" | "large" | "full",
}

const Loading: React.FC<ILoading> = (props) => {    
    return (
        <div className={"loading-container " + props.size}>
            <IonSpinner name="crescent" /> 
        </div>
    );
};

Loading.defaultProps = {
    size: "small"
}

export default Loading;
