import React, {useState} from 'react';

import { IonButton, IonButtons, IonHeader, IonIcon, IonMenuButton, IonGrid, IonRow, IonCol, IonTitle, IonText, IonToolbar, IonImg } from '@ionic/react';
import { useLocation } from 'react-router-dom';
import { arrowBack, arrowBackOutline, arrowBackSharp } from 'ionicons/icons';

import useLocalUserDatabase from '../../services/account/useLocalUserDatabase';
import {settings} from '../../settings/settings';

import {IGoBackFunc} from './Base'

interface HeaderProps{
  name: string,
  hasBackButton?: boolean,
  goBack?: IGoBackFunc
}

const defaultProps: HeaderProps = {
  name: "",
  hasBackButton: false,
}

const Header: React.FC<HeaderProps> = (props) => {
  const location = useLocation();
  const {localUser} = useLocalUserDatabase(undefined)
  const[headerUserInfo, setHeaderInfo] = useState<string | object>();

  React.useEffect(() => {
    if(localUser != undefined)
    {
      if(localUser.isLoggedIn == true)
      {
        if(localUser.roles != undefined && typeof localUser.roles == 'object')
        {
            if(localUser.roles.includes("ROLE_NORMAL"))
              setHeaderInfo(localUser.company?.name);
            else
              setHeaderInfo(<span>{localUser.person?.firstname} {localUser.person?.surname}<br/>{localUser.roles.includes('ROLE_SALE') ? "Vertrieb" : ""}</span>);
        }
      }
    }
  }, [localUser]);

  return (
    <IonHeader className="header ">
        <IonToolbar>
            <IonButtons slot="start">
                {props.hasBackButton == false ? <IonMenuButton />  : <IonButton className="back-button" onClick={props.goBack}><IonIcon  slot="start" ios={arrowBackOutline} md={arrowBackSharp}/></IonButton> }
            </IonButtons>
            {/*
            <IonTitle >{props.name} </IonTitle>
            <IonTitle>Bla</IonTitle>
            */}
            <IonGrid>
              <IonRow>
                <IonCol size="4" sizeLg='7' sizeSm='5.5' sizeMd='5' sizeXs='4' ><IonTitle>{props.name} </IonTitle></IonCol>
                <IonCol size="4" sizeLg='3' sizeSm='4' sizeMd='5' sizeXs='4.5' offset='1' offsetSm='.2' offsetXs='1.20'><IonText className="company-name">{headerUserInfo}</IonText>  </IonCol>
                <IonCol className="header-logo" size="2" sizeLg='1' sizeSm='1.2' sizeMd='1' sizeXs='1.75' offset='0' offsetXs='0.5'><IonImg src="images/header_logo.png"  /></IonCol>
              </IonRow>
            </IonGrid>
        </IonToolbar>
    </IonHeader>
  );
};

Header.defaultProps = defaultProps;

export default Header;
