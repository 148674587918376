import React, {useState} from 'react';

import { IonInfiniteScroll, IonInfiniteScrollContent,} from '@ionic/react';

import { settings } from '../../settings/settings';
// interfaces
import { IConstruction, IHandleOrder } from '../../interfaces/order';
import { ICustomer } from '../../interfaces/customer';

// components
import ItemList from '../../components/orders/ItemList';
import ItemListBlocks from '../../components/orders/ItemListBlocks';

// hooks
import usePost from '../../services/hooks/usePost';
import useGet from '../../services/hooks/useGet';

import useLocaleCustomerDatabase from '../../services/orders/useLocalCustomersDatabase';

interface IHandleSearchDisableStatus {
    (value:boolean) :void
}
interface IHandleOrderCustomerPage extends IHandleOrder{
    hasSearchResult: boolean,
    handleSerachDisableStatus: IHandleSearchDisableStatus,
}

const Customers: React.FC<IHandleOrderCustomerPage> = (props) => { 
    /// for fetching data func
    const [lastTimeOfDataFetch, setLastTimeOfDataFetch] = useState<Date>();
    const [doFetchCalls, setDoFetchCalls] = useState<boolean>(false);

    const [fetchApiLimitSteps, setFetchApiLimitSteps] = useState<number>(1000);
    const [limit, setLimit] = useState<number>(0);
    const [apiLoadingIsFinished,setApiLoadingIsFinished] = useState<boolean>(false);

    // for scroll func
    const [customers, setCustomers] = useState<ICustomer[]>();
    const [infiniteScrollData, setInfiniteScrollData] = useState<ICustomer[]>();
    const [amountPerStep, setAmountPerStep] = useState<number>(100);
    const [currentLength, setCurrentLength] = useState<number>(100);
    const [scrollingDisabled, setScrollingDisabled] = useState<boolean>(false);

    const { data, loading, isLoaded, reDoGet, isEmpty, setIsEmpty } = useGet("customers" + (settings.producer_nr != undefined ? "/pnr-" + settings.producer_nr : "") + (fetchApiLimitSteps > 0 ? "?limit=" + limit + "&steps=" + fetchApiLimitSteps: ""), false);
    const {savedCustomers, setSavedCustomers, saveCustomers, updateData, saveUpdateData} = useLocaleCustomerDatabase();

    React.useEffect(() => {
        if(props.doReload == true){
            setCustomers(undefined);
            setLimit(0)
            saveUpdateData(undefined);
            setIsEmpty(false);
            setTimeout(() => saveUpdateData([]), 500);
        }
            
    },[props.doReload])
    
    React.useEffect(() => {
        if (typeof props.updateSearchItems == 'function' && customers != undefined)
        {
           props.updateSearchItems(savedCustomers, props.stageNumber);
        }
    }, [customers])

    React.useEffect(() => {
        if(props.hasSearchResult ==true)
        {
            setScrollingDisabled(true)
            let filteredCustomers: ICustomer[] = [];
            savedCustomers.forEach((item, index) => {
                if(item.isVisible == true)
                    filteredCustomers.push(item)
            });

            setCustomers(filteredCustomers);
        }
        else
        {
            setScrollingDisabled(false)
            setCustomers(infiniteScrollData)
        }
    },[props.hasSearchResult, savedCustomers]);


    React.useEffect(() => {
        if (updateData != undefined)
        {
            if(updateData.savingTime != undefined)
            {
                let later = new Date(updateData.savingTime);
                
                later.setMinutes(updateData.savingTime.getMinutes() + 30);
                
                if(later > new Date())
                    setDoFetchCalls(false);
                else
                    setDoFetchCalls(true);
            }
            else
            {
                setDoFetchCalls(true);
            }
        }

        if(props.user?.token != undefined  && doFetchCalls && updateData != undefined && isEmpty == false) 
        {
            reDoGet(props.user.token);
        }
            
    },[limit, updateData, doFetchCalls]);

    React.useEffect(() => {
        props.handleSerachDisableStatus(doFetchCalls)
    },[doFetchCalls])

    React.useEffect(() => {
        if(isEmpty == true)
        {
            setLastTimeOfDataFetch(new Date());
            saveUpdateData({"savingTime": new Date});
            setDoFetchCalls(false)
        }
    }, [isEmpty]);

    React.useEffect(() => {
        if(savedCustomers != undefined)
        {
            if(fetchApiLimitSteps == 0)
                setCustomers(savedCustomers);
            else 
                setCustomersByLimit()
        }
    }, [savedCustomers])

    const setCustomersByLimit = () => {
        if(currentLength != undefined)
        {   
            let limitedCustomers:ICustomer[] = [];
            savedCustomers.forEach((c:ICustomer, index: number) => {
                if(currentLength > index)
                    limitedCustomers.push(c);
               
            });
            setCustomers(limitedCustomers);
            setInfiniteScrollData(limitedCustomers);
        }
    }

    const builtCustomersFromData = () => {
        let customersList:ICustomer[] = data.map((customerData: any, index:number) => {
            let c: ICustomer = {
                id: customerData.id,
                tbkuid: customerData.id,
                producer_nr: customerData.producer_nr,
                customer_nr: customerData.customer_nr,
                customername: customerData.customername,
                additional: customerData.additional,
                city: customerData.city,
                isVisible: true,
                showItem: true,
            }
            return c;
        })
        if(limit == 0)
            setSavedCustomers([...customersList]);
        else 
            setSavedCustomers([...savedCustomers, ...customersList]);
        setCustomers(customersList);
        setLimit(limit + fetchApiLimitSteps);
    }

    React.useEffect(() => {
        if(data != undefined)
        {
            if (data.length != undefined)
            {
                if (data.length > 0)
                    builtCustomersFromData();
                else
                    setApiLoadingIsFinished(true);
            }   
        }

    }, [data])
     
    React.useEffect(() => {
        if(apiLoadingIsFinished == true)
            saveCustomers(savedCustomers);
    }, [apiLoadingIsFinished]);
    
    const handleClick = (event: any) =>{
        let elem = event.target,
            selectedIndex,
            currentOrder: any = {};

        if(elem.nodeName != 'ION-ITEM')
            elem = event.target.closest('ion-item')

        selectedIndex = elem.getAttribute('data-list-index')
        
        if(currentOrder == undefined)
            currentOrder = {isUpdated: false, isDeleted:false}
        
        if(customers != undefined)
        {
            currentOrder.customer = customers[selectedIndex];
            props.updateOrder(currentOrder, "forwards");
        }
    }

    const searchNext = (e:any) => {
        setCurrentLength(currentLength + amountPerStep);
        (e.target as HTMLIonInfiniteScrollElement).complete();
    }

    React.useEffect(() => {
        setCustomersByLimit();

    }, [currentLength]);

    return (
        <div>
            <ItemList emptyMessage="Keine Kunden vorhanden." items={customers != undefined ? customers : []} onClick={handleClick} loading={loading == true && savedCustomers.length == 0 ? true : false}/>
            <IonInfiniteScroll 
                threshold="100px" 
                disabled={scrollingDisabled}
                onIonInfinite={(e: CustomEvent<void>) => searchNext(e)}>
            <IonInfiniteScrollContent
                loadingText="Lade...">
            </IonInfiniteScrollContent>
            </IonInfiniteScroll>
        </div>
    )
}

export default Customers;