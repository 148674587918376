import React from 'react';

import {IonItem, IonList, IonSpinner, IonInfiniteScroll, IonInfiniteScrollContent, } from '@ionic/react';
import { render } from '@testing-library/react';
import { useParams } from 'react-router';

import { IConstruction, IMaterial, IOrder, instanceOfConstruction, instanceOfMaterial, instanceOfOrder } from '../../interfaces/order';
import { ICustomer, instanceOfCustomer } from '../../interfaces/customer';
import OrderDetail from './OrderDetail'
import { IDelivery, IDeliveryMode, instanceOfDelivery } from '../../interfaces/delivery';
import DeliveryDetail from '../delivery/DeliveryDetail';

interface IListClick {
    (event: any): void
}

interface IUpdateItems{
    (items:IConstruction[] | ICustomer[] | IMaterial[] | IOrder[] |string[] | number[] | any[]) :void
}
interface IUpdateSingleItem{
    (item:IConstruction | ICustomer | IMaterial | IOrder |string | number | any, type: string | undefined) :void
}
export interface IListItems {
    emptyMessage?: string,
    extraClass?: string,
    items: IConstruction[] | ICustomer[] | IDelivery[] | IMaterial[] | IOrder[] |string[] | number[],
    loading?: boolean,
    onClick?: IListClick,
    updateItems?: IUpdateItems
    updateSingleItem?: IUpdateSingleItem,
    mode?: IDeliveryMode,
    infinitScrollActive?:boolean,
}

const ItemList: React.FC<IListItems> = (props) => { 
    const list = props.items.map((item : IConstruction | ICustomer | IDelivery | IMaterial | IOrder | string | number, index) => {
        if(typeof item == 'string' || typeof item == 'number') 
            return <IonItem className="none-border" key={index}>{item}</IonItem>;

        // Construction List
        if(instanceOfConstruction(item)){
            return (
                <IonItem style={{display: (item.isVisible ? 'block' : 'none')}} className="none-border" key={index} onClick={props.onClick} data-list-index={index}>
                    <p>{item.address} {item.additionalAddress}<br/>
                    <span className="street small">{item.street}</span><br/>
                    <span className="zip small">{item.zip}</span> <span className="city small">{item.city}</span></p>
                </IonItem>);
        }
        
        // Customer List
        if(instanceOfCustomer(item))
        {
            return (
            <IonItem style={{display: (item.isVisible ? 'block' : 'none')}} className="none-border" key={index} onClick={props.onClick} data-list-index={index}>
                <p>{item.customername}<br/>
                    {item.additional != undefined  && item.additional != "" ? <span> <span className="small">{item.additional}</span> <br/></span>: ""}
                    <strong>{item.zip != undefined ? <span className="zip small">{item.zip}</span> : ""} {item.city != undefined ? <span className="city small">{item.city}</span> : ""} </strong> <br/>
                    <span className="small">Kundennummer: {item.customer_nr}</span>
                
                </p>
            </IonItem>);
        }

        // Delivery 
        if(instanceOfDelivery(item))
        {
            return (
                <IonItem className="none-border delivery" key={index} onClick={props.onClick} data-list-index={index}>
                    <DeliveryDetail delivery={item} mode={props.mode}/>
                </IonItem>);
        }

        // Material List
        if (instanceOfMaterial(item)){
            return (
                <IonItem style={{display: (item.isVisible ? 'block' : 'none')}} className="none-border" key={index} onClick={props.onClick} data-list-index={index}>
                    <p>{item.name}</p>
                </IonItem>);
        }

        // Order List
        if (instanceOfOrder(item)){
            return (
                <IonItem  className="none-border order" key={index} onClick={props.onClick} data-list-index={index}>
                    <OrderDetail order={item} handleOrderChange={props.updateSingleItem != undefined ? props.updateSingleItem : () => {console.error("No prop updateSingleItem found.")}}/>
                </IonItem>);
        }
    });
    
    return (
       <IonList slot="end" className={"item-list " + (props.extraClass != undefined ? props.extraClass : "") + (props.items.length == 0 ? " empty" : "")  }>
           {props.loading == true ? <IonSpinner name="circular" /> : ""}
           {props.items.length <= 0  && props.loading != true ? <p className="empty-message">{props.emptyMessage != undefined ? props.emptyMessage : "Keine Einträge gefunden."}</p> : ""}
           {list}
       </IonList>
    );
}

export default ItemList;
