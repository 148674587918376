import React, {useContext, useState} from 'react';
import { IonButtons, IonButton, IonRippleEffect, IonImg, IonPage, IonGrid, IonRow, IonCol, NavContext } from '@ionic/react';

// interfaces
import { IWelcomeContainer } from '../pages/Welcome';
// hooks & services
import  useInternalNavigate  from '../services/hooks/internalNavigate';

const WelcomeContainer: React.FC<IWelcomeContainer> = (props) => { 
    const {navigateInternal: navigateToOrderList} = useInternalNavigate("orderList");
    const {navigateInternal: navigateToOrdering} = useInternalNavigate("ordering");
    const [hasToOrderListButton, setHasOrderListButton] = useState<boolean>(props.hasToOrderListButton);

    React.useEffect(() => {
        if(props.localUser != undefined)
        {
            if(props.localUser.roles?.includes("ROLE_NORMAL"))
                setHasOrderListButton(true);
            else
                setHasOrderListButton(false);
        }

    }, [props.localUser])

    return (
        <div className="welcome-container">
            <IonGrid>
                <IonRow className="main-logo">
                    <IonCol size='4' sizeLg='4' sizeSm='4' sizeMd='4' sizeXs='8' offset='4' offsetLg='4' offsetSm='4' offsetMd='4' offsetXs='2' >
                        <IonImg src='images/start_app_top.jpg' />
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonGrid className="button-group">
                        <IonRow>
                            <IonCol size='10' offset='1' sizeLg='6' sizeSm='6' sizeMd='6' sizeXs='10' offsetLg='3' offsetSm='3' offsetMd='3' offsetXs='1'>
                                <IonButton className="to-ordering"  expand='block' fill='outline' onClick={(e:any) => {if (props.handleButtonClickBeforeAction != undefined ) props.handleButtonClickBeforeAction(e); navigateToOrdering();}}>
                                    Material<br/> bestellen  
                                </IonButton>
                            </IonCol>
                        </IonRow>
                    {hasToOrderListButton == true 
                    ?
                        <IonRow>
                            <IonCol size='10' offset='1' sizeLg='6' sizeSm='6' sizeMd='6' sizeXs='10' offsetLg='3' offsetSm='3' offsetMd='3' offsetXs='1'>
                                <IonButton className="to-ordering" expand='block' fill='outline' onClick={(e:any) => {if (props.handleButtonClickBeforeAction != undefined ) props.handleButtonClickBeforeAction(e); navigateToOrderList();}}>
                                    Bestellübersicht
                                </IonButton>
                            </IonCol>
                        </IonRow>
                    : null}
                    </IonGrid>
                </IonRow>
                <IonRow className="app-logo buttom">
                    <IonCol size="4.5" sizeLg='4' sizeSm='4' sizeMd='4' sizeXs='8'  offset="3.75" offsetLg='4' offsetSm='4' offsetMd='4' offsetXs='2'>
                        <IonImg src='images/start_app_bottom.jpg' />
                    </IonCol>
                </IonRow>
            </IonGrid>
        </div>  
    )
  }
  
  export default WelcomeContainer 