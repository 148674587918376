import React, {useState} from 'react';

import { IonGrid, IonRow, IonCol, IonButton } from '@ionic/react';
import { IHandleOrder, IOrder } from '../../interfaces/order';

import Error from '../../components/Error';
import Loading from '../../components/base/Loading';

import { deliveryDateString } from '../../services/hooks/dateString';
import { materialAmountConverter, amountConverter } from '../../services/hooks/materialAmountConverter'
import { stringToHTMLconvert } from '../../services/hooks/stringToHTMLconvert';

import usePost from '../../services/hooks/usePost';
import useLocalUserDatabase from '../../services/account/useLocalUserDatabase';
import useLocalOrderListDatabase from '../../services/orders/useLocalOrderListDatabase';
import parse from 'html-react-parser';
interface IOrderSaveResponse {
    Error?: boolean,
    Success?: boolean,
    Msg?: string,
    primkey?: string,
    data?: any,
}

const Summary: React.FC<IHandleOrder> = (props) => { 
    const [order, setOrder] = useState<IOrder>()
    const {isLocalUserLoaded, setIsSaved, isSaved, loadExistingUser, localUser, saveUserData} = useLocalUserDatabase(undefined, false);
    const { data: orderSaveResponse, error: saveOrderError, setError: setSavedOrderError, loading, setLoading, repost: sendOrderingData, setData: setValidationData, setDoPost } = usePost("pre-order/dispo", {order: order, user: localUser}, false, (props.user?.token != undefined ? props.user.token : ""));
    const {setOrderToSave, isSaved: isSavedLocally, setIsSaved: setIsSavedLocallyOrderList} = useLocalOrderListDatabase();

    React.useEffect(() => {
        if(props.order != undefined)
        {
            let deliveryDate = new Date(String(props.order.deliveryDate));
            setOrder({...props.order, deliveryDate: deliveryDate});
        }
    }, [props.order]);

    // finish ordering process
    const doOrderingComplete = (e: any) => {
        setSavedOrderError("");

        if(localUser?.token != undefined && props.order != undefined)
            sendOrderingData();
    }

    // handling saving order
    React.useEffect(() => {
        if(orderSaveResponse != undefined)
        {
            let response: IOrderSaveResponse = orderSaveResponse;

            if(response.Error == true)
                setSavedOrderError("Es ist ein technisches Problem aufgetreten.");
            else if (response.Success == true && props.order != undefined){
                let updatedOrder:IOrder = {...props.order, primkey: response.primkey, isFinished: true, finishTime: new Date()};
                setOrderToSave(updatedOrder);
            }
        }
    }, [saveOrderError, orderSaveResponse])

    React.useEffect(() => {
        if(isSavedLocally == true && props.order != undefined)
        {
            setOrderToSave(undefined)
            props.updateOrder({...props.order, isFinished: true, finishTime: new Date()}, 'forwards')
        }
    }, [isSavedLocally])
 
    // build comment box
    let commentBox;
    if(props.order?.comment != undefined && props.order?.comment != '' && typeof props.order?.comment == 'string')
        commentBox = (
            <IonRow className="comment-box">
                <IonCol size="8">
                    <span className="col-headline">Kommentar</span>
                    {parse(stringToHTMLconvert(props.order.comment))}
                </IonCol>
            </IonRow>
        );
    
    return (
        <div>
            {loading == true ? <Loading size='full' />  :""}
            <IonGrid className="order-summary">
                {saveOrderError != "" && typeof saveOrderError == 'string'
                    ? (<IonCol size="12"><Error error={saveOrderError}/></IonCol>) : ""}
                <IonRow className="header">
                    <IonCol size="7">Artikel</IonCol>
                    <IonCol size="5">Menge</IonCol>
                </IonRow>
                <IonRow className="material-row">
                    <IonCol size="8" className="strong">{props.order?.material?.name}</IonCol>
                    <IonCol size="4" className="right-side-content strong">{amountConverter(props.order?.amount)} {materialAmountConverter(props.order?.material, props.order?.amountType)}</IonCol>
                </IonRow>
                <IonRow >
                    <IonCol size="12">Lieferung am {deliveryDateString(props.order?.deliveryDate != undefined ? new Date(props.order.deliveryDate) : props.order?.deliveryDate, props.order?.deliveryTimeIsUnimportant)}</IonCol>
                </IonRow>
                <IonRow >
                    <IonCol size="12">Transporter: {props.order?.transporter?.name}</IonCol>
                </IonRow>
                <IonRow >
                    <IonCol size="8">
                        <span className="col-headline">An die Baustelle</span><br/> 
                        <strong>{props.order?.construction?.address}</strong><br/>
                        {props.order?.construction?.street}<br/>
                        {props.order?.construction?.zip} {props.order?.construction?.city}
                    </IonCol>
                </IonRow>
                <IonRow >
                    <IonCol size="12">
                        <span className="col-headline">Ansprechpartner</span><br/> 
                        <strong>{props.order?.contactPerson?.salutation} {props.order?.contactPerson?.fullName}</strong><br/>
                        {props.order?.contactPerson?.phone != "" && props.order?.contactPerson?.phone != undefined ? "Tel: " + props.order?.contactPerson?.phone  : ""} <br/>
                        {props.order?.contactPerson?.email != "" && props.order?.contactPerson?.email != undefined ? "E-Mail: " + props.order?.contactPerson?.email  : ""}
                    </IonCol>
                </IonRow>
                {commentBox}
                <IonRow className="no-border">
                    <IonCol offset="2" size="8">
                        <IonButton className="ordering-continue-button" expand="block" onClick={doOrderingComplete}>Bestellen</IonButton>
                    </IonCol>
                </IonRow>
            </IonGrid>
        </div>
    )
}

export default Summary;