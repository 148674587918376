import React, {Component, MouseEventHandler, useState} from 'react';

// interfaces
import {IAccount, IUser} from '../../interfaces/account'

// hooks & services
import useLocalUserDatabase from '../../services/account/useLocalUserDatabase'
import useLocalOrderDatabase from '../../services/orders/useLocalOrderDatabase';
import useLocalOrderListDatabase from '../../services/orders/useLocalOrderListDatabase';

const Logout: React.FC<IAccount> = (props: IAccount) => {
    const [appUser, setAppUser] = useState<IUser>()
    const {isSaved, saveUserData} = useLocalUserDatabase(undefined)

    const {removeSavedOrder, savedOrderIsRemoved} = useLocalOrderDatabase();
    const {removeSavedOrderList, savedOrderListIsRemoved} = useLocalOrderListDatabase();

    React.useEffect(() => {
        setTimeout(() =>  {removeSavedOrder();}, 500)
    }, [])

    React.useEffect(() => { 
        if(savedOrderIsRemoved == true)
        {
            removeSavedOrderList();
        } 
        
    }, [savedOrderIsRemoved]);

    React.useEffect(() => { 
        if(savedOrderListIsRemoved == true)
        {

            let currentUser : IUser = JSON.parse(JSON.stringify(props.user));
            currentUser.isLoggedIn = false;
            
            setAppUser(currentUser);
            saveUserData(currentUser);

        } 
        
    }, [savedOrderListIsRemoved]);

    React.useEffect(() => {
        if(isSaved == true && appUser != null && appUser != undefined && savedOrderListIsRemoved == true && savedOrderIsRemoved)
            props.handleLoginState(appUser);

    }, [isSaved, appUser, savedOrderListIsRemoved, savedOrderIsRemoved]);

    return (null)
}

export {Logout}