import React, { useEffect, useState } from "react";

import {IUser} from '../../interfaces/account'
import { Storage } from '@ionic/storage'

function useLocalUserDatabase(user : IUser | undefined, doSave: boolean = true) {
    const [isMounted, setIsMounted] = useState<boolean>(true)

    const storage = new Storage();
    storage.create();

    const [doSetIsSave, setDoSetIsSave] = useState<boolean>(doSave)
    const [isSaved, setIsSaved] = useState<boolean>(false)
    const [isLocalUserLoaded, setIsLocalUserLoaded] = useState<boolean>(false)
    const [localUser, setLocalUser] = useState<IUser | undefined>()
    
    React.useEffect(() => {
        if(isLocalUserLoaded == false)
            loadExistingUser()

        return () => {
          setIsLocalUserLoaded(false);
          setIsMounted(false);
        };
    }, []);

    React.useEffect((): any => {
        if(isMounted == true)
        {
            if(user != undefined)
            {
                storage
                    .set('user', user)
                    .then(() => {
                        if(isMounted == true && doSetIsSave == true)
                            setIsSaved(true)
                    });
            }
        }
    }, [user])
    
    const saveUserData = (user: IUser, doSetIsSave: boolean = true) => {
        if(storage.get("user") != undefined)
        {
            storage.remove("user").then(()=> {
                storage
                    .set('user', user)
                    .then(() => {
                        if(isMounted == true && doSetIsSave == true)
                        {
                            setLocalUser(user)
                            setIsSaved(true);
                        }
                    })
                    .catch((e) => {
                        if(isMounted == true)
                            console.error(e)
                    });;
            })
        }
        else {
            storage
                .set('user', user)
                .then(() => {
                    if(isMounted == true && doSetIsSave == true)
                    {
                        setLocalUser(user);
                        setIsSaved(true);
                    }
                })
                .catch((e) => {
                    if(isMounted == true)
                        console.error(e)
                });
        }   
    }

    const loadExistingUser = () => {
        if(isMounted == true)
        {
            setIsLocalUserLoaded(true)
            if(isLocalUserLoaded == false){
                storage.get('user').then((data) => {
                    if(isMounted == true)
                        setLocalUser(data)
                }).catch((e) => {
                    if(isMounted == true)
                    {
                        console.error(e)
                        setIsLocalUserLoaded(false);
                    }
                });
            }
        }
    }

    return { isLocalUserLoaded, isSaved, setIsSaved , loadExistingUser, localUser, useLocalUserDatabase, saveUserData }// loading, error};
}

export default useLocalUserDatabase;