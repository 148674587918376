
export const array_chunk = (data:any[], size: number) => {
    if (!Array.isArray(data)) 
        throw new TypeError('Input should be Array');

    if (typeof size !== 'number') 
        throw new TypeError('Size should be a Number');

    var result = [];

    for (var i = 0; i < data.length; i += size) 
        result.push(data.slice(i, size + i));
    
    return result;
}