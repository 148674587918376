import React, { useEffect, useState } from "react";
import { useLocation } from 'react-router-dom';

import { IAppPage } from "../../interfaces/application";
import useInternalNavigate from "./internalNavigate";
import useLocalModuleData from '../useLocalModuleData';

function useCheckAvailableModule() {
    const [isMounted, setIsMounted] = useState<boolean>(true);
    
    const location = useLocation();
    const {navigateInternal} = useInternalNavigate();
    
    const [isModuleAvailable, setIsModuleAvailable] = useState<boolean>(true);
    const [currentModule, setCurrentModule] = useState<IAppPage>();
    const {loadModuleData, localModules} =  useLocalModuleData();

    React.useEffect(() => {
        return () => {
          setIsMounted(false);
        };
    }, []);

    React.useEffect(() => {
        if(localModules !== undefined){
            let module : IAppPage|undefined = getCurrentModule(location.pathname);
            setCurrentModule( module);
            
            if(module !== undefined){
                checkAvailablityOfModule(module?.itemcallname);
            } 
        }
    },[location.pathname, localModules]);
    
    const checkAvailablityOfModule = (currentPageName: string = "") :boolean => {
        if (currentPageName === "" && currentModule !== undefined)
            currentPageName = currentModule?.itemcallname;

        let isAvailable = false;
        if(localModules !== undefined ) {
            // Add available modules 
            localModules.forEach((localItem, index) => {
                if(currentPageName == localItem.itemcallname)
                  isAvailable = true;
            });
        }
        else
            isAvailable = true;

        setIsModuleAvailable(isAvailable);
        return isAvailable;
    };

    const moveToAvailableModule = () => {
        if(localModules != undefined) {
            let moveToAppPage:IAppPage|any = []; 
            localModules.forEach((item, index) => {
                if(index === 0)
                moveToAppPage = item;
            });
    
            if(moveToAppPage !== undefined){
                navigateInternal(false, moveToAppPage.itemcallname);
            }
            else if(moveToAppPage === undefined){
                navigateInternal(false, 'logout');
            }
        }
    };
    
    const getCurrentModule = (path: string) : IAppPage|undefined => {
        let currentModule: IAppPage|undefined = undefined;
        if(localModules !== undefined) {
            localModules.forEach((item, index) => {
                if(item.url === path)
                    currentModule = item;
            });
        }
        
        if(currentModule !== undefined)
            setCurrentModule(currentModule);

        return currentModule;
    };

    return {checkAvailablityOfModule, currentModule, localModules, moveToAvailableModule, loadModuleData};
}

export default useCheckAvailableModule;