import React from 'react';

import { IonSearchbar, IonContent, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar } from '@ionic/react';
import { useParams } from 'react-router';
import { render } from '@testing-library/react';

import {ISearch} from '../../interfaces/search'

let searchKeyUpTimeout:any = undefined;

const Search: React.FC<ISearch> = (props) => {
    const [search, setSearch] = React.useState<string>();
    const [isDisabled, setIsDisabled] = React.useState<boolean>(props.minSearchChars != undefined ? (props.minSearchChars > 0 ? true : false) : false )

    React.useEffect(() => {
        if(props.resetSearch == true && search != "")
            setSearch("");   
    })

    React.useEffect(() => {
        //setTimeout(() => {setIsDisabled(false)}, 8000)
    }, [isDisabled])

    const doSearch = (e: any) => {

        var value: string | undefined = e.detail.value;
        var doSearch: boolean = true;
        if(value != undefined )
        {
            if(searchKeyUpTimeout != undefined)
            {
                clearTimeout(searchKeyUpTimeout);
            }

            if(props.minSearchChars != undefined)
            {
                if(props.minSearchChars > value.length && value.length > 0)
                {
                    value = "";
                    e.detail.value = "";
                    doSearch = false;
                }    
            }
           
            if(doSearch == true){
                //searchKeyUpTimeout = setTimeout(() => {
                    setSearch(value);
                    if(props.handleSearch != undefined)
                        props.handleSearch(e);

               // }, 100);
            }
        }
    }

    return (
        <div className="search-container">
            <IonSearchbar disabled={props.searchDisabled} placeholder="Suche" cancelButtonIcon={props.cancelButtonIcon}
                value={search}
                onIonChange={doSearch}
                showCancelButton="never"></IonSearchbar>
            {props.noSearchResult == true ? <p className="error-message">Kein Eintrag gefunden</p> : ""}
        </div>
    );
};

export default Search;
