import React from 'react';

import { IonButtons, IonContent, IonHeader, IonIcon, IonMenuButton, IonPage, IonTitle, IonToolbar } from '@ionic/react';
import { useParams } from 'react-router';
import { render } from '@testing-library/react';

import Header from './Header';
import Search from './Search';
import SearchBar from '../orders/SearchBar';
import IntroductionContainer from '../IntroductionContainer';

import {IHandleReload, IHandleSearch} from '../../interfaces/search'
export interface IGoBackFunc {
    () : void
} 

interface MainProps {
    name: string,
    title?: string,
    description?: string | any
    extraClassName?: string,
    handleReload?: IHandleReload, 
    hasSearch?: boolean,
    handleSearch?: IHandleSearch,
    minSearchChars?:number,
    resetSearch?: boolean,
    noSearchResult?: boolean,
    hasBackButton?:boolean,
    goBack?: IGoBackFunc,
    topElements?: any
    searchDisabled?: boolean,
}

const defaultProps: MainProps = {
    name: "",
    title: "",
    description: "",
    extraClassName: "",
    hasSearch: false,
    resetSearch: false,
    noSearchResult: false,
    minSearchChars:3,
    hasBackButton: false,
}

class Base extends React.Component<MainProps, MainProps> {
    constructor(props: MainProps){
        super(props)
    }
    
    render(){
        // Introduction Text
        let introText = null;           
        if(this.props.description != undefined || this.props.title != undefined)
            introText = <IntroductionContainer title={this.props.title} description={this.props.description}/>;
        return (
            <IonPage>
                <IonContent fullscreen>
                    <Header name={this.props.name} hasBackButton={this.props.hasBackButton} goBack={this.props.goBack}/>
                    <div className={"main-content " + this.props.extraClassName}>
                        
                        {/* define search bar(s) */}
                        {this.props.hasSearch == true  
                            ?
                            (this.props.extraClassName == 'ordering' 
                                ? <SearchBar searchDisabled={this.props.searchDisabled} handleSearch={this.props.handleSearch} resetSearch={this.props.resetSearch} noSearchResult={this.props.noSearchResult} handleReload={this.props.handleReload} minSearchChars={this.props.minSearchChars}/> 
                                : <Search handleSearch={this.props.handleSearch}  resetSearch={this.props.resetSearch} noSearchResult={this.props.noSearchResult} minSearchChars={this.props.minSearchChars} />
                            )
                            : ""
                        }
                        {/* top elements */}
                        {this.props.topElements}

                        {/* introductionText */}
                        {introText}
                        {/* the child elements */}
                        {this.props.children}
                    </div>     
                </IonContent>
            </IonPage>
        );
    }
};

export default Base;