import React, { useState } from 'react';

import {IonButton, IonPage } from '@ionic/react';
import { render } from '@testing-library/react';
import { useParams } from 'react-router';
import { settings } from '../../settings/settings';
// interfaces
import { IApplication } from '../../interfaces/application';
import {IConstruction, instanceOfConstruction, IMaterial, instanceOfMaterial, IOrder, IOrderingStage } from '../../interfaces/order';

// components
import Base from '../../components/base/Base';
import RestartContainer from '../../components/base/RestartContainer';
import Customers from './Customers';
import Constructions from './Constructions';
import Materials from './Materials';
import Amount from './Amount';
import Summary from './Summary';
import Success from './Success';

import Welcome from '../Welcome';
// hooks & services
import useLocalAppDatabase from '../../services/useLocalAppDatabase';
import useLocalUserDatabase from '../../services/account/useLocalUserDatabase';
import useLocalContactPersonDatabase from '../../services/orders/useLocalContactPersonDatabase'
import useLocalOrderDatabase from '../../services/orders/useLocalOrderDatabase'
import { Icon } from 'ionicons/dist/types/components/icon/icon';
import { handLeftSharp, save } from 'ionicons/icons';
import { attachProps } from '@ionic/react/dist/types/components/utils';
import { ICustomer, instanceOfCustomer } from '../../interfaces/customer';

import useGet from '../../services/hooks/useGet';

let originStages: IOrderingStage[] = [];
 
/***
 *  Main Page for ordering material
 *    Includes the pages Constructions, Materials, Amount, Summary and Success (ordering stages)
 *    
 *    on user role "SALE_ROLE" the stage Customers is the first stage number
 */
const Ordering: React.FC<IApplication> = (props) => { 
  let name = "Bestellen";
  
  const [isMounted, setIsMounted] = useState<boolean>(true)

  React.useEffect(() => {
    setIsMounted(true);

    return () => {
      originStages = [];
      setIsMounted(false);
    };
  }, []);

  const {localApplication, loadApplicationData, saveApplicationData} = useLocalAppDatabase();
  const {localUser} = useLocalUserDatabase(undefined)

  React.useEffect(() => {
    if(localUser !== undefined)
      setDoUpdateStages(true);
    
  }, [localUser])

  const {savedOrder, isLocalLoaded, setSavedOrder, removeSavedOrder, savedOrderIsRemoved, setSavedOrderIsRemoved} = useLocalOrderDatabase();
  const [hasSearchResult, setHasSearchResult] = useState<boolean>(false);

  React.useEffect(() => {
    if(savedOrder !== undefined)
    {
      setCurrentOrder(savedOrder);
      if(savedOrder.currentStage !== undefined && savedOrder.currentStage !== currentStage?.stageNumber)
        setMovement("currentStage")
      else if (savedOrderIsRemoved === true)
        setStages(undefined)
      else
        setStages(originStages)
    }
  },[savedOrder, isLocalLoaded, savedOrderIsRemoved])

  const [currentOrder, setCurrentOrder] = useState<IOrder>()
  const [movement, setMovement] = useState<string>()
  
  /* changeable descriptions and templates */
  const [materialDescription, setMaterialDescription] = useState<string | any>(<p>Bitte wählen Sie einen der Artikel für die Baustelle <strong>{currentOrder?.construction?.address} {currentOrder?.construction?.zip} {currentOrder?.construction?.city}</strong> das Material aus.</p>)
  const [timeAndAmountDescription, setTimeAndAmountDescription] = useState<string | any>(<p>Geben Sie bitte den Zeitpunkt und die Menga an, an dem das Material <strong>{currentOrder?.material?.name}</strong> an die Baustelle <strong>{currentOrder?.construction?.address} ({currentOrder?.construction?.zip} {currentOrder?.construction?.city}</strong>) geliefert werden soll.</p>)
  
  /* order update processes */
  const handleOrderUpdate = (order: IOrder | undefined | null, movementType: string | undefined) => {  
    if(order !== undefined && order !== null){
      if(Object.keys(order).length > 0)
      {
        let theOrder: IOrder = currentOrder !== undefined ? {...currentOrder, appToken: localApplication?.appToken} : {isUpdated: false, isDeleted:false, appToken: localApplication?.appToken};
        
        if(order.amount !== undefined)
          theOrder.amount = order.amount;
        if(order.amountType !== undefined)
          theOrder.amountType = order.amountType;

        if(order.comment !== undefined)
          theOrder.comment = order.comment;
        if(order.construction !== undefined)
          theOrder.construction = order.construction;
        if(order.contactPerson !== undefined)
          theOrder.contactPerson = order.contactPerson;
        if (order.currentStage !== undefined)
          theOrder.currentStage = order.currentStage;          
        if (order.customer !== undefined)
          theOrder.customer = order.customer;        

        if(order.deliveryTimeIsUnimportant !== undefined)
          theOrder.deliveryTimeIsUnimportant = order.deliveryTimeIsUnimportant;  
        if(order.deliveryDate !== undefined)
          theOrder.deliveryDate = order.deliveryDate;

        if(order.material !== undefined)
          theOrder.material = order.material;

        if(order.orderDate !== undefined)
          theOrder.orderDate = order.orderDate;
          
        if(order.transporter !== undefined)
          theOrder.transporter = order.transporter;
        
        if(order.isFinished !== undefined)
          theOrder.isFinished = order.isFinished;
        if(order.finishTime !== undefined)
          theOrder.finishTime = order.finishTime;

        if(order.doLocalRemovement === true)
          removeSavedOrder();
        
        const newCurrentOrder = {...theOrder};
        setCurrentOrder(newCurrentOrder)
      }
    }
    
    if(movementType === "forwards" || movementType === "backwards")
      setMovement(movementType)
  }

  const updateSearchItems = (items: ICustomer | IConstruction | IMaterial | any, stageNumber: number) => {
    if(stages !== undefined )
    {
      let newStages = stages.map((stage, index) => {
        if(stage.stageNumber === stageNumber)
          stage.searchItems = items;

        return stage;
      }); 

      setStages(newStages);
    }
  }

  const handleUpdateOrderAfterClick = () => {
    if( currentOrder !== undefined)
      handleOrderUpdate({...currentOrder, doLocalRemovement: true}, undefined)
  }

  // only set once the origin stages
  // otherwise the variable will be changed on every react change
  if(originStages.length === 0 && localUser !== undefined)
  {
    originStages = [
      {
        active: localUser?.roles?.includes("ROLE_NORMAL") ? true : false,
        name: "Construction", 
        title: "Auswahl der Baustelle", 
        description: "<p>Bitte wählen Sie eine Baustelle aus, für die Sie etwas bestellen wollen.</p>", 
        fetchURL: "", 
        hasSearch: true,
        searchItems: [],
        hasBackButton: localUser?.roles?.includes("ROLE_NORMAL") ? false : true,
        stageNumber: localUser?.roles?.includes("ROLE_NORMAL") ? 0 : 1,
        template: <Constructions order={currentOrder} updateOrder={handleOrderUpdate} updateSearchItems={updateSearchItems} stageNumber={ localUser?.roles?.includes("ROLE_NORMAL") ? 0 : 1} user={localUser}/>,
      },
      {
        active: false,
        name: "Material", 
        title: "Auswahl des Materials" , 
        description: materialDescription, 
        fetchURL: "",
        hasSearch: true,
        searchItems: [],
        hasBackButton: true,
        stageNumber: localUser?.roles?.includes("ROLE_NORMAL") ? 1 : 2,
        template: <Materials order={currentOrder} updateOrder={handleOrderUpdate}  updateSearchItems={updateSearchItems} stageNumber={ localUser?.roles?.includes("ROLE_NORMAL") ? 1 : 2} user={localUser}/>, 
      },
      { 
        active: false,
        name: "Time-Amount", 
        title: "Zeitpunkt und Menge", 
        description: timeAndAmountDescription, 
        fetchURL: "", 
        hasSearch: false,
        hasBackButton: true,
        stageNumber: localUser?.roles?.includes("ROLE_NORMAL") ? 2 : 3,
        template: <Amount order={currentOrder} updateOrder={handleOrderUpdate} stageNumber={localUser?.roles?.includes("ROLE_NORMAL") ? 2 : 3} user={localUser}/>,
      },
      {
        active: false,
        name: "Summary",
        title: "Bestellabschluss",
        description: "<p>Bitte prüfen Sie die Bestellung</p>",
        fetchURL: "",
        hasSearch: false,
        hasBackButton: true,
        stageNumber: localUser?.roles?.includes("ROLE_NORMAL") ? 3 : 4,
        template: <Summary order={currentOrder} updateOrder={handleOrderUpdate} stageNumber={localUser?.roles?.includes("ROLE_NORMAL") ? 3 : 4} user={localUser}/>,
      },
      {
        active: false,
        name: "Success",
        title: "Bestellabschluss",
        description: "<p>Ihre Bestellung ist abgeschickt.</p>",
        fetchURL: "",
        hasOwnTemplate: settings.appData.appPackagename === "com.tbsoft.minerals.kaltenbach" ? true : false,
        hasSearch: false,
        hasBackButton: false,
        stageNumber: localUser?.roles?.includes("ROLE_NORMAL") ? 4 : 5,
        template: settings.appData.appPackagename === "com.tbsoft.minerals.kaltenbach" ?<Welcome {...props} localUser={localUser} handleOrder={{order:currentOrder, updateOrder:handleOrderUpdate}} handleButtonClickBeforeAction={handleUpdateOrderAfterClick} pagePath="/bestellen" hasToOrderListButton={true} /> : <Success order={currentOrder} updateOrder={handleOrderUpdate}/>,
      }
    ];
  }
  
  const [doUpdateStages, setDoUpdateStages] = useState<boolean>(false);
  const [stages, setStages] = useState<IOrderingStage[]>();
  const [areStagesRebuilt, setAreStagesRebuilt] = useState<boolean>(false);
  
  React.useEffect(() => {
    if(stages !== undefined && currentStage === undefined)
    { 
      if(localUser !== undefined)
      {
        if(localUser.isLoggedIn === true)
        {
          if(localUser.roles !== undefined && typeof localUser.roles === 'object')
          {
              searchActiveStage()
          }
        }
      }
    }
  }, [stages])

  /* handle the stages && update or save contact person if next stage is summary */
  const {saveContactPerson} = useLocalContactPersonDatabase()
  const [currentStage, setCurrentStage] = useState<IOrderingStage>();
  
  const searchActiveStage = (newStages: IOrderingStage[] | undefined = undefined) => {
    if(stages !== undefined)
    {
      stages.map((stage) => {
        if(stage.active === true)
        {
          // save or update contact person
          if(stage.name === "Summary" && currentOrder?.contactPerson !== undefined)
            saveContactPerson(currentOrder.contactPerson);

          setCurrentStage(stage)
        }
      });
    }
  }

  React.useEffect(() => {
    // movement process after restarting order
    if(savedOrderIsRemoved === true && stages === undefined)
    {
      setCurrentStage(undefined)
      setStages(originStages)
      setSavedOrderIsRemoved(false);
    }    
  }, [stages])

  React.useEffect(() => {
    searchActiveStage();
    setDoUpdateStages(true);
  },[currentStage]);

  /* Update descriptions by current order data */
  React.useEffect(() => {
    if (currentOrder !== undefined){
      //console.error("use effect ", currentOrder)
      let materialDescription: string; 
      let timeAndAmountDescription: string; 
      
      if(currentOrder?.customer?.customername !== undefined)
        materialDescription = "<p>Bitte wählen Sie einen Artikel, für den Kunden <strong>" + currentOrder.customer.customername + " (Kundennummer: " + currentOrder.customer.customer_nr +")</strong> und dessen Baustelle <strong>" + currentOrder?.construction?.address + " " +  currentOrder?.construction?.zip + " " +  currentOrder?.construction?.city + "</strong>, das Material aus.</p>";
      else
        materialDescription = "<p>Bitte wählen Sie einen Artikel für die Baustelle <strong>" + currentOrder?.construction?.address + " " + currentOrder?.construction?.zip + " " + currentOrder?.construction?.city + "</strong> das Material aus.</p>";

      if(currentOrder?.customer?.customername !== undefined)
        timeAndAmountDescription = "<p>Geben Sie bitte den Zeitpunkt und die Menge für den Kunden <strong>" + currentOrder.customer.customername + " (Kundennummer: " + currentOrder.customer.customer_nr +")</strong> an, an dem das Material <strong>" + currentOrder?.material?.name + "</strong> an die Baustelle <strong>" + currentOrder?.construction?.address + " (" + currentOrder?.construction?.zip + " " +  currentOrder?.construction?.city + ")</strong> geliefert werden soll.</p>";
      else
        timeAndAmountDescription = "<p>Geben Sie bitte den Zeitpunkt und die Menge an, an dem das Material <strong>" + currentOrder?.material?.name + "</strong> an die Baustelle <strong>" + currentOrder?.construction?.address + " (" + currentOrder?.construction?.zip + " " +  currentOrder?.construction?.city + ")</strong> geliefert werden soll.</p>";


      setMaterialDescription(materialDescription);
      setTimeAndAmountDescription(timeAndAmountDescription);
      setDoUpdateStages(true);
    }
  }, [currentOrder]);

  /****************
   ** Update Stages
    **   1. Update templates + descriptions
    **       => neccessary for setup the current order state in the component; elsewhere the component has an old order state
    **   2. Define the handleSearch funcs => setup for getting the searchItems of currentStage; defining before give an undefined result of currentStage
    ************************/
  const [searchDisabled, setSearchDisabled] = useState<boolean>(false);

  const handleSerachDisableStatus = (status: boolean) => {
    setSearchDisabled(status)
  }

  const [resetSearch, setResetSearch] = useState<boolean>(false);
  const [noSearchResult, setNoSearchResult] = useState<boolean>(false)
  React.useEffect(() => {
    if(doUpdateStages === true && stages !== undefined)// && savedOrder !== undefined)
    {
      if(localUser?.roles?.includes("ROLE_SALE") && areStagesRebuilt === false && stages  && stages[0]['name'] !== "Customers")
      {
        setTimeout(() => {
          let currentStages = stages.map((stage) => {return stage});
          if (currentStages !== undefined && currentStages[0]['name'] !== "Customers")
          {
            originStages.unshift({
              active: true,
              name: "Customers",
              title: "Auswahl des Kunden",
              description: "<p>Wählen Sie den Kunden für die Bestellung aus.</p>",
              fetchURL: "",
              hasSearch: true,
              // handleSearch: handleSearch,
              minSearchChars: 2,
              searchItems: [],
              hasBackButton: false,
              stageNumber: 0,
              template: <Customers order={currentOrder} updateOrder={handleOrderUpdate} updateSearchItems={updateSearchItems} hasSearchResult={hasSearchResult} user={localUser} doReload={doReload} handleSerachDisableStatus={handleSerachDisableStatus}/>,
            });
            
            setStages(originStages);
            setAreStagesRebuilt(true);
          }
        }, 1500);
      }    

      let newCurrentStage;
      let newStages = stages.map((stage, index) => {
        if(stage.stageNumber !== currentOrder?.currentStage)
          stage.active = false;
        else
        {
          stage.active = true
          newCurrentStage = stage;
        }
        
        if(stage.name === 'Customers')
        {
          // define search in stage
          stage.handleSearch = handleSearch;
          stage.template = <Customers order={currentOrder} updateOrder={handleOrderUpdate} updateSearchItems={updateSearchItems} hasSearchResult={hasSearchResult} stageNumber={stage.stageNumber} user={localUser} doReload={doReload} handleSerachDisableStatus={handleSerachDisableStatus} />
        }

        if(stage.name === 'Construction')
        {
          // define search in stage
          if(currentOrder?.customer?.customername !== undefined)
            stage.description = "<p>Bitte wählen Sie eine Baustelle aus, für den Kunden <strong>" + currentOrder.customer.customername + " (Kundennummer: " + currentOrder.customer.customer_nr +")"+ "</strong>.</p>";
          else
            stage.description = "<p>Bitte wählen Sie eine Baustelle aus, für die Sie etwas bestellen wollen.</p>";
          
          stage.handleSearch = handleSearch;
          stage.template = <Constructions order={currentOrder} updateOrder={handleOrderUpdate} updateSearchItems={updateSearchItems} stageNumber={stage.stageNumber} user={localUser} doReload={doReload}/>
        }

        if(stage.name === 'Material' && currentOrder?.construction?.construction_nr !== undefined)
        {
          // define search in stage
          stage.handleSearch  = handleSearch;
          stage.description   = materialDescription;
          stage.template      = <Materials order={currentOrder} updateOrder={handleOrderUpdate} updateSearchItems={updateSearchItems} stageNumber={stage.stageNumber} user={localUser} doReload={doReload}/>
        }

        if (stage.name === 'Time-Amount')
        {
          stage.description = timeAndAmountDescription;
          stage.template = <Amount order={currentOrder} updateOrder={handleOrderUpdate} stageNumber={stage.stageNumber} user={localUser}/>
        }

        if(stage.name === 'Summary')
          stage.template = <Summary order={currentOrder} updateOrder={handleOrderUpdate} stageNumber={stage.stageNumber} user={localUser}/>
        
        if(stage.name === 'Success')
          stage.template =  settings.appData.appPackagename === "com.tbsoft.minerals.kaltenbach" ? <Welcome {...props} localUser={localUser} handleOrder={{order:currentOrder, updateOrder:handleOrderUpdate}} handleButtonClickBeforeAction={handleUpdateOrderAfterClick} pagePath="/bestellen" hasToOrderListButton={true} /> : <Success order={currentOrder} updateOrder={handleOrderUpdate} stageNumber={stage.stageNumber} user={localUser}/>;//<Success order={currentOrder} updateOrder={handleOrderUpdate} stageNumber={stage.stageNumber} user={localUser}/>

        return stage;
      });
      setStages(newStages);
      setSavedOrder(currentOrder);

      // Update the current stage for getting new values of property current order
      setCurrentStage(newCurrentStage)
      
      setDoUpdateStages(false);
    } 
  }, [materialDescription, timeAndAmountDescription, doUpdateStages])

  /* search funcs */
  const handleSearch = (event:any ) => {
    let search: string = event.detail.value;
   
    if(currentStage !== undefined){
      
      if(currentStage.searchItems !== undefined)
      {
        let jsonData: string          = JSON.stringify(currentStage.searchItems),
            doSearch: boolean         = jsonData.toLowerCase().includes(search.toLowerCase()),
            itemFound: boolean        = false,
            resetVisibiltiy: boolean  = true;

        let amountOfFound:number = 0;
        let searchItems: (IConstruction | IMaterial | any)[] = currentStage.searchItems.map((item: IConstruction | IMaterial | any, index: number) => {
          
          if(doSearch === true)
          {
            if(instanceOfConstruction(item))
            {
              if(item.address.toLowerCase().includes(search.toLowerCase()) || item.street?.toLowerCase().includes(search.toLowerCase()) || item.zip?.toLowerCase().includes(search.toLowerCase()) || item.city?.toLowerCase().includes(search.toLowerCase()))
              {
                item.isVisible  = true;
                itemFound       = true;
                resetVisibiltiy = false;
              }
              else 
                item.isVisible = false;  
            }

            if(instanceOfCustomer(item))
            {
              if(item.customername.toLowerCase().includes(search.toLowerCase()) || item.additional?.toLowerCase().includes(search.toLowerCase()) || item.city?.toLowerCase().includes(search.toLowerCase()) ||  item.customer_nr?.toLowerCase().includes(search.toLowerCase()))
              {
                amountOfFound++;
                item.isVisible  = true;
                itemFound       = true;
                resetVisibiltiy = false;
              }
              else 
                item.isVisible = false;  
            }

            if(instanceOfMaterial(item))
            {
              item.isVisible = item.name.toLowerCase().includes(search.toLowerCase());
              
              if(item.isVisible === true && itemFound === false)
              {
                itemFound       = true;
                resetVisibiltiy = false;
              }
            }
          }
          else
          {
            item.isVisible  = true;
            resetVisibiltiy = false;
          }
              
          return item;
        });
        
        if(resetVisibiltiy === true && itemFound === false)
        {
          searchItems = currentStage.searchItems.map((item: IConstruction | IMaterial | any, index: number) => {
            item.isVisible = true;
            return item;
          });
        }

        itemFound = (currentStage.searchItems.length <= 0 && search === "" ? true: itemFound);

        setNoSearchResult(!itemFound);
        
        setHasSearchResult(search === "" ? false :( doSearch === true? itemFound : doSearch));
        setCurrentStage({...currentStage, searchItems: searchItems});
      }
    }
  };

  React.useEffect(() => {
    if(resetSearch === true)
      setResetSearch(false);
  }, [resetSearch])
  
  /* handle movements */
  const handleGoBack = () => {
    let updatedOrder: any = {}
    
    if(currentStage?.name === 'Material')
      updatedOrder.construction = {};
    
    if(currentStage?.name === 'Time-Amount')
      updatedOrder.material = {};

    handleOrderUpdate(updatedOrder, 'backwards');  
  }

  React.useEffect(() => {
    if(stages !== undefined && (movement === 'forwards' || movement === 'backwards')){
      setMovement("")
      let number: number = -1;

      if (movement === 'forwards' && currentStage !== undefined)
        number = currentStage.stageNumber + 1;

      if (movement === 'backwards' && currentStage !== undefined)
        number = currentStage.stageNumber - 1;
      
      if(number > -1 && number < stages.length && currentStage !== undefined)
      {
        setResetSearch(true)
        setCurrentStage(stages[number])
        // save stagenumber to current order
        if(currentOrder !== undefined)
        {
          let updatedOrder = {...currentOrder, currentStage: number, appToken: localApplication?.appToken};
          handleOrderUpdate(updatedOrder, undefined);
          setSavedOrder(updatedOrder);
        }
        
        let theStages = [...stages];
        theStages[currentStage.stageNumber].active = false;
        theStages[number].active = true;
        setStages(theStages);
      }
      else
        console.error("No stage found: Stage Nr " + number + " does not exist.")
    }

    if (movement === 'currentStage' && currentOrder !== undefined)
    { 
      if (currentStage === undefined)
      {
          const newStages = originStages.map((stage, index) => {
            if(stage.stageNumber === currentOrder.currentStage)
            {
              stage.active = true;
              setCurrentStage(stage)
            }
            else
              stage.active = false;

            return stage;
          });

          setStages(newStages);
      }
    }
  }, [movement]);

  /* Reload data => refetch data from API */
  const [doReload, setDoReload] = useState<boolean>(false);
  const handleReload = (event:any) => {
    setDoReload(true)
  }

  React.useEffect(() => {
    if(doReload === true)
    { 
      setDoUpdateStages(true);
      setTimeout(() => {
        setDoReload(false)
      }, 1500);
    }
    else
      setDoUpdateStages(true);
    
  }, [doReload]);

  /* restart order => remove current ordering from local database */
  const restartOrdering = () => { removeSavedOrder();}

  return (
    <IonPage>
      {currentStage?.hasOwnTemplate === true
      ? (currentStage.template) 
      :
      (<Base name={name} title={currentStage?.title} description={currentStage?.description} extraClassName="ordering" 
            hasSearch={currentStage?.hasSearch} handleSearch={currentStage?.handleSearch} resetSearch={resetSearch} noSearchResult={noSearchResult} minSearchChars={currentStage?.minSearchChars !== undefined ? currentStage.minSearchChars : 0} searchDisabled={searchDisabled}
            handleReload={handleReload}
            hasBackButton={currentStage?.hasBackButton} goBack={handleGoBack}
            topElements={((currentStage?.stageNumber !== 0 && currentStage?.stageNumber !== 4) && currentStage?.stageNumber !== undefined ? <RestartContainer doRestart={restartOrdering}  />: "")}> 
        {currentStage?.template}
      </Base>)}
    </IonPage>
  );
}

export default Ordering;