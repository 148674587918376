import React, {useState} from 'react';
import { IonButton, IonGrid, IonRow, IonCol, IonItem, IonLabel, IonText, IonTextarea } from '@ionic/react';

import {IHandleOrder} from '../../interfaces/order';

import Errors, {IErrorList} from '../../components/Errors'
import {IErrors} from "../../interfaces/account"

import { email_check } from '../../services/account/email_check';

import AmountAndType from '../../components/orders/AmountAndType';
import TransporterGrid from '../../components/orders/TransporterGrid';
import DateAndTimePicker from '../../components/orders/DateAndTimePicker';
import ContactPerson from '../../components/orders/ContactPerson';


const Amount: React.FC<IHandleOrder> = (props) => { 
    const [errorList, setErrorList] = useState<IErrors>({})
    const [updated, setUpdated] = useState<boolean>(false);
    const handleNextClick = (event:any) => {
        let hasErrors = false;
        if (props.order != undefined)
        {   
            let errorList: IErrors = {otherErrors: []}

            if(props.order.amount == undefined || props.order.amount == 0)
            {
                errorList.otherErrors.push("Bitte geben Sie die Menge Ihrer Bestellung an.");
                hasErrors = true;
            }

            if(props.order.transporter == undefined )
            {
                errorList.otherErrors.push("Bitte wählen Sie einen Transportertyp aus.");
                hasErrors = true;
            }
            else if (props.order.transporter.active == false)
            {
                errorList.otherErrors.push("Bitte wählen Sie einen Transportertyp aus.");
                hasErrors = true;
            }

            if(props.order.contactPerson == undefined || (props.order.contactPerson?.fullName == undefined || props.order.contactPerson?.fullName == "" || props.order.contactPerson?.phone == undefined || props.order.contactPerson?.phone == ""))
            {
                errorList.otherErrors.push("Bitte füllen Sie den Ansprechpartner aus.");
                hasErrors = true;
            }

            if(props.order.contactPerson?.email != undefined)
            {
                if(props.order.contactPerson?.email != "" && !email_check(props.order.contactPerson?.email))
                {
                    errorList.otherErrors.push("Bitte geben Sie eine gültige E-Mail für den Ansprechpartner an.");
                    hasErrors = true;
                }
            }
            
            setErrorList(errorList);
        }   
        else 
        {
            setErrorList({otherErrors: ["Ein unerwarterer Fehler ist aufgetreten."]});
            hasErrors = true;
        }

        if(hasErrors == false){
            let order : any = {};
            props.updateOrder(order, "forwards");
        }
    }

    const handleCommentChange = (event: any) => {
        let comment:string = "";
        let doUpdate:boolean = false;
        
        if(typeof event == 'string')
        {
            comment = event;
            doUpdate = true;
        }
        else if (event.detail != undefined)
            comment = event.detail.value;

        if (props.order != undefined)
        {
            const currentOrder:any = {...props.order, comment: comment};
            props.updateOrder(currentOrder);
            
            if(doUpdate == true)
                setUpdated(true);
        }   
    }

    React.useEffect(() => {
        if (errorList != undefined)
        {
            if (Object.keys(errorList).length > 0)
                document.getElementsByClassName('error-container')[0].scrollIntoView({ block: 'start',  behavior: 'smooth' });
        }
    }, [errorList])

    React.useEffect(() => {
        if(props.user != undefined && props.order != undefined)
        {
            if(props.user.roles?.includes("ROLE_SALE") && (props.order?.comment == undefined || props.order?.comment == "") && updated == false)
            {
                let salesCommentText:string = "\n###################################### \n";
                salesCommentText           += "Bestellt aus dem Vertrieb:  \n";
                salesCommentText           += props.user.person?.firstname + " " + props.user.person?.surname + " \n";
                salesCommentText           += "E-Mail: " + props.user.email + " \n";
                salesCommentText           += "Tel: " + props.user.phone;
                handleCommentChange(salesCommentText);
            }
            else if(updated == true)
            {
                setTimeout(() => {setUpdated(false)}, 1000);
            }
        }
    }, [props.order, props.user, updated])
    return (
        <div className="content-amount-type">
            <Errors errors={errorList} />
            {/* define amount and type of amount */}
            <AmountAndType material={props.order?.material} order={props.order} updateOrder={props.updateOrder} />
            {/* transporter grid*/}
            <TransporterGrid order={props.order} user={props.user} updateOrder={props.updateOrder} />
            {/* date and time picker*/}
            <DateAndTimePicker title="Lieferung am" order={props.order} updateOrder={props.updateOrder} />
            <ContactPerson order={props.order} updateOrder={props.updateOrder} />
            <IonGrid>
                <h2>Kommentar</h2>
                <IonTextarea className="comment-box" rows={6} cols={20} placeholder="Kommentar" value={props.order != undefined ? props.order.comment : ""} onIonChange={handleCommentChange}></IonTextarea>
                <IonText className="small-info">* Pflichtfelder</IonText>
            </IonGrid>
            <IonGrid>
                <IonRow>
                    <IonCol offset="2" size="8">
                        <IonButton className="ordering-continue-button" expand="block" onClick={handleNextClick} >Weiter</IonButton>
                    </IonCol>
                </IonRow>
            </IonGrid>  
        </div>
    )
}

export default Amount;