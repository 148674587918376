import React, {useState} from 'react';
import { IonButton, IonCheckbox, IonLabel, IonInput, IonItem, IonPage} from '@ionic/react';
import { refresh } from 'ionicons/icons';

//import { render } from '@testing-library/react';
//import { useParams } from 'react-router';
import { settings } from '../../settings/settings';

// interfaces
import {IAccount, IErrors, ILoginForm, IUser} from '../../interfaces/account'

// components
import Base from '../../components/base/Base';
import Errors from '../../components/Errors'
import Loading from '../../components/base/Loading';

// hooks & services
import useGenerateSecurePassword from '../../services/account/useGenerateSecurePassword'
import useLocalUserDatabase from '../../services/account/useLocalUserDatabase';

import usePost from '../../services/hooks/usePost';
import useGet from '../../services/hooks/useGet';
import { email_check } from '../../services/account/email_check';

const Login: React.FC<IAccount> = (props) => { 
  const [isMounted, setIsMounted] = useState<boolean>(true)
  const name = "Login";

  const emailInput = React.useRef(null);
  const passwordInput = React.useRef(null);

  const [ignoreAPICall, setIgnoreAPICall] = useState<boolean>(true) 
  const [proofLoginData, setProofLoginData] = useState<boolean>(false)
  const [isValid, setIsValid] = useState<boolean>(false);

  const [user, setUser] = useState<IUser>()
  const {securePassword,  generatePassword, validationData} = useGenerateSecurePassword();
  const {data: companyData, loading: companyLoading, reDoGet: getCompanyData} = useGet("company/tbkuid-" + user?.tbkuid,false, props.user?.token != undefined ? {authorizationType: "Bearer", token: props.user.token} : undefined);
  const {isLocalUserLoaded, setIsSaved, isSaved, loadExistingUser, localUser, saveUserData} = useLocalUserDatabase(user, false)

  const [errors, setErrors] = useState<IErrors>({access: "", email: "", required: "", terms: ""});
  const [formData, setFormData] = useState<ILoginForm>({
      producerNrs : [1],
      producerNr:"",
      customerNr: "",
      password: "",
      email: (localUser !== undefined && localUser !== null ? localUser.email : ""),
      securePassword: "",
      terms_and_dataprotection_accepted: false
  });

  React.useEffect(() => {
    if(localUser !== undefined && localUser !== null)
      setFormData({...formData, email: localUser.email})
  }, [localUser]);

  const { data, loading: validationLoading, repost, setData: setValidationData } = usePost("user/validate", {email: formData.email, password: formData.password, ignoreAPICall: ignoreAPICall });
  // check local user and login state
  if(isLocalUserLoaded === false)
    loadExistingUser()

  // handle secure password 
  React.useEffect(()=> {
    if(isMounted === true)
      if(localUser !== undefined && localUser !== null)
      { 
        if(localUser.company !== undefined )
          setIsSaved(false);

        if(localUser.isLoggedIn === true){
          setUser(localUser)
          if(localUser.company === undefined && companyLoading === false && localUser.tbkuid !== undefined && localUser.tbkuid !== ""){
            getCompanyData(localUser.token);
          }
        }
      }
  },[localUser])
    
  // generate secure password
  if(securePassword === "")
    generatePassword();

  // handle secure password 
  React.useEffect(()=> {
    if(isMounted === true)
      if(securePassword !== "")
      { 
        formData.securePassword = securePassword;
        setFormData(formData)
      }
  },[securePassword])

  React.useEffect(() => {
    return () => {
      setIsMounted(false);
    };
  }, []);

  // login mechanism
  function handleLoginAction(event: any){
    let hasErrors: boolean = false;
    let errors: IErrors = {access: "", email: "", required: "",  terms: "",};

    if(formData.terms_and_dataprotection_accepted === false)
    {
      errors.terms = "Sie müssen die AGBs und Datenschutz-Richtlinien aktzeptieren um fortzufahren.";
      hasErrors = true;
    }

    if(formData.email === "" || (formData.producerNrs.length > 1 && (formData.producerNr === "" || formData.producerNr === 0) ) || formData.password === "")
    {
      errors.required = "Bitte füllen Sie alle Felder aus.";
      hasErrors = true;
    }
    
    if (formData.email !== null && formData.email !== undefined)
    {
      if(formData.email !== "" && !email_check(formData.email))   
      {
        errors.email = "Bitte geben Sie eien gültige E-Mail an."
        hasErrors = true;
      }
    }

    if(isMounted === true)
      setErrors(errors);
    
    // validate login data
    if (hasErrors === false)
      validateUserLogin()
    else if(isMounted === true) 
      setProofLoginData(false);
  }

  // handle validation of login data
  function validateUserLogin(){
    if(isMounted === true)
    {
      setIgnoreAPICall(false)
      setProofLoginData(true)
    }
  }

  // handle sending login data
  React.useEffect(() => {
    if(proofLoginData === true && isMounted === true){
      repost(false);
      setProofLoginData(false)
    }
  }, [ignoreAPICall, proofLoginData])

  // handle successfully login
  React.useEffect(() => {
    if(data !== null && data !== undefined && isMounted === true)
    {
        if (data.Error === true)
            errors.access = data.Msg
        else{
            errors.access = "";
            setIsValid(true)
        }
    }

    if(isValid === true && isMounted === true)
    {   
        let userData: IUser = {
            user_id: data.user_id,
            producer_nr: data.pnr,
            customer_nr: data.knr,
            company_id: data.CompanyId, 
            tbkuid: data.tbku_id,
            phone:  (data.phone !== undefined ? data.phone : ""),
            email: data.email,
            isLoggedIn: true,
            roles: JSON.parse(data.roles),
            person: data.person,
            appToken: props.appToken,
            token: "",
        }
        setValidationData(null);
        setIsValid(false)
        setUser(userData);
    }
    
    // load company Data
    if(user !== undefined && isMounted === true)
    {
        if(user.tbkuid !== undefined && user.tbkuid !== "" && (companyData === undefined || companyData === "") && companyLoading === false)
          getCompanyData(user.token);
            
        if(user.company === undefined && companyData !== undefined && companyData !== "" && user.tbkuid !== undefined) 
          setUser({...user, company: { _id: companyData.CompanyId, name: (companyData.company_name !== "" ? companyData.company_name : (companyData.customer_name !== "" ? companyData.customer_name : ""))}})
        
        if(user.isLoggedIn === true && (user.company !== undefined || user.roles?.includes('ROLE_NORMAL')  === false))
            saveUserData(user) 
        
        if(isSaved === true && localUser?.isLoggedIn === true && (localUser?.company !== undefined || user.roles?.includes('ROLE_NORMAL')  === false))
          setTimeout(() => props.handleLoginState(user), 300)
    }
  }, [companyData, data, isValid, user, isSaved, localUser])

  let description = "";
  let loginTypeFields = null

  const handleKeyUp = (e: any) => {

    if(e.key === "Enter")
    {
      if(e.target.name === "email"  )
      {
        /*
        if(passwordInput !== null)
          if (passwordInput.current !== null)
            passwordInput.current.focus();
        */
      }  
    }
  };

  /* form field configuration */
  if (props.loginType === 'single')
  {
    description = "<p>Bitte verifizieren Sie sich, damit Sie die App nutzen können.</p><p>Geben Sie hierzu Ihre <strong>Kundennummer</strong> und das dazugehörige <strong>Passwort</strong> ein.</p>";
    loginTypeFields = (
      <IonItem>
        <IonLabel position="stacked">Kundennummer:</IonLabel>
        <IonInput name="customernumber" onIonChange={(e) => {formData.customerNr = e.detail.value; setFormData(formData);}} onKeyUp={handleKeyUp} />
      </IonItem>)
  }
  else if (props.loginType === 'multiple')
  {
    description = "<p>Bitte verifizieren Sie sich, damit Sie die App nutzen können.</p><p>Geben Sie hierzu Ihre <strong>E-Mail</strong> und das dazugehörige <strong>Passwort</strong> ein.</p>";
    loginTypeFields = (
        <IonItem>
          <IonLabel position="stacked">E-Mail:</IonLabel>
          <IonInput ref={emailInput}  name="email"  value={formData.email}  onIonChange={(e) => {formData.email = e.detail.value; setFormData(formData);}}  onKeyUp={handleKeyUp}  />
        </IonItem>)
  }

  return (
    <IonPage>
      <Base name={name} title="" description={description}>
        {validationLoading === true || companyLoading === true ? <Loading size="full" /> : "" }
        <Errors errors={errors} />
        <div className="login-container">
          <IonItem hidden={(formData.producerNrs.length > 1 ? false : true)}>
            <IonLabel position="stacked">Produzent:</IonLabel>
            <IonInput name="producerNr" value={formData.producerNr} onIonChange={(e) => {formData.producerNr = e.detail.value; setFormData(formData);}} />
          </IonItem>
          {loginTypeFields}
          <IonItem>
            <IonLabel position="stacked">Passwort:</IonLabel>
            <IonInput  ref={passwordInput}  name="password" type="password" value={formData.password}  onIonChange={(e) => {formData.password = e.detail.value; setFormData(formData);}} />
          </IonItem>
          <IonItem className="terms-dataprotection">
            <IonCheckbox checked={formData.terms_and_dataprotection_accepted} name="terms_dataprotection_check" onIonChange={(e) => {formData.terms_and_dataprotection_accepted = e.detail.checked; setFormData(formData);}} />
            <IonLabel >Hiermit akzeptiere ich die <a href={settings.terms_link} target="_blank">AGBs</a> <br/>und <a href={settings.dataprotection_link} target="_blank">Datenschutz-Richtlinien</a></IonLabel>
          </IonItem>
          <IonButton expand="full" onClick={handleLoginAction} >Login</IonButton>
        </div>
      </Base>
    </IonPage>
  );
}

export {Login};
