import React, { useEffect, useState } from 'react';
import {IonDatetime, IonGrid, IonRow, IonCol, IonPage, IonItem, IonSelect, IonSelectOption, IonLabel, IonToggle} from '@ionic/react';

import { useParams, useLocation, useHistory } from 'react-router';
import qs from 'qs';

import DatePicker, { registerLocale, setDefaultLocale } from "react-datepicker";
import deDE from 'date-fns/locale/de';
import "react-datepicker/dist/react-datepicker.css";

import { render } from '@testing-library/react';

// interfaces
import { IApplication, IAppPage } from '../../interfaces/application';
import { IConstruction, IOrder } from '../../interfaces/order';

// components
import Base from '../../components/base/Base';
import ItemList from '../../components/orders/ItemList';
import Error from '../../components/Error';

// hooks & services
import useLocalUserDatabase from '../../services/account/useLocalUserDatabase';
import useLocalDeliveryListDatabase from '../../services/delivery/useLocalDeliveryListDatabase';
import { dateString } from '../../services/hooks/dateString';

import useGet from '../../services/hooks/useGet';
import { IDelivery, IDeliveryDetails, IDeliveryParams, IDeliveryMode } from '../../interfaces/delivery';

interface ISearchParameter {
  start_date?: string,
  end_date?: string
}

let deliveryChecker: any = undefined;
const intervalTime: number = 30000;
/***
 * Delivery Status Page
 */
const Delivery: React.FC<IApplication> = (props) => { 
  let name = "Lieferungen";
  registerLocale('deDE', deDE);
  const FORMAT = 'dd.MM.yyyy';

  const [isMounted, setIsMounted] = useState<boolean>(true);

  React.useEffect(() => {
    setIsMounted(true);
    return () => {
      setIsMounted(false);
      clearInterval(deliveryChecker);
    };
  }, []);

  const {localUser} = useLocalUserDatabase(undefined)

  const {isLocalDeliveryListLoaded, savedDeliveryList, updateDeliveryList, isLocalParamsLoaded, isLocalParamsEmpty, savedDeliveryParams, updateDeliveryParams} = useLocalDeliveryListDatabase();

  React.useEffect(() => {
    searchForDateGetParameters();
  }, [isLocalParamsLoaded])

  /**
   * Mode [daily | total] 
   *  Defines the view of the data
   */
  const [deliveryViewMode, setDeliveryViewMode] = useState<IDeliveryMode>();

  React.useEffect(() => {
    if(deliveryViewMode == undefined && isLocalParamsLoaded == true)
    {
      if(isLocalParamsEmpty == false && savedDeliveryParams?.mode != undefined)
        setDeliveryViewMode(savedDeliveryParams.mode)
      else
        setDeliveryViewMode('daily');
    }
    else 
    {
      if(deliveryFetchData != undefined)
        prepareFetchedDeliveryData();
    }
    
  }, [deliveryViewMode, isLocalParamsLoaded])

  /**
   * Date handling 
   *  including url get parameters
   */
  const location = useLocation();
  const history = useHistory();
  const [searchParameters, setSearchParameters] = useState<ISearchParameter>(qs.parse(location.search.replace('?', ''),{ plainObjects: true }));

  const [startDate, setStartDate] = useState<Date>();
  const [endDate, setEndDate] = useState<Date>();

  const buildExistingGET_Parameter = () : string => {
    let historyline: string = "?";
    let iterationNumber: number = 0;

    for (const [key, value] of Object.entries(searchParameters)) {
      if(iterationNumber > 0 || historyline.length > 1)
        historyline += "&";
      
      historyline +=  `${key}=${value}`;
    }

    return historyline;
  };

  const searchForDateGetParameters = () => {
    let historyline:string = buildExistingGET_Parameter();
    let localDataExists: boolean = savedDeliveryParams != undefined ? true : false;

    // for start of page 
    if(startDate == undefined && endDate == undefined && isLocalParamsLoaded == true )   
    {
      if(searchParameters.start_date == undefined)
      {
        let date = savedDeliveryParams != undefined ? new Date(savedDeliveryParams.start_date) : new Date();
        historyline += (historyline.length > 1 ? "&" : "") + "start_date=" + dateString(date);
        setStartDate(new Date());
      }
      else
        setStartDate(new Date(searchParameters.start_date));
      
      if(searchParameters.end_date == undefined)
      {
        let date = savedDeliveryParams != undefined ? new Date(savedDeliveryParams.end_date) : new Date();
        historyline += (historyline.length > 1 ? "&" : "") + "end_date=" + dateString(date);
        setEndDate(new Date());
      }
      else
        setEndDate(new Date(searchParameters.end_date));
    }
    // for coming back to page via navigation
    else if (startDate != undefined && endDate != undefined )
    {
      if(searchParameters.start_date != undefined  && historyline.includes("start_date") == false)
        historyline += (historyline.length > 1 ? "&" : "") + "start_date=" + dateString(startDate);
      if(searchParameters.end_date != undefined  && historyline.includes("end_date") == false) 
        historyline += "&end_date=" + dateString(endDate);
    }

    history.push({ search: historyline  })
  };

  const replaceUrlGetParameter = (paramName: string, value:string) : void => {
    if(paramName in searchParameters){
      let currentValue:string = "";
      let doUpdate: boolean = false;
      if(paramName == "start_date")
        currentValue = (searchParameters.start_date != undefined ? searchParameters.start_date : "");
      
      if(paramName == "end_date")
        currentValue = (searchParameters.end_date != undefined ? searchParameters.end_date : "");

      if(currentValue != value && currentValue != "")
        doUpdate = true;
      
      if(doUpdate == true)
      {
        let historyline:string = location.search;
        let newHistoryLine:string = "";
    
        let startPosition = historyline.indexOf(paramName) + paramName.length + 1;
        let endPosition = startPosition + value.length;
    
        newHistoryLine = historyline.substring(0,startPosition) + value + historyline.substring(endPosition);
        history.push({search: newHistoryLine});
      }
    }
  }

  React.useEffect(() => {
    if(location.search != "")
      setSearchParameters(qs.parse(location.search.replace('?','')));

  }, [location.search])

  React.useEffect(() => {
    if( searchParameters.start_date != undefined){
      setStartDate(new Date(searchParameters.start_date));
    }
    if( searchParameters.end_date != undefined){
      setEndDate(new Date(searchParameters.end_date));
    }
  }, [searchParameters]);


  React.useEffect(() => {
    if(startDate != undefined && endDate != undefined)
    {
      if(startDate > endDate)
        setEndDate(startDate);
      else
      {
        replaceUrlGetParameter('start_date', dateString(startDate));
        if(loading == false && localUser != undefined && localUser.token != undefined)
          getDeliveryData(localUser.token);
      }
    }
  }, [startDate])

  React.useEffect(() => {
    if(endDate != undefined && startDate != undefined)
    {
      if(endDate < startDate)
        setStartDate(endDate);
      else
      {
        replaceUrlGetParameter('end_date', dateString(endDate));
        if(loading == false && localUser != undefined && localUser.token != undefined)
          getDeliveryData(localUser.token);
      }
      
    }
  }, [ endDate])

  /**
   *  1.) Handling collect constructions && handling select construction
   *  2.) handle return of page by navigation 
   */
   React.useEffect(() => {
    if(localUser != undefined && localUser.token != undefined )
      getConstructionData( localUser.token);
  }, [localUser])

  const { data: constructionData, setData: setConstructionData, reDoGet: getConstructionData } = useGet("construction/tbkuid-" + localUser?.tbkuid, false, localUser?.token != undefined ? {authorizationType: "Bearer", token: localUser.token} : undefined);
  const [selectedConstruction, setSelectedConstruction] = useState<IConstruction>();

  React.useEffect(() => {
    if(constructionData != undefined)
    {
      let constructionIndex: number = 0;

      if(isLocalParamsLoaded == true){
        if(savedDeliveryParams?.construction != undefined && constructionData.every != undefined)
          constructionData.every((singleConstruction: IConstruction, index: number)=> {
            if (singleConstruction.constructionId == savedDeliveryParams.construction.constructionId)
            {
              constructionIndex = index;
              return false;
            }
            else
              return true;
          });
      }

      setSelectedConstruction(constructionData[constructionIndex]);
    }
      
  }, [constructionData, savedDeliveryParams]);

  React.useEffect(() => {
    if(props.pathHasChanged == true && ((props.currentPath.indexOf('?') > -1  && props.currentPath.split('?')[0] == props.pagePath) || props.currentPath == props.pagePath))
    {
      if(isLocalParamsLoaded)
        setTimeout(searchForDateGetParameters, 200);
    } 
    else if(props.pathHasChanged == true && (props.currentPath.indexOf('?') > -1 && props.currentPath.split('?')[0] != props.pagePath ) ||  (props.currentPath.indexOf('?') == -1  && props.currentPath != props.pagePath))
    {
      clearInterval(deliveryChecker);  
    }
  }, [props.pathHasChanged, selectedConstruction, localUser])

  const [checkFetchDataWithLocal, setCheckFetchDataWithLocal] = useState<boolean>(false);
  const proofDataOnInterval = () => { 
    clearInterval(deliveryChecker);
    deliveryChecker = setInterval(() => {getDeliveryData((localUser?.token != undefined ? localUser.token : undefined))}, intervalTime);
  };

  React.useEffect(() => {
    if(selectedConstruction != undefined && startDate != undefined && endDate != undefined)
    {
      getDeliveryData((localUser?.token != undefined ? localUser.token : undefined));

      //if(checkFetchDataWithLocal == true)
        proofDataOnInterval();
    }
  }, [selectedConstruction, startDate, endDate, checkFetchDataWithLocal])
  
  
   // effect for reloading page
   React.useEffect(() => {
    if(startDate != undefined && endDate != undefined && selectedConstruction != undefined && deliveryViewMode != undefined)
       updateDeliveryParams({
         start_date: startDate,
         end_date: endDate,
         construction: selectedConstruction,
         mode: deliveryViewMode,
       });
  }, [startDate, endDate, selectedConstruction, deliveryViewMode])

  /***
   *  Handling delivery data
   */
  const { data: deliveryFetchData, setData: setDeliveryFetchData, reDoGet: getDeliveryData, loading } = useGet("delivery-status/pnr-" + localUser?.producer_nr + "/knr-" + localUser?.customer_nr + "/baunr-" + selectedConstruction?.construction_nr + "/"+ dateString(startDate) + "/" + dateString(endDate), false, localUser?.token != undefined ? {authorizationType: "Bearer", token: localUser.token} : undefined);
  
  const [deliveryData, setDeliveryData] = useState<IDelivery[]>();
  
  const prepareFetchedDeliveryData = () : void  =>{
    let originDeliverFetchData : IDelivery[] | any[] = Symbol.iterator in Object(deliveryFetchData) ? [...deliveryFetchData] : [];
    let preparedDeliveryData: IDelivery[] | any[] = [];
    let deliveryDataOutput: IDelivery[] = [];
    
    originDeliverFetchData.forEach((delivery:IDelivery) => {
      let identifier: string | number | any = "";

      if (deliveryViewMode == "daily" )
          identifier =  `${delivery.producer_nr}/${delivery.customer_nr}/${delivery.contract_nr}/${delivery.material.material_nr}/${dateString(new Date(delivery.date))}`;//$"{d.Pnr}/{d.Knr}/{d.KontraktNr}/{d.ArticleNr}/{d.Date.ToString("yyyy-MM-dd")}";
      else if (deliveryViewMode == "total")
          identifier = `${delivery.producer_nr}/${delivery.customer_nr}/${delivery.contract_nr}/${delivery.material.material_nr}/${dateString(new Date(delivery.order_details.order_start))}-${dateString(new Date(delivery.order_details.order_end))}`;////{startDate}-{endDate}";
      
      let amount = 0 + parseFloat(String(delivery.amount));

      if(preparedDeliveryData[identifier] == undefined)
      {
        preparedDeliveryData[identifier] = {...delivery};
        preparedDeliveryData[identifier].details = [];
        preparedDeliveryData[identifier].complete_scaled_amount = 0;
        preparedDeliveryData[identifier].amountInfo = {
          completeAmountOfMaterialWithUnit: "",
          completeProgressInPercentage: "",
          completeProgressValue: 0,
          completeAmountOfMaterial: "",
        };
        preparedDeliveryData[identifier].order_details.total_amount = parseFloat(String(delivery.order_details.total_amount))
        delete(preparedDeliveryData[identifier].amount);
      }
      
      let detail:IDeliveryDetails = {
        showExtra: deliveryViewMode == 'total' ? true : false,
        scalingTime: delivery.scalingTime,
        deliveryTime: delivery.deliveryTime,
        delivery_note_nr: delivery.delivery_note_nr,
        amount: amount,
        unit: delivery.material.unit,
      };
      preparedDeliveryData[identifier].details.push(detail);
      preparedDeliveryData[identifier].complete_scaled_amount += amount; 
    });
  
    let data = Object.entries(preparedDeliveryData).forEach(([index, preparedDelivery]) => {
      // calcultate delivery amount info
      if(preparedDelivery.order_details.amountType == "to") 
      {
          preparedDelivery.amountInfo.completeProgressValue = preparedDelivery.complete_scaled_amount / preparedDelivery.order_details.total_amount;
          preparedDelivery.amountInfo.completeProgressInPercentage = (preparedDelivery.amountInfo.completeProgressValue * 100) + "%";
          preparedDelivery.amountInfo.completeAmountOfMaterialWithUnit = preparedDelivery.order_details.total_amount + " " + preparedDelivery.material.unit;
      }
      else if (preparedDelivery.order_details.amountType == "Touren")
      {
        let weightSum:number = 25 * preparedDelivery.order_details.total_amount;
        preparedDelivery.amountInfo.completeProgressValue =  preparedDelivery.complete_scaled_amount / weightSum;
        preparedDelivery.amountInfo.completeProgressInPercentage = (preparedDelivery.amountInfo.completeProgressValue * 100) + " %";
        preparedDelivery.amountInfo.completeAmountOfMaterialWithUnit = preparedDelivery.order_details.total_amount + " " + preparedDelivery.order_details.amountType;
      }
      else if (preparedDelivery.order_details.amountType == "Lfd.")
      {
        preparedDelivery.amountInfo.completeAmountOfMaterial = preparedDelivery.order_details.amountOfVehicles;
        preparedDelivery.amountInfo.completeAmountOfMaterialWithUnit = preparedDelivery.order_details.amountOfVehicles  + " Fahrzeuge pro Tag";
        preparedDelivery.amountInfo.completeProgressValue = (preparedDelivery.details.length / preparedDelivery.order_details.amountOfVehicles).toLocaleString('en', { maximumSignificantDigits: 4 });
        
        let endDate = new Date(preparedDelivery.order_details.order_end),
            startDate =  new Date(preparedDelivery.order_details.order_start);

        let totalDays  = ((endDate.getTime() - startDate.getTime())  / (1000 * 3600 * 24));

        if(deliveryViewMode == "total" && totalDays >= 1)
        {
          preparedDelivery.amountInfo.completeProgressValue = (preparedDelivery.amountInfo.completeProgressValue / totalDays).toLocaleString('en', { maximumSignificantDigits: 4 });
        }
         
        preparedDelivery.amountInfo.completeProgressInPercentage = (parseFloat(preparedDelivery.amountInfo.completeProgressValue) * 100) + " %";
      }

      deliveryDataOutput.push(preparedDelivery);
    });

    setDeliveryData(deliveryDataOutput);
  };

  React.useEffect(() => {
    if(deliveryFetchData != undefined)
    {
     
      prepareFetchedDeliveryData();
      
      /*if(deliveryFetchData[0].details == undefined)
      {
        console.log("PPPPPPP", deliveryFetchData[0].details)
        console.log("ÜÜÜÜÜÜÜÜÜÜ", deliveryFetchData)
        updateDeliveryList([...deliveryFetchData]);
      }
        
      if(checkFetchDataWithLocal == false)
      {
        setCheckFetchDataWithLocal(true);
        prepareFetchedDeliveryData();
      }
      else {
        if(JSON.stringify(savedDeliveryList) == JSON.stringify(deliveryFetchData))
        {
          console.log("else: EQUAL")
        }
        else
        {
          console.log("else: UNEQUAL")
          
          //prepareFetchedDeliveryData();

        }
      }
       */
    }
  }, [deliveryFetchData]);
  
  return (
    <IonPage>
      <Base name={name} title={name} description={"<p>Hier können Sie den <strong>Zustand Ihrer Lieferungen</strong> erhalten. Wählen Sie die entsprechende <strong>Baustelle</strong> und den <strong>Zeitpunkt</strong> aus um bereits abgearbeitete Lieferungen zu betrachten.</p>"} extraClassName="delivery">
        <IonGrid>
          {/* dates */}
          <IonRow className="dates">
            <IonCol size='5'>
                <IonRow className="datepicker-title">Von</IonRow> 
               <IonRow>
                  <DatePicker locale="deDE" selected={startDate} onChange={(date: Date) => setStartDate(date)} dateFormat={FORMAT} />
                </IonRow>  
            </IonCol>
            <IonCol size='2' offset='0'>
                <IonRow>
                </IonRow> 
                <IonRow>
                  <IonCol><p style={{textAlign: "center", fontSize: "1.1em", lineHeight: "1.5"}}>-</p></IonCol>
                </IonRow>    
            </IonCol>
            <IonCol size='5' offset='0'>
              <IonRow className="datepicker-title">Bis</IonRow> 
               <IonRow>
                  <DatePicker locale="deDE" selected={endDate} onChange={(date: Date) => setEndDate(date)} dateFormat={FORMAT} />
                </IonRow> 
            </IonCol>
          </IonRow>
          {/* view mode [daily | total] */}
          <IonRow className="mode-switch">
            <IonCol size='2' offset='8'>{deliveryViewMode == 'daily' ? "Täglich" : "Gesamt"}</IonCol>
            <IonCol size='2' offset='0'>
              <IonToggle checked={deliveryViewMode == 'total' } onIonChange={e => setDeliveryViewMode(e.detail.checked ? "total" : "daily")} />
            </IonCol>
            
          </IonRow>
           {/* construction */}
          <IonRow>
            <IonCol size="10" offset='1'>
              {constructionData?.length > 0 ? 
                (<IonLabel>Auswahl der Baustelle
                    <IonSelect 
                      interfaceOptions={{header: "Baustellen"}}
                      cancelText="Abbrechen"
                      placeholder="Auswahl Baustelle" 
                      value={selectedConstruction} 
                      className="gray-select" 
                      onIonChange={(e:any) => {setSelectedConstruction(e.detail.value)}} >
                      
                    {constructionData?.map != undefined ? (constructionData?.map((construction:IConstruction, index: number) => {
                        return <IonSelectOption key={"person-" + index } value={construction} >{construction.address}</IonSelectOption>
                    })) : null}
                  </IonSelect>
                </IonLabel>)
              : <Error error="Es konnten keine Baustellen gefunden werden!" />}
              </IonCol>
          </IonRow>
        </IonGrid>
        <ItemList emptyMessage="Für diesen Zeitraum wurden keine Daten gefunden." extraClass="delivery-items" items={deliveryData != undefined ? deliveryData : []} mode={deliveryViewMode} />
      </Base>
    </IonPage>
  );
}

export default Delivery;