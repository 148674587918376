import React, { useEffect, useState } from "react";

import {IAppPage} from '../interfaces/application';

import { Storage } from '@ionic/storage'

function useLocalModuleData() {
    const [isMounted, setIsMounted] = useState<boolean>(true)

    const storage = new Storage();
    storage.create();
    
    const [isLocalLoaded, setIsLocalLoaded] = useState<boolean>(false)
    const [localModules, setLocalModules] = useState<IAppPage[]>()
    const [isSaved, setIsSaved] = useState<boolean>(false)

    React.useEffect(() => {
        if(localModules === undefined && isLocalLoaded === false)
            loadModuleData();

        return () => {
          setIsMounted(false);
          setIsLocalLoaded(false);
        };
    }, []);

    /* load existing modules */
    const loadModuleData = () => {
        if(isMounted === true)
        {
            storage.get('module_data').then((data) => {
                if(isMounted === true)
                {
                    if(data !== null)
                        setLocalModules(data);
                    
                    setIsLocalLoaded(true);
                }
            }).catch((e) => {
                if(isMounted === true)
                {
                    console.error(e)
                    setIsLocalLoaded(false);
                }
            });
        }
    }

    /* save contact person */
    const saveModuleData = (moduleData:IAppPage[]) => {
        storage.remove("module_data").then(()=> {
            storage
                .set('module_data', moduleData)
                .then(() => {
                    if(isMounted == true){
                        setIsSaved(true);
                        setLocalModules(moduleData);
                        setTimeout(() => {setIsSaved(false);}, 1000);
                    }
                        
                });
        });
    }

    return { localModules, loadModuleData, saveModuleData, useLocalModuleData, isLocalLoaded, isSaved }// loading, error};
}

export default useLocalModuleData;
