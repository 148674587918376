import React, {useState} from 'react';

import { IonItem, IonGrid, IonRow, IonCol, IonSelect, IonInput, IonSelectOption, IonLabel, IonButton  } from '@ionic/react';
import { render } from '@testing-library/react';
import { useParams } from 'react-router';

import { IHandleOrder } from '../../interfaces/order';
import {IContactPerson} from '../../interfaces/person'

import useLocalAppDatabase from '../../services/useLocalAppDatabase';
import useLocalContactPersonDatabase from '../../services/orders/useLocalContactPersonDatabase'

const ContactPerson: React.FC<IHandleOrder> = (props) => { 
    // proof on mounted state -> is component available or removed from DOM
    const [isMounted, setIsMounted] = useState<boolean>(true);
    const {localApplication, loadApplicationData, saveApplicationData} = useLocalAppDatabase();

    React.useEffect(() => {
        setIsMounted(true);
        return () => {
          setIsMounted(false);
        };
    }, []);

    const [isSelected, setIsSelected] = useState<boolean>();
    const [preventSelectChange, setPreventSelectChange] = useState<boolean>(false);
    const [selectedContactPerson, setSelectedContactPerson] = useState<IContactPerson>();
    const {localListOfContactPerson} = useLocalContactPersonDatabase();
    const [isEmailValid, setIsEmailValid] = useState<boolean>(true);
    
    React.useEffect(() => {
        if (localListOfContactPerson !== undefined){
            if(localListOfContactPerson.length > 0 && selectedContactPerson === undefined)
                setSelectedContactPerson(localListOfContactPerson[0]);
        }
    }, [localListOfContactPerson]);

    /* proof on allowed chars in amount field */
    const handlePhoneNumberKeyDown = (event: any) => {
        if(event.code !== undefined && localApplication?.appToken === undefined && localApplication?.appToken === "") {
            if ((event.code.substr(0, event.code.length - 1) !== 'Digit' 
                && event.code.substr(0, event.code.length - 1) !== 'Numpad' 
                && event.code !== 'Backspace'
                && event.code !== 'NumpadAdd'
                && event.code !== 'Space')
                ||  (isNaN(parseInt(event.key)) &&  event.code.substr(0, event.code.length - 1) === 'Digit'))
            {    
                event.preventDefault();
                event.stopPropagation();
            }

            if (event.code === 'Enter' || event.code === 'NumpadEnter')
                event.target.blur();
        }
    }

    const handleDataChange = (e: any, contactPersonKey: string) => {
        if(selectedContactPerson !== undefined && isMounted === true)
            switch(contactPersonKey){
                case "salutation":
                    setSelectedContactPerson({...selectedContactPerson, salutation: e.detail.value});
                    break;
                case "fullName":
                    setSelectedContactPerson({...selectedContactPerson, fullName: e.detail.value});
                    break;
                case "phone":
                    setSelectedContactPerson({...selectedContactPerson, phone: e.detail.value});
                    break;
                case "email":
                    setSelectedContactPerson({...selectedContactPerson, email: e.detail.value});
                    break;
                default:
                    console.error("Key " + contactPersonKey + " deos not exist!!!");
        }
    }

    const handleSelectOfContactPerson = (e:any) => {
        if(preventSelectChange === false)
        {
            let contactPersonId = e.detail.value;
            let newSelectedContactPerson: IContactPerson | undefined= undefined;
            
            localListOfContactPerson.map((person, i) => {
                if(contactPersonId === person._id)
                    newSelectedContactPerson = person;
            })
            
            if(newSelectedContactPerson !== undefined && isMounted === true)
            {
                setSelectedContactPerson(newSelectedContactPerson)
                setIsSelected(true);
            }
        }
        else if (isMounted === true)
            setPreventSelectChange(false)
        
    }
    /* unset selected contact person */
    const unsetSelectedContactPerson = (e:any) => {
        if(isMounted === true)
            setPreventSelectChange(true);
    }

    React.useEffect(() => {
        if(preventSelectChange === true && isMounted === true)
            setIsSelected(false);

        if(isSelected === false && isMounted === true)
            setSelectedContactPerson({salutation: "", fullName: "", phone: ""});
    }, [isSelected, preventSelectChange])

    // setup for initializing
    React.useEffect(() => {
        if(props.order !== undefined){
            if(selectedContactPerson === undefined)
            {
                setTimeout(() => {
                    
                    let isUpdated: boolean = false;
                    if(props.order !== undefined)
                    {
                        if(props.order.contactPerson !== undefined && isMounted === true)
                        {
                            setSelectedContactPerson(props.order.contactPerson);
                            isUpdated = true;
                        }                        
                    } 
    
                    if (isUpdated === false && isMounted === true)
                        setSelectedContactPerson({salutation: "", fullName: "", phone: ""});
                },1250);
            }
        }
    }, [props.order])

    // Update of order
    React.useEffect(() => {
        if (isMounted === true && selectedContactPerson !== undefined)
        {
            let currentOrder: any = {contactPerson: selectedContactPerson};
            props.updateOrder(currentOrder);
        }
    }, [selectedContactPerson])

    return (
       <IonGrid className="contactPerson">
           {/*  Select Box for saved contact persons */}
           {localListOfContactPerson.length > 0 
            ?
           (<div>
                <IonRow>
                    <h2>Auswahl Ansprechpartner</h2>
                </IonRow>
                <IonRow>
                    <IonCol size="9">
                        <IonItem >
                            <IonSelect 
                                interfaceOptions={{header: "Ansprechpartner"}}
                                cancelText="Abbrechen"
                                placeholder="Auswahl Ansprechpartner" value={selectedContactPerson?._id} className="gray-select" onIonChange={handleSelectOfContactPerson} >
                                {localListOfContactPerson.map((person, index) => {
                                    return <IonSelectOption key={"person-" + index }value={person._id}>{person.fullName}</IonSelectOption>
                                })}
                            </IonSelect>
                        </IonItem>
                    </IonCol>
                    <IonCol size="2" offset="0" >
                        {isSelected === true ? <IonButton style={{marginBottom: 0, marginTop: ".2em", minWidth: "5em", width: "100%"}} className="" expand="block" onClick={unsetSelectedContactPerson}>Neu</IonButton> :""}
                    </IonCol>
                </IonRow>
            </div>) 
            : ""
            }
            {/* form for new contact person */}
            <IonRow>
                <IonCol size="6">
                     <h2>Ansprechpartner*</h2>
                    <IonItem >
                        {/*<IonInput placeholder="Anrede" value={selectedContactPerson?.salutation} onIonChange={e => handleDataChange(e, 'salutation')}/>*/}
                        <IonSelect interfaceOptions={{header: "Anrede"}} cancelText="Abbrechen" placeholder="Anrede" value={selectedContactPerson?.salutation} onIonChange={e => handleDataChange(e, 'salutation')}>
                            <IonSelectOption value="Herr">Herr</IonSelectOption>
                            <IonSelectOption value="Frau">Frau</IonSelectOption>
                        </IonSelect>
                    </IonItem>
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol size="12">
                    <IonItem >
                        <IonInput required={true} placeholder="Name*" value={selectedContactPerson?.fullName} onIonChange={e => handleDataChange(e, 'fullName')}></IonInput>
                    </IonItem>
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol size="6">
                    <IonItem >
                        <IonInput required={true} 
               
                                  inputmode="tel" placeholder="Telefon*" 
                                  value={selectedContactPerson?.phone} 
                                  onKeyDown={handlePhoneNumberKeyDown}
                                  onIonChange={e => handleDataChange(e, 'phone')}></IonInput>
                    </IonItem>
                </IonCol>
                <IonCol size="6">
                    <IonItem >
                        <IonInput placeholder="E-Mail" value={selectedContactPerson?.email} inputmode="email" onIonChange={e => handleDataChange(e, 'email')}></IonInput>
                    </IonItem>
                </IonCol>
            </IonRow>
       </IonGrid>
    );
}

export default ContactPerson;